import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DropdownInputProps, SelectionItem, ProFilterInfo } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import Select, { components } from 'react-select';
import ActiveFilters from './ActiveFilters';

const Option = (props) => {
    return (
        <div className="d-flex align-items-center">
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


function PbiSelectFilterInput({
    id,
    group,
    table,
    column,
    displayName,
    operator,
    className,
    noSelectionPlaceholder,
    values,
    isSearchable,
    isMultiSelect,
    onSetFilterItems,
    disabled,
    passRawValuesToFilterFn,
    defaultValue,
    sortIndex
}: DropdownInputProps) {
    
    const { updateFilter, removeFilter, getFilterValue, allFilters } = useFilters();
    const [selected, setSelected] = useState<SelectionItem[] | null>(null);
    const [currentFilter, setCurrentFilter] = useState<ProFilterInfo | null>();
    const { createBasicFilter } = useFilterFactory();
    
    const debouncedSelection = useDebouncedValue(selected, 5);

    const colorStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#999999" : null,
                color: "#333333"
            };
        }
    };

    useEffect(() => {
        let passDefaultValue: any = getFilterValue(table, column) ?? defaultValue;

        if (passDefaultValue) {
            let newValue = values.find(di => di.value === passDefaultValue.value);
            
            var passDefaultArray: SelectionItem[] = new Array();
            passDefaultArray.push(newValue!);

            if (newValue) {
                setSelected(passDefaultArray);
            }
        }
    }, [])

    useMemo(() => {
        if (id === "origin_zone" || id === "destination_zone") {
            let passDefaultValue: any = getFilterValue(table, modulateColumnName(column)) ?? defaultValue;

            if (passDefaultValue) {
                let newValue = values.find(di => di.value === passDefaultValue.value);

                var passDefaultArray: SelectionItem[] = new Array();
                passDefaultArray.push(newValue!);

                if (newValue) {
                    setSelected(passDefaultArray);
                }
            }
        }
    }, [values])

    function modulateColumnName(table: string) {
        if (table.substring(0,4) === "Orig") {
            return "Orig Key Market Area";
        }
        else {
            return "Dest Key Market Area";
        }
    }


    useEffect(() => {
        var category: number | undefined | null = null;

        if (selected === null || selected.length == 0) {
            if (currentFilter !== null) {
                removeFilter(currentFilter);
                setCurrentFilter(null);
            }

            if (onSetFilterItems) {
                onSetFilterItems(null, null, group);
            }

            return;
        }

        if (selected !== null && selected.length !== 0) {
            let values = [...selected.map(s => s.value)];
            let rawValues = [...selected];

            let quotedValues = values.map(v => `${v}`);
            let inList = `${quotedValues.join(", ")}`;



            let zzkmaColumn: string = "";
            if (group === "zoneZipKma") {

                if (rawValues) {
                    category = Number(rawValues[0].category!);

                    //setFilterCategory(Number(category));

                    var isOrigin = table == "Origin";

                    if (category == 1) {
                        zzkmaColumn = isOrigin ? "Orig Zone" : "Dest Zone";
                    }
                    else if (category == 2) {
                        zzkmaColumn = isOrigin ? "Orig Zip 3" : "Dest Zip 3";
                    }
                    else {
                        zzkmaColumn = isOrigin ? "Orig Key Market Area" : "Dest Key Market Area";
                    }
                }
                else {
                    category = null;
                }
            }

            const reportFilter = createBasicFilter(
                {
                    table,
                    column: zzkmaColumn ? zzkmaColumn : column,
                    value: values
                }
            );

            let filter: ProFilterInfo = {
                id,
                displayTextHeader: `${displayName}`,
                displayText: `${inList}`,
                group,
                reportFilter,
                reset,
                rawValue: rawValues,
                sortIndex: sortIndex
            };

            setCurrentFilter(filter);
            updateFilter(filter);

            if (onSetFilterItems) {

                onSetFilterItems(
                    (passRawValuesToFilterFn ? rawValues : values)
                    , (passRawValuesToFilterFn ? category : null)
                    , (passRawValuesToFilterFn ? group : null));
            }

        }

    }, [debouncedSelection])
    //only sends after x secs of inactivity



    //function trailerTypeToVan() {
    //    var defaultValue = getFilterValue(id);

    //    if (defaultValue === null && id === "trailer_type") {
    //        var passDefaultArray: SelectionItem[] = new Array();
    //        passDefaultArray.push({ label: "Van", value: "Van" });

    //        defaultValue = passDefaultArray;
    //    }

    //    if (defaultValue) {
    //        setSelected(defaultValue);
    //    }
    //};



    function reset() {
        //if (id === "trailer_type") {
        //    var passDefaultArray: SelectionItem[] = new Array();
        //    passDefaultArray.push({ label: "Van", value: "Van" });
        //    setSelected(passDefaultArray);
        //}
        //else {
        setSelected(null);
        //}
    }

    function handleChange(value: any, meta: any) {
        if (meta.action === 'clear' || value === null) {
            setSelected(null);
            return;
        }

        if (isMultiSelect) {
            handleChangeMulti(value, meta);
        }
        else {
            handleChangeSingle(value, meta);
        }
    }


    function handleChangeSingle(value: any, meta: any) {
        let arg = value as SelectionItem;
        let val: SelectionItem[] = [];
        val.push(arg);
        setSelected(val);
    }

    function handleChangeMulti(value: any, meta: any) {
        let val = value as SelectionItem[];
        setSelected(val);
    }

    function placeholder() {
        if (!selected || selected.length == 0) {
            return noSelectionPlaceholder;
        }

        if (selected.length > 1) {
            var concat = selected.map((a) => `${a.label}`).join(', ');

            if (concat.length < 20) {
                return concat;
            }
            else {
                return "Multiple Values";
            }

            
        }

        if (selected !== null && selected !== undefined) {
            try {
                return selected[0].label;
            } catch (e) {
                return "";
            }
            
        }
        

    }

    if (isMultiSelect)
        return (
            <div className={className ?? ""}>
                <label className="select-label">{displayName}</label>
                <Select
                    value={selected}
                    onChange={handleChange}
                    options={values}
                    styles={colorStyles}
                    isSearchable={isSearchable}
                    isMulti={true}
                    isClearable={selected !== null}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    placeholder={placeholder()}
                    components={{ Option }}
                    closeMenuOnSelect={false}
                    isDisabled={disabled}
                />
            </div>
        );


    return (
        <div className={className ?? ""}>
            <label className="select-label">{displayName}</label>
            <Select
                value={selected}
                onChange={handleChange}
                options={values}
                styles={colorStyles}
                isSearchable={isSearchable}
                isMulti={isMultiSelect}
                isClearable={selected !== null}
                placeholder={placeholder()}
                isDisabled={disabled}
            />
        </div>

    );
}


export default PbiSelectFilterInput;