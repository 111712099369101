import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { CButton } from '@coreui/react';
//import rateLogo from '../assets/img/dollar-sign.png'
//import rateLogo from '../assets/img/rate-calculator-white.png'
import rateLogo from '../assets/img/base-predictor.png'
//import rateLogo from '../assets/img/rate-pred.png'

import RatePredictor2 from './RatePredictor2';

import './RatePredictor2.css';

function ButtonRatePred(props) {

	const [isExpanded, setIsExpanded] = useState(false);
	const [isCleared, setIsCleared] = useState(false);
	const [isPro, setIsPro] = useState(false);

	function changeDisplay() {
		setIsExpanded((e) => !e);
	};

	useEffect(() => {
		if (props.isProClass === "button-pro-rp") {
			setIsPro(true);
		}
	}, [])

	useEffect(() => {
		if (isCleared) {
			setIsExpanded(true);
			setIsCleared(false);
		}
		
	}, [isCleared])

	

return (
	<div>
		<CButton className={`
						text-black-50 
						text-dark 
						bg-transparent 
						shadow-none   
						${props.isProClass}`}

			shape="pill"
			variant="ghost">

			{!isPro && (<img className="button-image-rp-base" src={rateLogo} onClick={changeDisplay} />)}
			{isPro && (
				<div className="button-image-rp" onClick={changeDisplay}>
					{/*<span className="first">&nbsp;M</span>*/}
					{/*<span className="second">Pact</span>*/}
					{/*<span className="third">&nbsp;Rate</span>*/}
					<span className="fourth">Predictor</span>
				</div>
				
			)}
		</CButton>
		<div>
			{isExpanded && (<RatePredictor2 isItExpanded={isExpanded} setIsExpanded={setIsExpanded} setIsCleared={setIsCleared} />)}
		</div>
	</div>



	)	
};

export default ButtonRatePred;