import React from 'react';
import mpactLogoWhite from '../assets/img/MPACT_Logo_white.png';
import mcleodLogoWhite from '../assets/img/McLeodLogo_white.png'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function AdminPageFraming(props: any) {
    return (
        <>
            <Helmet>
                <title>MPact Admin</title>
            </Helmet>
            <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-login ap-frame">
                <div className="ap-header">
                    <Link to='/'>
                        <img src={mpactLogoWhite} height="20" />
                    </Link>
                </div>
                <div className="ap-content">
                    {props.children}
                </div>
                <div className="ap-footer">
                    <img src={mcleodLogoWhite} height="50vw" className="pos-absolute right-0 bottom-0 ap-footer-logo" />
                </div>
            </div>
        </>
    );
}


export default AdminPageFraming;
