import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { ProFilterInfo } from '../models/filters';
import { useFilters } from './FilterContext';
//import { CTooltip } from '@coreui/react'


function ActiveFilters (props) {


    const { activeFilters, removeFilter } = useFilters();

    const [map, setMap] = useState<any>();

    const activeRef = useRef<any>(null);

    function ClearSvg() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 20 20">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
        );
    }

    useEffect(() => {
        /*let mappedElement = activeFilters.filter(f => !f.isReadonly).sort((a, b) => a.sortIndex! - b.sortIndex!).map(filterTag);*/
        let mappedElement = activeFilters.filter(f => !f.isReadonly);
        const newMap = Array.from(mappedElement).sort((a, b) => a.sortIndex! - b.sortIndex!);
        const newMap2 = newMap.map(filterTag);
        setMap(newMap2);
    }, [activeFilters])

    function filterTag(filter: ProFilterInfo) {
        //function deleteFilter(evt: any) {
        //    removeFilter(filter);
        //    filter.reset();
        //}

        return (
            <div
                key={filter.id}
                className="p-1 mr-1 filter-tag "
            >
                <label className="tag-header">{filter.displayTextHeader}</label>
                <div className="tag-text">
                    <span title={filter.displayText}>{filter.displayText}</span>
                </div>
                {/*{*/}
                {/*    !filter.isReadonly &&*/}
                {/*    <span onClick={deleteFilter} className="cursor-pointer remove-tag"><ClearSvg /></span>*/}
                {/*}*/}
            </div>
        );
    }


    return (
        <div> 
            <div className="active-filters-wrapper">
                <span className="active-filters-header">Filters</span>
                <div className="active-filters-spacer"></div>
                <div ref={activeRef} className="active-filters ">
                    {map}
                </div>
                
            </div>
            
        </div>

    );
}


export default ActiveFilters;