import React, { useState, useEffect } from 'react';
import "./Splash.css";
import { CButton, CModal } from '@coreui/react';
import { useLazyAxios } from 'use-axios-client';

const baseUri = "https://" + window.location.host + "/";

const Splash = (props) => {

    const [getSplash, { data: dataSplash, loading: loadingSplash }] = useLazyAxios<any>(baseUri + 'api/splash/getSplashConfiguration', { method: 'GET' });
    //const [splashes, setSplashes] = useState<SplashConfigVals[]>([]);

    const [head, setHead] = useState<string>("");
    const [blurb, setBlurb] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [copyright, setCopyright] = useState("");
    const [button1, setButton1] = useState("");
    const [button1Url, setButton1Url] = useState("");
    const [isButton1, setIsButton1] = useState(false);
    const [button2, setButton2] = useState("");
    const [button2Url, setButton2Url] = useState("");
    const [isButton2, setIsButton2] = useState(false);
    //const [imageUrl, setImageUrl] = useState<string>("");

    interface SplashConfigVals {
        sdKey: number,
        smKey: number,
        header: string,
        title: string,
        blurb: string,
        backgroundImage: string,
        copyright: string,
        buttonOneText: string,
        buttonOneURL: string,
        buttonTwoText: string,
        buttonTwoURL: string,
        dateAdded: string
    }

    useEffect(() => {
        getSplash();
    }, []);

    useEffect(() => {
        if (dataSplash) {
            /*let Splashs = [...dataSplash].sort((a, b) => a.Splash - b.Splash);*/
            let Splashs = [...dataSplash];
            
            //setSplashes(Splashs);
            if (dataSplash != undefined) {
                setHead(dataSplash[0].header);
                setBlurb(dataSplash[0].blurb);
                setTitle(dataSplash[0].title);
                setCopyright(dataSplash[0].copyright + "©");
                setButton1(dataSplash[0].buttonOneText);
                setButton1Url(dataSplash[0].buttonOneURL);
                setButton2(dataSplash[0].buttonTwoText);
                setButton2Url(dataSplash[0].buttonTwoURL);
                //setImageUrl(dataSplash[0].backgroundImage);
            };
        }
        //console.log(imageUrl);
    }, [dataSplash]);

    let imgUrl = "";

    //useEffect(() => {
    //    imgUrl = imageUrl;
    //    console.log(imgUrl);
    //}, [imageUrl]);

    useEffect(() => {
        if (button1 !== "" && button1Url !== "") { setIsButton1(true) }
        if (button2 !== "" && button2Url !== "") { setIsButton2(true) }

    }, [button1, button1Url, button2, button2Url]);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener, noreferrer');
    };

    return (
        <CModal alignment="center" visible={props.isSplash} onClose={() => {
            props.setIsSplash(false);
        }}>
            <div className="top-level-splash">
                <h3 className="head">{head}</h3>
                <h1 className="title">{title}</h1>
                <h3 className="blurb">{blurb}</h3>
                {isButton1 && (<button className="button1" onClick={() => openInNewTab(button1Url)}>
                    {button1}
                </button>)}
                {isButton2 && (<button className="button2" onClick={() => openInNewTab(button2Url)}>
                    {button2}
                </button>)}
            </div>
            <div>
                <h3 className="copyright">{copyright}</h3>
            </div>
            
            </CModal>
        );

};


export default Splash;




