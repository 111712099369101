import React from 'react';
import logoWhite from './assets/img/McLeodLogo_white.png'

interface FooterProps {
    hideFooterText: boolean;
    className: string|undefined;
}

function Footer({ hideFooterText, className }: FooterProps) {
    function FooterText() {
        if (hideFooterText)
            return null;

        return (<span className="footer-text">All information and content in the MPact portal is the intellectual property of McLeod Software.  You may not reuse, republish, or reprint any of the content without the explicit prior written consent of McLeod Software.</span>);
    }

    function cssClass() {
        return className ? `app-footer ${className}` : `app-footer`;
    }

    return (
        <footer className={cssClass()}>
            <FooterText />
            <img className="pos-absolute left-0 bottom-0 footer-logo" src={logoWhite} alt="MPact Logo" height="50vw"/>
        </footer>
    );
}

export default Footer;
