//import { domain } from 'process';
import React, { useState, useEffect } from 'react';
//import { useAxios } from 'use-axios-client';
import { GeographyComplete, SelectionItem } from '../models/filters';


interface SharedGeographyProvider {
    zonesZipsKmas: SelectionItem[];
    domains: SelectionItem[];
    setFilterValues: (values: SelectionItem[] | null, cat?: number, sourceName?: string) => void;
}


export function useSharedAll(geoMaster?: GeographyComplete[]): SharedGeographyProvider {
    const [geographies, setGeographies] = useState<GeographyComplete[]>([]);

    const [zonesZipsKmas, setZonesZipsKmas] = useState<SelectionItem[]>([]);
    const [zoneZipKmaFilter, setZoneZipKmaFilter] = useState<number[]>();

    const [domains, setDomains] = useState<SelectionItem[]>([]);
    const [domainFilter, setDomainFilter] = useState<string[]>([]);

    useEffect(() => {
        if (!geoMaster) {
            return;
        }
        setGeographies(geoMaster);
    }, [geoMaster]);


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let zoneszipskmas = toSelectionList(geographies, "zoneZipKma", "zoneZipKma", "sort");
        let domains = toSelectionList(geographies, "domain", "domain", "sort");

        setZonesZipsKmas(zoneszipskmas);
        setDomains(domains);
    }, [geographies])


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();
        let zones = toSelectionList(filtered, "zoneZipKma", "zoneZipKma", "sort"!);
        setZonesZipsKmas(zones);

        let domains = toSelectionList(geographies, "domain", "domain", "sort");
        setDomains(domains);

    }, [zoneZipKmaFilter])

    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();

        let zones = toSelectionList(filtered, "zoneZipKma", "zoneZipKma", "sort"!);
        setZonesZipsKmas(zones);

        let domains = toSelectionList(geographies, "domain", "domain", "sort");
        setDomains(domains);

    }, [domainFilter])


    function filterGeographies(): GeographyComplete[] {

        if (!zoneZipKmaFilter && !domainFilter.length) {
            return geographies;
        }


        return geographies.filter(geo => {

            if (zoneZipKmaFilter === null) {
                return (
                    (domainFilter.length && domainFilter.some(domain => geo.domain === domain))
                )
            }
            else {
                return (
                    (zoneZipKmaFilter!.length && zoneZipKmaFilter!.some(category => geo.sort === category)) ||
                    (domainFilter.length && domainFilter.some(domain => geo.domain === domain))
                )
            }
            
        });
    }

    function unique(value, index, self) {
        return self.indexOf(value) === index;
    }

    function toSelectionList(filtered: GeographyComplete[], valueKey: string, labelKey: string, categoryKey?: string): SelectionItem[] {

        
        if (valueKey === "zoneZipKma") {

            let passFiltered: any = filtered;
            

            if (zoneZipKmaFilter !== null && zoneZipKmaFilter !== undefined) {

                if (domainFilter[0] !== undefined) {
                    passFiltered = filtered.filter(geo =>
                        geo.sort === zoneZipKmaFilter[0]
                        && geo.domain === domainFilter[0]
                    )
                }
                else {
                    passFiltered = filtered.filter(geo =>
                        geo.sort === zoneZipKmaFilter[0]
                    )
                }
                

                const domValues = passFiltered.map(si => si.zoneZipKma);
                const distilled = domValues.filter(unique);

                //converted filtered into simple array to pass into the line below.....
                const finalArray = filtered.map(function (obj) {
                    return [obj.zoneZipKma, obj.sort];
                });

                let selection = distilled.map(o => ({ value: o, label: o, category: findCategory(o, finalArray) }));

                return selection;
            }

            const domValues = passFiltered.map(si => si.zoneZipKma);
            const distilled = domValues.filter(unique);

            const finalArray = filtered.map(function (obj) {
                return [obj.zoneZipKma, obj.sort];
            });

            let selection = distilled.map(o => ({ value: o, label: o, category: findCategory(o, finalArray) }));

            return selection;
        }
        else {

            let passFiltered: any = filtered;
            if (zoneZipKmaFilter !== null && zoneZipKmaFilter !== undefined) {
                passFiltered = filtered.filter(geo =>
                    geo.sort === zoneZipKmaFilter[0]
                )

                const domValues = passFiltered.map(si => si.domain);
                const distilled = domValues.filter(unique);

                const finalArray = filtered.map(function (obj) {
                    return [obj.zoneZipKma, obj.sort];
                });

                let selection = distilled.map(o => ({ value: o, label: o, category: findCategory(o, finalArray) }));

                return selection.sort((a, b) => { return a.label.localeCompare(b.label); });
            }

            let selection = filtered.map(f => f[valueKey])
                .filter(unique)
                .map(i => { return { label: i, value: i } });

            return selection.sort((a, b) => { return a.label.localeCompare(b.label); });
        }

        
    }

    function findCategory(value, array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][0] === value) {
                return array[i][1];
            }
        }
        return null;
    }

    function setFilterValues(
        values: SelectionItem[] | null
        , cat: number | undefined
        , sourceName: string | undefined
    ) {
        if (values === null) {
            values = [];
        }

        if (sourceName === "zoneZipKma") {

            let filter: any = (!values || values.length === 0) ? null : cat;

            const cats: number[] | null = [];
            cats[0] = filter;

            if (filter === null) {
                setZoneZipKmaFilter(filter);
            }
            else {
                setZoneZipKmaFilter(cats);
            }
        }
        else {
            const domValues = values.map(si => si.value);

            setDomainFilter(domValues);
            
        }

    }

    return {
        zonesZipsKmas,
        domains,
        setFilterValues
    };
}
