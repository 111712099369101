import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullSizeCentered from './FullSizeCentered';

import mcLeodLogoBlack from './assets/img/McLeodLogo_black.png';
import mpactLogoWhite from './assets/img/MPACT_Logo_white.png';

import { useLazyAxios } from 'use-axios-client';
import { useQueryString } from './hooks/useQueryString';

import { GoogleLogin } from '@react-oauth/google';
import * as msal from '@azure/msal-browser';

import MicrosoftSignInButton from './MicrosoftSignInButton';
import GoogleSignInButton from './GoogleSignInButton';

import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import { MDBInput } from "mdbreact";


interface RegistrationDetails {
    identityToken: string;
    uniqueId: string;
    registrationCode: string;
    provider: string;
}

const editorState = {
    no_registration_code: 0,
    choosing_provider: 1,
    editing_profile: 2,
};


//https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
const msalConfig: msal.Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID!,
        redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI_REGISTER!
    },
    cache: {
        storeAuthStateInCookie: true,
        secureCookies: true
    }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

interface UserProfile {
    companyName: string;
    email: string;

    firstName: string;
    lastName: string;
    phone: string;

    contactId: string;
    identityToken: string;
    uniqueId: string;
    provider: string;
}


function Register() {
    const query = useQueryString();
    const navigate = useNavigate();

    const [validateRegistration, { data: validationResult, loading: isWaitingForValidationResponse }] = useLazyAxios<any>('api/registration/validateRegistration', { method: 'POST' });
    const [registerUser, { data: registerUserResponse, loading: isWaitingForFinalResponse }] = useLazyAxios<boolean>('api/registration/registerUser', { method: 'POST' });

    const [registrationCode, setRegistrationCode] = useState<string>("");

    const [registrationError, setRegistrationError] = useState<string>("");

    const [state, setState] = useState<number>(editorState.no_registration_code);

    const [validationMessage, setValidationMessage] = useState<string>("");

    const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
    const [hasAcceptedConditions, setHasAcceptedConditions] = useState<boolean>(false);

    const [userProfile, setUserProfile] = useState<UserProfile>(() => {
        return {
            companyName: "",
            email: "",
            firstName: "",
            lastName: "",
            phone: "",

            contactId: "",
            identityToken: "",
            uniqueId: "",
            provider: ""
        };
    });


    useEffect(() => {
        let code = query.get("Code") || query.get("code");
        if (code) {
            setRegistrationCode(code);
            setState(editorState.choosing_provider);
        }

        msalInstance.handleRedirectPromise()
            .then((tokenResponse) => {
                if (tokenResponse) {
                    const registrationDetails: RegistrationDetails = {
                        identityToken: tokenResponse.idToken,
                        uniqueId: tokenResponse.uniqueId,
                        registrationCode: code!,   //if this starts breaking, you'll need to send state back and forth
                        provider: "Microsoft",

                    };
                    validateRegistration(registrationDetails);
                }
            })
            .catch((error) => {
                setRegistrationError("Could not register with Microsoft");
            });
    }, []);


    useEffect(() => {
        if (!validationResult)
            return;

        if (validationResult.error) {
            setRegistrationError(validationResult.error);
            return;
        }

        if (validationResult.isLoggedIn) {
            navigate("/");
            return;
        }

        setUserProfile(validationResult);
        setState(editorState.editing_profile);
    }, [validationResult])


    useEffect(() => {
        if (registerUserResponse === undefined)
            return;

        if (registerUserResponse === true) {
            navigate("/");
        }
        else {
            setRegistrationError("Error processing registration.");
        }
    }, [registerUserResponse])

    useEffect(() => {
        if (isWaitingForValidationResponse || isWaitingForFinalResponse)
            setRegistrationError("");
    }, [isWaitingForValidationResponse, isWaitingForFinalResponse]);


    async function onMsButtonClick() {
        var redirectRequest: msal.RedirectRequest = {
            scopes: ['openid', 'profile'],
            prompt: "select_account",
        };
        await msalInstance.loginRedirect(redirectRequest);
    }

    async function onGoogleAuthSuccess(googleResponse: any) {
        let registration: RegistrationDetails = {
            identityToken: googleResponse.credential,
            uniqueId: "",
            registrationCode: registrationCode,
            provider: "Google"
        };

        validateRegistration(registration);
    }

    function onGoogleAuthFail(error: any) {
        if (error && error.details) {
            let message = error.details;

            if (message === "Cookies are not enabled in current environment.") {
                message = "Sign in is not supported from private/incognito mode.";
            }

            setRegistrationError(`Google: ${message}`);
        }
    }

    function handleChange(evt: any) {
        setUserProfile((old) => {
            return {
                ...old,
                [evt.target.id]: evt.target.value
            }
        });
    }

    function submitFinalRegistration() {
        var inputs = document.querySelectorAll('input');
        let invalids: string[] = [];

        inputs.forEach((i) => {
            let isValid = i.checkValidity();
            if (!isValid) {
                invalids.push(i.id);
            }
        });

        if (invalids.length == 0) {
            registerUser(userProfile);
        } else {
            let fields = invalids.join(", ");
            setValidationMessage(`The following fields have invalid values: ${fields}`);
        }
    }

    function NoRegistrationCode() {
        return (
            <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-login">
                <img src={mpactLogoWhite} height="30" className="pos-absolute left-0 top-0 m-3" />
            </div>
        );
    }

    function ChoosingProvider() {
        return (
            <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-login">
                <img src={mpactLogoWhite} height="30" className="pos-absolute left-0 top-0 m-3" />

                <FullSizeCentered>
                    <div className="container h-100 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card bg-acrylic z-depth-3 p-3">
                                    <div className="card-body">
                                        <h2 className="font-weight-bold my-4 text-center mb-1 mt-1">
                                            <strong>REGISTER</strong>
                                        </h2>
                                        <hr />

                                        <div className="row mt-5">
                                            <div className="col-md-6 ml-lg-5 ml-md-3">
                                                <div className="row pb-2 wow fadeIn">
                                                    <div className="col-2 col-lg-1">
                                                        <i className="fas fa-calendar-day fa-lg calendar"></i>
                                                    </div>
                                                    <div className="col-10">
                                                        <h4 className="font-weight-bold mb-2">
                                                            <strong>Accurate Data</strong>
                                                        </h4>
                                                        <p className="">
                                                            Daily updates from hundreds of McLeod customers deliver the best
                                                            pricing, market and lane insights available in our industry.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row pb-2 wow fadeIn" data-wow-delay="0.6s">
                                                    <div className="col-2 col-lg-1">
                                                        <i className="fas fa-hand-holding-usd fa-lg hand"></i>
                                                    </div>
                                                    <div className="col-10">
                                                        <h4 className="font-weight-bold mb-2">
                                                            <strong>Actionable Insights</strong>
                                                        </h4>
                                                        <p className="">
                                                            Highlights key decision indices and provides tools designed to make the most
                                                            important pricing decisions that impact and produce profitable opportunities.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row pb-2 wow fadeIn" data-wow-delay="1.2s">
                                                    <div className="col-2 col-lg-1">
                                                        <i className="fas fa-map-marker-alt fa-lg pin"></i>
                                                    </div>
                                                    <div className="col-10">
                                                        <h4 className="font-weight-bold mb-2">
                                                            <strong>Always Convenient</strong>
                                                        </h4>
                                                        <p className="">
                                                            Hosted in a secure cloud, the McLeod Data Science team
                                                            provides you with analytics in real-time anytime, anywhere.
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-5">
                                                <div className="row pb-2 d-flex justify-content-center mb-2">
                                                    <h4 className="mt-2 mr-4">
                                                        <strong>Register with</strong>
                                                    </h4>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <MicrosoftSignInButton
                                                        disabled={isWaitingForValidationResponse}
                                                        onClick={onMsButtonClick}
                                                    />

                                                    
                                                 <GoogleLogin onSuccess={onGoogleAuthSuccess} />

                                                    {/*
                                                    <GoogleLogin
                                                        disabled={isWaitingForValidationResponse}
                                                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
                                                        onSuccess={onGoogleAuthSuccess}
                                                        onFailure={onGoogleAuthFail}
                                                        render={GoogleSignInButton} />
                                                        */}

                                                </div>

                                                <div className="d-flex justify-content-center mb-2 p-3">
                                                    <span className="text-danger">{registrationError}</span>
                                                </div>


                                            </div>

                                        </div>

                                        <div className="row mt-2">
                                            <div className="col d-flex justify-content-end">
                                                <a href="https://www.mcleodsoftware.com" target="_blank">
                                                    <img src={mcLeodLogoBlack} height="70" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FullSizeCentered>
            </div>
        );
    }

    function EditingProfile() {
        return (
            <>
                <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-login">
                    <img src={mpactLogoWhite} height="30" className="pos-absolute left-0 top-0 m-3" />

                    <div className="container p-5">
                        <div className="row">
                            <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto">

                                <div className="card card-cascade cascading-admin-card user-card wow fadeIn faster bg-acrylic z-depth-3">
                                    <div className="admin-up d-flex justify-content-start">
                                        <div className="accent1-color mr-3 z-depth-2 bg-primary">
                                            <i className="fas fa-user-plus py-2 pr-2"></i>
                                            <span className="h3 text-white mr-3">Registration</span>
                                        </div>
                                    </div>

                                    <div className="card-body card-body-cascade">
                                        <div className="text-danger">{validationMessage}</div>

                                        <div className="row mt-2">

                                            <div className="col-md-8">
                                                <div className="md-form mb-0">
                                                    <MDBInput
                                                        type='text'
                                                        label="Company"
                                                        id='companyName'
                                                        value={userProfile.companyName}
                                                        required
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="md-form mb-0">
                                                    <MDBInput
                                                        type='text'
                                                        label="First Name"
                                                        id='firstName'
                                                        required
                                                        validate
                                                        value={userProfile.firstName}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="md-form mb-0">
                                                    <MDBInput
                                                        type='text'
                                                        label="Last Name"
                                                        id='lastName'
                                                        required
                                                        validate
                                                        value={userProfile.lastName}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="md-form mb-0">
                                                    <MDBInput
                                                        type='email'
                                                        label="Email"
                                                        id='email'
                                                        required
                                                        validate
                                                        value={userProfile.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="md-form mb-0">
                                                    <MDBInput
                                                        type='tel'
                                                        label="Telephone Number"
                                                        id='phone'
                                                        required
                                                        validate
                                                        value={userProfile.phone}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 my-2">
                                                <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="accept"
                                                        className="custom-control-input"
                                                        checked={hasAcceptedConditions}
                                                        onChange={e => setHasAcceptedConditions(e.target.checked)}
                                                    />
                                                    <label
                                                        htmlFor="accept"
                                                        className="custom-control-label">I have read and agree to the <a
                                                            style={{ textDecoration: 'underline' }}
                                                            onClick={() => {
                                                                setShowTermsModal(show => !show);
                                                            }}
                                                            className="accent5-text">terms and conditions</a></label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-12 text-center my-2">
                                                <CButton
                                                    shape="pill"
                                                    color="primary"
                                                    disabled={isWaitingForFinalResponse || !hasAcceptedConditions}
                                                    onClick={submitFinalRegistration}
                                                >
                                                    Register
                                                </CButton>
                                            </div>
                                        </div>

                                        <div className="mr-3 mt-2 d-flex justify-content-end">
                                            <a href="https://www.mcleodsoftware.com" target="_blank">
                                                <img src={mcLeodLogoBlack} height="50" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <CModal alignment="center" visible={showTermsModal} onClose={() => {
                    setShowTermsModal(false);
                }}>
                    <CModalHeader>
                        <CModalTitle>Terms and Conditions</CModalTitle>
                    </CModalHeader>

                    <CModalBody>
                        <p>
                            The information provided by McLeod Software (“McLeod”) in the MPact™ portal (“MPact”) is for general informational purposes only,
                            and is only available to McLeod customers validly licensed for use of the MPact product. All information available through
                            MPact is provided in good faith, however McLeod makes no representation or warranty of any kind, express or implied,
                            regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information in MPact.
                        </p>
                        <p>
                            Under no circumstance shall McLeod have any liability to you for any loss or damage of any kind incurred as a result of the
                            use of MPact or reliance on any information provided through the portal. Your use of MPact and your reliance on any information
                            in the portal is solely at your own risk
                        </p>
                        <p>
                            All information and content in the MPact portal is the intellectual property of McLeod.  You may not reuse, republish, or reprint
                            any of the content without the explicit prior written consent of McLeod.
                        </p>
                        <p>
                            In addition to the above, your use of MPact is governed by the terms and conditions of your Software License and Professional
                            Services Agreement (the “Agreement”) with McLeod, although where the specific limitations and disclaimers in this notice are
                            different than those in the Agreement, the provisions of this notice shall apply.
                        </p>

                    </CModalBody>

                    <CModalFooter>
                        <CButton color="info" onClick={() => setShowTermsModal(false)}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>



            </>
        );
    }

    if (state === editorState.no_registration_code)
        return <NoRegistrationCode />
    else if (state === editorState.choosing_provider)
        return <ChoosingProvider />
    else if (state === editorState.editing_profile)
        return <EditingProfile />
    else
        return <NoRegistrationCode />
}

export default Register;
