import React, { useState, useEffect } from 'react';
import { useAxios } from 'use-axios-client';
import { Geography, SelectionItem } from '../models/filters';


interface SharedGeographyProvider {
    markets: SelectionItem[];
    zones: SelectionItem[];
    zips: SelectionItem[];
    domains: SelectionItem[];
    setFilterValues: (sourceName: string, values: string[] | null) => void;
}

function unique(value, index, self) {
    return self.indexOf(value) === index;
}


export function useSharedGeographies(): SharedGeographyProvider {
    const { data } = useAxios<Geography[]>('/api/filters/geographies');
    const [geographies, setGeographies] = useState<Geography[]>([]);

    const [markets, setMarkets] = useState<SelectionItem[]>([]);
    const [zones, setZones] = useState<SelectionItem[]>([]);
    const [zips, setZips] = useState<SelectionItem[]>([]);
    const [domains, setDomains] = useState<SelectionItem[]>([]);

    const [marketFilter, setMarketFilter] = useState<string[]>([]);
    const [zoneFilter, setZoneFilter] = useState<string[]>([]);
    const [zipFilter, setZipFilter] = useState<string[]>([]);
    const [domainFilter, setDomainFilter] = useState<string[]>([]);

    useEffect(() => {
        if (!data) {
            return;
        }
        setGeographies(data);
    }, [data]);


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let zones = toSelectionList(geographies, "zone");
        let markets = toSelectionList(geographies, "kma", true);
        let zips = toSelectionList(geographies, "zip");
        let domains = toSelectionList(geographies, "domain");

        setZones(zones);
        setMarkets(markets);
        setZips(zips);
        setDomains(domains);
    }, [geographies])


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();

        let markets = toSelectionList(filtered, "kma", true);
        let zips = toSelectionList(filtered, "zip");
        let domains = toSelectionList(filtered, "domain");

        setMarkets(markets);
        setZips(zips);
        setDomains(domains);

        if (zoneFilter.length === 0) {
            let zones = toSelectionList(filtered, "zone");
            setZones(zones);
        }
    }, [zoneFilter])


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();

        let zones = toSelectionList(filtered, "zone");
        let zips = toSelectionList(filtered, "zip");
        let domains = toSelectionList(filtered, "domain");

        setZones(zones);
        setZips(zips);
        setDomains(domains);

        if (marketFilter.length === 0) {
            let markets = toSelectionList(filtered, "kma", true);
            setMarkets(markets);
        }



    }, [marketFilter])


    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();

        let zones = toSelectionList(filtered, "zone");
        let markets = toSelectionList(filtered, "kma", true);
        let domains = toSelectionList(filtered, "domain");

        setMarkets(markets);
        setZones(zones);
        setDomains(domains);

        if (zipFilter.length === 0) {
            let zips = toSelectionList(filtered, "zip");
            setZips(zips);
        }



    }, [zipFilter])

    useEffect(() => {
        if (!geographies) {
            return;
        }

        let filtered = filterGeographies();

        let zones = toSelectionList(filtered, "zone");
        let markets = toSelectionList(filtered, "kma", true);
        let zips = toSelectionList(filtered, "zip");

        setMarkets(markets);
        setZones(zones);
        setZips(zips);

        if (zipFilter.length === 0) {
            let domains = toSelectionList(filtered, "domain");
            setDomains(domains);
        }



    }, [domainFilter])


    function filterGeographies(): Geography[] {

        if (!zoneFilter.length && !marketFilter.length && !zipFilter.length && !domainFilter.length)
            return geographies;


        return geographies.filter(geo => {
            return (zoneFilter.length && zoneFilter.some(zone => geo.zone === zone)) ||
                (marketFilter.length && marketFilter.some(market => geo.kma === market)) ||
                (domainFilter.length && domainFilter.some(domain => geo.domain === domain)) ||
                (zipFilter.length && zipFilter.some(zip => geo.zip === zip));
        });
    }


    function toSelectionList(filtered: Geography[], key: string, alphaSort: boolean = false): SelectionItem[] {
        let selection = filtered.map(f => f[key])
            .filter(unique)
            .map(i => { return { label: i, value: i } });

        if (alphaSort) {
            selection.sort((a, b) => { return a.label.localeCompare(b.label); })
        }

        //console.log("selection from source");
        //console.log(selection);

        return selection;
    }


    function setFilterValues(sourceName: string, values: string[] | null) {
        if (values === null) {
            values = [];
        }

        if (sourceName === "markets") {
            setMarketFilter(values);
        }

        if (sourceName === "zones") {
            setZoneFilter(values);
        }

        if (sourceName === "zips") {
            setZipFilter(values);
        }

        if (sourceName === "domains") {
            setDomainFilter(values);
        }
    }


    return {
        markets,
        zones,
        zips,
        domains,
        setFilterValues
    };
}
