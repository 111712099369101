import React from 'react';
import microsoftSigninImage from './assets/img/ms-symbollockup_signin_light.png';
import SignInButtonProps  from './models/signin-button-props';

function MicrosoftSignInButton({ onClick, image, disabled }: SignInButtonProps) {
    return (
        <button className="btn p-0 border-white"
            disabled={disabled}
            name="provider"
            value="Microsoft"
            title="Log in using your Microsoft account"
            onClick={onClick}>

            <img alt="Microsoft sign-in" src={image || microsoftSigninImage} />
        </button>
    );
}

export default MicrosoftSignInButton;
