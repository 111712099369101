import { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import { CRow, CCol } from "@coreui/react";
import { ProFilterInfo } from '../models/filters';
import { subDays } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

const DatePicker2 = (props) => {
    interface DateRange {
        startDate: Date | null;
        endDate: Date | null;
    }

    const [open, setOpen] = useState(false);
    const instanceStart = useRef<any>();
    const instanceEnd = useRef<any>();

    const { updateFilter, getFilterValue } = useFilters();
    const { createDateRangeFilter } = useFilterFactory();

    const [range, setRange] = useState<DateRange>(() => {
        return {
            startDate: props.startDateDefault || null,
            endDate: props.endDateDefault || null
        };
    })

    const [rangeOrig, setRangeOrig] = useState<DateRange>();

    const debouncedRange = useDebouncedValue(range, 500);

    const defaultStartMin = new Date(2018, 1, 1);

    const [startDateMin, setStartDateMin] = useState<Date>(defaultStartMin);
    const [startDateMax, setStartDateMax] = useState<Date>();

    const [endDateMin, setEndDateMin] = useState<Date>();
    const [endDateMax, setEndDateMax] = useState<Date>(new Date());

    const [daysBackPrecursorFlag, setDaysBackPrecursorFlag] = useState(0);

    const years = yearRange(defaultStartMin.getFullYear(), endDateMax.getFullYear());

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const toggle = () => {
        if (open == true) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }

    };

    useEffect(() => {
        var defaultValue = getFilterValue(props.id) as DateRange;

        //console.log("date-filter defaultValue:");
        //console.log(defaultValue);


        if (defaultValue) {
            setRange(defaultValue);
            //console.log("defaultValue from []");
            //console.log(defaultValue);
            writeToFilter(defaultValue);
        }
        else {
            //console.log("defaultValue is null or undefined");
            var end = new Date();
            var start = new Date();

            end.setHours(0, 0, 0, 0);

            start.setDate(end.getDate() - 90);

            start.setHours(0, 0, 0, 0);

            let newRange: DateRange = {
                startDate: start,
                endDate: end
            };

            writeToFilter(newRange);
        }
    }, [])

    useEffect(() => {
        checkMinMaxDates();
        writeToFilter(range);

    }, [debouncedRange])


    useEffect(() => {
        let initRange: DateRange = {
            startDate: range.startDate,
            endDate: range.endDate
        };

        setRangeOrig(initRange);

    }, [open])

    useEffect(() => {
        if (daysBackPrecursorFlag > 0) {
            daysBackCalc(daysBackPrecursorFlag);
        }
        setDaysBackPrecursorFlag(0);
    }, [daysBackPrecursorFlag])

    function writeToFilter(defaultValue) {

        let filterStart = defaultValue.startDate || startDateMin;
        let filterEnd = defaultValue.endDate || endDateMax;

        let table = props.table;
        let column = props.column;

        let reportFilter = createDateRangeFilter(filterStart, filterEnd, table, column);

        let id = props.id;
        let group = props.group;
        let displayName = props.displayName;
        let sortIndex = props.sortIndex;

        const info: ProFilterInfo = {
            id,
            group,
            displayTextHeader: `${displayName}`,
            displayText: `${filterStart.toLocaleDateString('en-US')} to ${filterEnd.toLocaleDateString('en-US')}`,
            reset,
            reportFilter,
            rawValue: { startDate: filterStart, endDate: filterEnd },
            sortIndex
        };

        updateFilter(info);
    }

    const daysBackCalc = (offset) => {

        let ndDate = new Date();
        let stDate = new Date();


        ndDate.setHours(0, 0, 0, 0);

        stDate.setDate(stDate.getDate() - offset);

        stDate.setHours(0, 0, 0, 0);

        let daterange = instanceEnd.current!;
        let daterangeStart = instanceStart.current!;


        //console.log(daterange);

        if (daterange !== undefined) {
            //let test = daterange.getPreSelection();
            daterange.setSelected(ndDate);
            //daterange.setSelected(ndDate);
            //console.log("daterangeEnd: ");
            //console.log(daterange);
        }

        if (daterangeStart !== undefined) {
            //let test = daterange.getPreSelection();
            daterangeStart.setSelected(stDate);
            //daterange.setSelected(ndDate);
            //console.log("daterangeStart: ");
            //console.log(daterangeStart);
        }

        let newRange: DateRange = {
            //...range,
            startDate: stDate,
            endDate: ndDate
        };

        setRange(newRange);

        //console.log("daysBack start date: " + range.startDate + ":" + stDate);

        daterange.renderCalendar();
    }

    const daysBackPrecursor = () => {
        let nullRange: DateRange = {
            //...range,
            startDate: null,
            endDate: null
        };

        setRange(nullRange);

        let daterange = instanceEnd.current!;

        daterange.renderCalendar();
    }

    const daysBack = (offset) => {
        daysBackPrecursor();
        setDaysBackPrecursorFlag(offset);
        //console.log(offset);
    };

    const cancelAction = (offset) => {
        if (rangeOrig === undefined) {
            daysBack(90);
        }
        else {
            let initRange: DateRange = {
                startDate: rangeOrig.startDate,
                endDate: rangeOrig.endDate
            };

            setRange(initRange);
        }

        setOpen(false);
    };

    function yearRange(start: number, end: number) {

        var list: number[] = [];
        for (var i = start; i <= end; i++) {
            list.push(i);
        }
        return list;
    }

    function formatDate(date: Date | null) {
        if (date == null) {
            return "invalid"
        }
        return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    }

    function reset() {
        var end = new Date();
        var start = new Date();

        end.setHours(0, 0, 0, 0);

        start.setDate(end.getDate() - 90);

        start.setHours(0, 0, 0, 0);

        let range: DateRange = {
            startDate: start,
            endDate: end
        };

        //removeFilter(currentFilter);
        //setCurrentFilter(null);
        setRange(range);
    }

    function startDateFromNull(dateVar) {
        if (dateVar == null) {
            var date = new Date();

            return date;
        }

        return dateVar;
    }

    function endDateFromNull(dateVar) {
        if (dateVar == null) {
            var date = new Date();

            return date;
        }

        return dateVar;
    }

    function checkMinMaxDates() {
        if (!range.endDate) {
            setEndDateMax(new Date());
            if (!range.startDate) {
                setStartDateMin(subDays(new Date(), 90));
            }
        } else {
            let max = subDays(range.endDate, 1);
            setStartDateMax(max)
        }

    }

    return (
        <div className="container-date">
            <CRow className="mt-1 fp-row-date">
                <CCol className="dropdown-spacer2">
                    <label className="datepicker-label">{props.startDateLabelText}</label>
                    <button className="mt-1-fp" onClick={toggle} type="button">{formatDate(range.startDate)}</button>
                </CCol>

                <CCol className="dropdown-spacer2">
                    <label className="datepicker-label">{props.endDateLabelText}</label>
                    <button className="mt-1-fp" onClick={toggle} type="button">{formatDate(endDateFromNull(range.endDate))}</button>
                </CCol>
            </CRow>

            {open && (<div className="range-container" >
                <CRow className="mt-1 fp-row-date2">
                    <CCol className="date-buttons-column">
                        <label className="range-label" >Custom</label>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(7)} type="button">{"Last 7 days"}</button>
                        </div>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(30)} type="button">{"Last 30 days"}</button>
                        </div>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(60)} type="button">{"Last 60 days"}</button>
                        </div>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(90)} type="button">{"Last 90 days"}</button>
                        </div>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(365)} type="button">{"Last 1 year"}</button>
                        </div>
                        <div className="date-periods">
                            <button className="range-button" onClick={() => daysBack(365 * 5)} type="button">{"Last 5 years"}</button>
                        </div>
                        <div className="date-periods-buttons">
                            <button className="range-button-cancel" onClick={cancelAction} type="button">{"Cancel"}</button>
                            <div className="date-periods-button-spacer"></div>
                            <button className="range-button-apply" onClick={() => setOpen(false)} type="button">{"Apply"}</button>
                        </div>
                        {/*<div className="date-periods">*/}

                        {/*</div>*/}
                    </CCol>
                </CRow>

                <div className="dflex-date">
                    <div className="date-column">
                        <DatePicker
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div
                                    style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        {"<"}
                                    </button>
                                    <select
                                        value={date.getFullYear()}
                                        onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>

                                    <select
                                        value={months[date.getMonth()]}
                                        onChange={({ target: { value } }) =>
                                            changeMonth(months.indexOf(value))
                                        }>
                                        {months.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        {">"}
                                    </button>
                                </div>
                            )}
                            selected={range.startDate}
                            ref={instanceStart}
                            onChange={(val) => {
                                let newRange: DateRange = {
                                    ...range,
                                    startDate: val
                                };

                                setRange(newRange);

                            }}
                            inline
                            minDate={startDateMin}
                            maxDate={startDateMax}
                            isClearable
                        />
                    </div>
                    <div>
                    </div>


                    <div className="date-column2">
                        <DatePicker
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                            }) => (
                                <div
                                    style={{
                                        margin: 10,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                        {"<"}
                                    </button>
                                    <select
                                        value={date.getFullYear()}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                    >
                                        {years.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>

                                    <select
                                        value={months[date.getMonth()]}
                                        onChange={({ target: { value } }) =>
                                            changeMonth(months.indexOf(value))
                                        }
                                    >
                                        {months.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>

                                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                        {">"}
                                    </button>
                                </div>
                            )}
                            selected={range.endDate}
                            ref={instanceEnd}

                            onChange={(val) => {
                                let newRange: DateRange = {
                                    ...range,
                                    endDate: val
                                };

                                setRange(newRange);

                                if (range.startDate != null && range.startDate >= val) {
                                    let newRange2: DateRange = {
                                        ...range,
                                        startDate: subDays(val, 1)
                                    };

                                    setRange(newRange2);

                                }

                            }}
                            inline
                            minDate={endDateMin}
                            maxDate={endDateMax}
                            isClearable
                        />
                    </div>
                </div>
            </div>)}
        </div>

    );
};

export default DatePicker2;