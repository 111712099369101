import React, { useState, useEffect } from 'react';
import AdminPageFraming from './AdminPageFraming';
import { useParams } from 'react-router-dom';
import { useLazyAxios } from 'use-axios-client';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';

interface Contact {
    id: string;
    batchId: string;
    scac: string;
    company: string;
    firstName: string;
    lastName: string;
    email: string;
    isRegistered: boolean;
    code: string;
}

function Contacts() {
    const { id } = useParams()
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [getContacts, { data, loading, error }] = useLazyAxios<Contact[]>('/api/admin/contacts', { method: 'POST' });
    const [deleteUser, { data: deleteResponse, loading: isAwaitingDeleteResponse}] = useLazyAxios<Contact[]>('/api/admin/deleteUser', { method: 'POST' });

    const [activeContact, setActiveContact] = useState<Contact | null>(null);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (id) {
            getContacts({ batchId: id });
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            setContacts(data);
        }
    }, [data]);

    useEffect(() => {
        if (deleteResponse === undefined) {
            return;
        }
        setContacts(deleteResponse);
    }, [deleteResponse]);


    function MapContact(contact: Contact, ix: number) {
        function LastCell() {
            if (contact.isRegistered) {
                return (
                    <>
                        <i className="fa fa-lg fa-check text-success m-2"></i>
                        <button
                            disabled={isAwaitingDeleteResponse}
                            className="btn btn-sm btn-danger waves-effect m-1 p-2"
                            onClick={() => {
                                setActiveContact(contact);
                                setIsModalVisible(true);
                        }}>
                            <i className="fa fa-lg fa-trash-alt text-white"></i>
                        </button>
                    </>
                );
            }
            else if (contact.email) {
                return (
                    <>

                        { id != '99' &&
                            <button className="btn btn-sm btn-primary btn-clipboard waves-effect m-1 p-2" title={contact.code} onClick={() => {
                                navigator.clipboard.writeText(contact.code);
                            }}>
                                <i className="fa fa-lg fa-copy text-white"></i>
                            </button>
                        }

                        <button
                            disabled={isAwaitingDeleteResponse}
                            className="btn btn-sm btn-danger waves-effect m-1 p-2"
                            onClick={() => {
                            setActiveContact(contact);
                            setIsModalVisible(true);
                        }}>
                            <i className="fa fa-lg fa-trash-alt text-white"></i>
                        </button>
                    </>
                );
            }
        }

        return (
            <tr key={ix}>
                <td>
                    {contact.scac}
                </td>
                <td>
                    {contact.company}
                </td>
                <td>
                    {`${contact.firstName} ${contact.lastName}`}
                </td>
                <td>
                    {contact.email}
                </td>
                <td className="text-right">
                    {LastCell()}
                </td>
            </tr >
        )
    }



    if (loading)
        return <AdminPageFraming />

    if (error)
        return <AdminPageFraming><h1 className="text-danger">Error loading contacts</h1></AdminPageFraming>

    return (
        <AdminPageFraming>
            <div className="mask h-100 w-100 d-flex justify-content-center align-items-center">
                <div className="table-wrapper-scroll-y my-custom-scrollbar" style={{ maxHeight: '75vh' }}>
                    <table className="table table-sm table-responsive table-borderless table-striped table-hover table-scroll-y text-nowrap bg-white z-depth-3">
                        <thead>
                            <tr>
                                <th>
                                    SCAC
                                </th>
                                <th>
                                    Company
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map(MapContact)}
                        </tbody>
                    </table>
                </div>
            </div>


            <CModal alignment="center" visible={isModalVisible} onClose={() => {
                setActiveContact(null);
                setIsModalVisible(false);
            }}>
                <CModalHeader className="bg-danger text-white">
                    <CModalTitle>Delete User</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div className="row">
                        <div className="col-3">
                            <p></p>
                            <p className="text-center">
                                <i className="fas fa-trash-alt fa-4x mb-3 animated bounceIn float-left text-danger"></i>
                            </p>
                        </div>

                        <div className="col-9">
                            <p>Deleting the user will prevent the user from using MPact. Would you like to delete this user?</p>
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="danger"
                        variant="outline"
                        onClick={
                            () => {
                                if (activeContact) {
                                    deleteUser({
                                        contactId: activeContact.id,
                                        batchId: activeContact.batchId
                                    });
                                }
                                setIsModalVisible(false);
                            }
                        }
                    >Delete</CButton>

                    <CButton color="danger" onClick={() => setIsModalVisible(false)}>
                        Cancel
                    </CButton>
                </CModalFooter>
            </CModal>

        </AdminPageFraming>
    );
}

export default Contacts;
