import React, { ReactChild, useEffect, useState } from 'react';

const defaultContainerClasses = "d-flex justify-content-center align-items-center";

function LoadingIndicator({ className, show, imagePath }: { className?: string, show?: boolean, imagePath: string }) {
    const [containerCssClass, setContainerCssClass] = useState<string>(defaultContainerClasses);

    useEffect(() => {
        const containerClasses = `${defaultContainerClasses} ${className ?? ''}`;
        setContainerCssClass(containerClasses);
    }, [className]);

    return (
        <div className={show === false ? 'd-none' : containerCssClass}>
            <img src={imagePath} className="loading-animation" height="100vw" />
        </div>
    );

}

export default LoadingIndicator;