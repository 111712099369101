import React, { useState, useEffect } from 'react';
import { SelectionItem } from '../models/filters';


interface SharedHaulLengthProvider {
    haulLengths: SelectionItem[];
    mileages: SelectionItem[];
    setFilterValues: (sourceName: string, values: string[] | null) => void;
}

interface HaulGroup {
    length: string;
    mileage: string;
}

function unique(value, index, self) {
    return self.indexOf(value) === index;
}


const haulData: HaulGroup[] = [
    //{ length: "City", mileage: "Less" },
    //{ length: "Long", mileage: "Greater" },
    //{ length: "Mid", mileage: "Greater" },
    //{ length: "Short", mileage: "Less" },
    //{ length: "Tweener", mileage: "Greater" },
    { length: "City", mileage: "<=250" },
    { length: "Long", mileage: ">250" },
    { length: "Mid", mileage: ">250" },
    { length: "Short", mileage: "<=250" },
    { length: "Tweener", mileage: ">250" },
];


export function useSharedHauls(): SharedHaulLengthProvider {

    const [allHaulLengths, setAllHaulLengths] = useState<SelectionItem[]>(() => {
        return toSelectionList(haulData, "length");
    });

    const [allMileages, setAllMileages] = useState<SelectionItem[]>(() => {
        return toSelectionList(haulData, "mileage");
    });

    const [filteredLengths, setFilteredLengths] = useState<SelectionItem[]>(allHaulLengths);
    const [filteredMileages, setFilteredMileages] = useState<SelectionItem[]>(allMileages);


    const [haulLengthFilter, setHaulLengthFilter] = useState<string[]>([]);
    const [mileageFilter, setMileageFilter] = useState<string[]>([]);


    useEffect(() => {
        let filtered = filterHaulData();
        let selections = toSelectionList(filtered, "mileage");
        let fromAll = allMileages.filter(item => selections.some(s => s.label === item.label));

        setFilteredMileages(fromAll);

        if (haulLengthFilter.length === 0) {
            let selections = toSelectionList(filtered, "length");
            let fromAll = allHaulLengths.filter(item => selections.some(s => s.label === item.label));
            setFilteredLengths(fromAll);
        }
    }, [haulLengthFilter])

    useEffect(() => {
        //console.log("filteredMileages: ");
        //console.log(filteredMileages);
    }, [filteredMileages])

    
    useEffect(() => {
        let filtered = filterHaulData();
        let selections = toSelectionList(filtered, "length");
        let fromAll = allHaulLengths.filter(item => selections.some(s => s.label === item.label));
        setFilteredLengths(fromAll);

        if (mileageFilter.length === 0) {
            let selections = toSelectionList(filtered, "mileage");
            let fromAll = allMileages.filter(item => selections.some(s => s.label === item.label));
            setFilteredMileages(fromAll);
        }
    }, [mileageFilter])


    function filterHaulData(): HaulGroup[] {
        if (!haulLengthFilter.length && !mileageFilter.length)
            return haulData;

        return haulData.filter(haul => {
            return (mileageFilter.length && mileageFilter.some(mileage => haul.mileage === mileage)) ||
            (haulLengthFilter.length && haulLengthFilter.some(len => haul.length === len));
        });
    }


    function toSelectionList(filtered: any[], key: string, alphaSort: boolean = false): SelectionItem[] {
        let selection = filtered.map(f => f[key])
            .filter(unique)
            .map(i => { return { label: i, value: i } });

        if (alphaSort) {
            selection.sort((a, b) => { return a.label.localeCompare(b.label); })
        }

        return selection;
    }


    function setFilterValues(sourceName: string, values: string[] | null) {
        if (values === null) {
            values = [];
        }

        //console.log("useSharedHaulFilter values for id: " + sourceName);
        //console.log(values);

        if (sourceName === "loh") {
            setHaulLengthFilter(values);
        }

        if (sourceName === "mileage") {
            setMileageFilter(values);
        }
    }


    return {
        haulLengths: filteredLengths,
        mileages: filteredMileages,
        setFilterValues
    };
}
