import React, { useState, useEffect } from 'react';
import { SelectionItem, ProFilterInfo, RadioButtonInputProps } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
//import Select from 'react-select/dist/declarations/src/Select';
import { useDebouncedValue } from '../hooks/useDebouncedValue';

export default function PbiCheckboxFilterInput(props: RadioButtonInputProps) {

    const { id, group, table, column,
        displayName, className, defaultValue,
        values, sortIndex } = props;

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const { updateFilter } = useFilters();
    const { createBasicFilter } = useFilterFactory();

    const [selected, setSelected] = useState<SelectionItem>(() => {
        let val = values.find(di => di.value === defaultValue);
        return val!;
    });

    const debouncedSelection = useDebouncedValue(selected, 100);

    useEffect(() => {
        let value = selected.value;
        let reportFilter = createBasicFilter({ table, column, value })

        let filter: ProFilterInfo = {
            id,
            group,
            reportFilter,
            displayTextHeader: `${displayName}`,
            displayText: `${selected.label}`,
            reset,
            isReadonly: false,
            isPreload: selected.value === defaultValue,
            rawValue: { value },
            sortIndex: sortIndex
        };

        updateFilter(filter);
        let passBool: any = demodulateDefaultValue(id.toString() + value?.toString());
        setIsChecked(passBool);
    }, [debouncedSelection]);


    function demodulateDefaultValue(val) {

        switch (val) {
            case "fuelWith Fuel":
                return true;
            case "market_dataWith Me":
                return true;
            case "rate_basisFlat":
                return false;
            case "fuelWithout Fuel":
                return false;
            case "market_dataWithout Me":
                return false;
            case "rate_basisRPM":
                return true;
        }
    }

    function findSelectedItem(val) {
        switch (val) {
            case "fueltrue":
                return { label: "With Fuel", value: "With Fuel" };
            case "fuelfalse":
                return { label: "Without Fuel", value: "Without Fuel" };
            case "market_datatrue":
                return { label: "With Me", value: "With Me" };
            case "market_datafalse":
                return { label: "Without Me", value: "Without Me" };
            case "rate_basistrue":
                return { label: "RPM", value: "RPM" };
            case "rate_basisfalse":
                return { label: "Flat", value: "Flat" };
            default:
                throw new Error("Invalid value");
        }
    }

    function reset() {
        let newValue = values.find(di => di.value === defaultValue)!;
        setSelected(newValue);
    }

    function handleChange(evt: any) {
        let val = evt.target.checked;
        let selectedItem = findSelectedItem(id.toString() + val)!;
        setSelected(selectedItem);
        setIsChecked(val);
    }

    return (
        <div className={className ?? ""}>
            <div>
                <input
                    className="check-box"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                ></input>

                <label htmlFor={id} className="fuel-spacer" >
                    {displayName}
                </label>
            </div>
        </div>
    );
}

