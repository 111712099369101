import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { models, Report as PbiReport, Page as PbiPage } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react';

import Header from './Header';
import Footer from './Footer';

import { Page } from './models/page';

import { useEmbedToken } from './hooks/useEmbedTokens';
import { ReportViewerProps } from './models/report';
import { useQueryStringFilters } from './hooks/useQueryStringFilters';
import { usePopoutLink } from './hooks/usePopoutLink';
import logo from './assets/img/MPACT_Logo_white_sm.png';

import { Helmet } from 'react-helmet';

import { useTelemetry } from './pro/TelemetryContext';

import ChatBot from './ChatBot';
import ButtonRatePred from './pro/ButtonRatePred';

async function applyPageFilters(report: PbiReport, page: any, pageFilters: any[]) {
    const defaultFiltered = "MPact RPM";
    if (page.displayName === defaultFiltered) {
        await page.updateFilters(models.FiltersOperations.Replace, pageFilters.map(pf => pf.filter));
    }
}


function ReportViewer({ userReport }: ReportViewerProps) {
    const { pageUri } = useParams();
    const embeddedReport = useRef<any>(false);
    const [activePage, setActivePage] = useState<string>(userReport.defaultPage.powerBiInternalName);
    const [activePageTitle, setActivePageTitle] = useState<string>(userReport.defaultPage.displayName);
    const { token } = useEmbedToken(userReport.embedToken);
    const [isLoadingReport, setIsLoadingReport] = useState<boolean>(true);
    const navigate = useNavigate();
    const { link } = usePopoutLink();
    const { filters: queryStringFilters, userId } = useQueryStringFilters();
    const { setSession, logAction, setUser } = useTelemetry();
    const [isChatbot, setIsChatbot] = useState(false);
    const [isRatePred, setIsRatePred] = useState(false);
    const [chatbotScac, setChatbotScac] = useState(false);

    const embedConfig = useRef<any>({
        type: 'report',
        id: userReport.reportId,
        embedUrl: userReport.embedUrl,
        accessToken: token,
        pageName: activePage,
        settings: {
            panes: {
                filters: {
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            },
            background: models.BackgroundType.Transparent
        },
        tokenType: models.TokenType.Embed
    });

    useEffect(() => {
        setSession(userReport.sessionId);
        setUser(userId!);
        setIsChatbot(userReport.isChatbotReport);
        setIsRatePred(userReport.isRatePredReport);
        console.log("IsChat: " + isChatbot);
        logAction("reportViewerLoaded");
        setChatbotScac(userReport.isChatbotUser)


        return () => {
            logAction("reportViewerUnloaded");
        }
    }, [])

    useEffect(() => {
        embedConfig.current = {
            ...embedConfig.current,
            accessToken: token
        };
    }, [token])


    useEffect(() => {
        const defaultPage = userReport.pages.find(p => p.isDefault);
        const pageMatch = userReport.pages.find(p => p.uri === pageUri);


        if (pageMatch) {
            setActivePage(pageMatch.powerBiInternalName);
            setActivePageTitle(pageMatch.displayName);
            embedConfig.current.pageName = pageMatch.powerBiInternalName;

            logAction("pageChanged", { "newPage": pageMatch.displayName });
        }
        else if (defaultPage) {
            setActivePage(defaultPage.powerBiInternalName);
            setActivePageTitle(defaultPage.displayName);
            embedConfig.current.pageName = defaultPage.powerBiInternalName;

            logAction("pageChanged", { "newPage": defaultPage.displayName });
        }


        return () => {
            setActivePage("");
            setActivePageTitle("");
        };
    }, [pageUri]);


    useEffect(() => {
        if (embeddedReport.current !== false) {
            (async () => {
                await embeddedReport.current.setPage(activePage);
            })();
        }
    }, [activePage])


    //useEffect(() => {
    //    if (embeddedReport.current !== false) {
    //        const filteredPages: PbiPage[] = [];
    //        const initialFilters: any = {};
    //        let page: PbiPage;

    //        (async () => {
    //            await embeddedReport.current.setPage(activePage);

    //            page = await embeddedReport.current.getPageByName(activePage);
    //            filteredPages.push(page);

    //            let startingFilters = await page.getFilters();
    //            if (startingFilters.length > 0) {
    //                initialFilters[page.name] = [...startingFilters];
    //            }

    //            embeddedReport.current.on('pageChanged', async (evt) => {
    //                let detail = evt.detail as any;
    //                let newPage: PbiPage = detail.newPage;

    //                if (!initialFilters.hasOwnProperty(newPage.name)) {
    //                    let startingFilters = await newPage.getFilters();
    //                    if (startingFilters.length > 0) {
    //                        initialFilters[page.name] = [...startingFilters];
    //                    }
    //                }

    //                if (!filteredPages.includes(newPage)) {
    //                    filteredPages.push(newPage);
    //                    await applyPageFilters(embeddedReport.current, newPage, queryStringFilters);
    //                }
    //            });

    //            await applyPageFilters(embeddedReport.current, page, queryStringFilters);
    //        })();

    //        return () => {
    //            embeddedReport.current.off('pageChanged');
    //            filteredPages.forEach(async p => {
    //                await p.removeFilters();

    //                if (initialFilters.hasOwnProperty(p.name)) {
    //                    await p.setFilters(initialFilters[p.name]);
    //                }
    //            });
    //        }
    //    }
    //}, [activePage]);

    const onPbiReportEvent = useCallback((event: any) => {
        let type = event.type;
        let details = event.detail;
        let keys = Object.keys(details)
            .filter(k => k !== "report" && k !== "filters" && k !== "page" && details[k]);

        let toLog: any = {};
        keys.forEach(k => toLog[k] = details[k]);

        logAction(type, toLog);
    }, [activePageTitle, logAction])




    let embedEventHandlers: Map<string, any> = new Map<string, any>([
        ['rendered', async function (e: any, pbiReport: PbiReport) {
            logAction("reportRendered");

            if (embeddedReport.current === false) {
                embeddedReport.current = pbiReport;
                pbiReport.on("buttonClicked", onPbiReportEvent);
                pbiReport.on("commandTriggered", onPbiReportEvent);
                pbiReport.on("dataHyperlinkClicked", onPbiReportEvent);
                pbiReport.on("dataSelected", onPbiReportEvent);
                pbiReport.on("selectionChanged", onPbiReportEvent);
                pbiReport.on("visualClicked", onPbiReportEvent);

                const defaultPage = userReport.pages.find(p => p.isDefault);
                let page = await pbiReport.getPageByName(defaultPage!.powerBiInternalName);
                await applyPageFilters(embeddedReport.current, page, queryStringFilters);
            }
            setIsLoadingReport(false);
        }],



        ['error', async function (event: any) {
            //logAction("reportError", event.detail)
        }]
    ]);

    async function changePage(page: Page) {
        const route = `/${page.uri}`;
        navigate(route);
    }

    async function changeHambColor(page: Page) {
        const route = `/${page.uri}`;
        navigate(route);
    }


    /*if (userReport.hideFraming) {*/

    //const rlw = true;
    //if (rlw) {
    if (userReport.hideFraming) {


        return (
            <>
                <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report" style={{ overflow: 'hidden' }}>
                    <span
                        title="Click here to access the full power of MPact!"
                        className='collapsed-background base-mpact mb-2 button-left'

                        onClick={() => {
                            var params = [
                                'height=' + window.screen.height,
                                'width=' + window.screen.width
                            ].join(',');

                            window.open(link, "MPactPopup", params);
                        }}>
                        Launch MPact!
                        {/*<img src={logo} height="12" className="ml-1" />*/}
                    </span>
                    <span
                        title="Upgrade to MPact Pro today!"
                        className='collapsed-background base-mpact mb-2 upgrade-right'

                        onClick={() => {
                            var params = [
                                'height=' + window.screen.height,
                                'width=' + window.screen.width
                            ].join(',');


                            let protocol = "https://";
                            let host = window.location.host;
                            let path = 'upgrade';

                            let link = protocol + host + '/' + path;
                            var url = new URL(link);
                            window.open(url.href, "MPactPopup", params)
                        }}
                    >&nbsp;&nbsp;Upgrade to MPact Pro!</span>

                    <div className="report-viewer mt-3">
                        <PowerBIEmbed
                            embedConfig={embedConfig.current}
                            eventHandlers={embedEventHandlers}
                            cssClassName='report embed-rendered'
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`${activePageTitle} | MPact`}</title>
            </Helmet>
            <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report report-page">
                <Header title={activePageTitle} onPageClick={changePage} hideMenuButton={isLoadingReport} userReport={userReport} showProUpgrade={userReport.isLmeRequest} />
                <div className="report-viewer">
                    <PowerBIEmbed
                        embedConfig={embedConfig.current}
                        eventHandlers={embedEventHandlers}
                        cssClassName='report embed-rendered'
                    />
                    {/*{isChatbot && chatbotScac && (<ChatBot isProClass="button-base" />)}*/}
                    {isRatePred && (<ButtonRatePred isProClass="button-base-rp-new" />)}
                </div>
                <Footer hideFooterText={isLoadingReport} className="text-white" />
                {/*<script type="text/javascript" async src="//l.getsitecontrol.com/zwxjoop7.js"></script>*/}
            </div>
        </>
    );
}

export default ReportViewer;
