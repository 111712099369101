import React, { useState, useEffect } from 'react';
import AdminPageFraming from './AdminPageFraming';
import mcleodLogoBlack from '../assets/img/McLeodLogo_black.png';
import { useLazyAxios } from 'use-axios-client';
import { useNavigate } from 'react-router-dom';
import { useAdminPage } from '../hooks/useAdminPage';
import FullSizeCentered from '../FullSizeCentered';
import { MDBInput } from "mdbreact";
import { CButton } from '@coreui/react';

interface Contact {
    batch: number;
    customerCode: string;
    customerName: string;
    firstName: string;
    lastName: string;
    position: string;
    email: string;
    phone: string;
}

function AddContact() {
    const { isAuthPending } = useAdminPage();

    const [contact, setContact] = useState<Contact>({
        batch: 0,
        customerCode: "",
        customerName: "",
        firstName: "",
        lastName: "",
        position: "",
        email: "",
        phone: ""
    });

    const [addContact, { data: addResponse, error: addError, loading: isWaitingOnResponse }] = useLazyAxios<boolean>('/api/admin/addContact', { method: 'POST' });
    const [validationMessage, setValidationMessage] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        if (addResponse === undefined)
            return;

        if (addResponse === true) {
            navigate('/admin/');
        } else {
            setValidationMessage('Failed validation.  Please ensure all fields are populated.')
        }

        return () => { setValidationMessage(''); }
    }, [addResponse])

    function handleChange(evt: any) {
        setContact((old) => {
            return {
                ...old,
                [evt.target.id]: evt.target.value
            }
        });
    }

    function submitContact() {
        var inputs = document.querySelectorAll('input');
        let invalids: string[] = [];

        inputs.forEach((i) => {
            let isValid = i.checkValidity();
            if (!isValid) {
                invalids.push(i.id);
            }
        });

        if (invalids.length == 0) {
            addContact(contact);
        } else {
            let fields = invalids.join(", ");
            setValidationMessage(`The following fields have invalid values: ${fields}`);
        }
    }

    if (isAuthPending) {
        return (
            <AdminPageFraming>
                <FullSizeCentered>
                    <h1 className="text-white">Loading ...</h1>
                </FullSizeCentered>
            </AdminPageFraming>
        );
    }

    return (
        <AdminPageFraming>
            <div className="container p-5">
                <div className="row">
                    <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto">

                        <div className="card card-cascade cascading-admin-card user-card wow fadeIn faster bg-acrylic z-depth-3">
                            <div className="admin-up d-flex justify-content-start">
                                <div className="accent1-color mr-3 z-depth-2 bg-primary">
                                    <i className="fas fa-user-plus py-2 pr-2"></i>
                                    <span className="h3 text-white mr-3">Add Contact</span>
                                </div>
                            </div>

                            <div className="card-body card-body-cascade">
                                <div className="text-danger">{validationMessage}</div>

                                <div className="row mt-2">
                                    <div className="col-md-2">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='number'
                                                label="Batch"
                                                id='batch'
                                                required
                                                validate
                                                value={contact.batch}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='text'
                                                label="SCAC"
                                                id='customerCode'
                                                required
                                                validate
                                                value={contact.customerCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='text'
                                                label="Company"
                                                id='customerName'
                                                required
                                                validate
                                                value={contact.customerName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='text'
                                                label="First Name"
                                                id='firstName'
                                                required
                                                validate
                                                value={contact.firstName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='text'
                                                label="Last Name"
                                                id='lastName'
                                                required
                                                validate
                                                value={contact.lastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='text'
                                                label="Position"
                                                id='position'
                                                required
                                                validate
                                                value={contact.position}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='email'
                                                label="Email"
                                                id='email'
                                                required
                                                validate
                                                value={contact.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="md-form mb-0">
                                            <MDBInput
                                                type='tel'
                                                label="Telephone Number"
                                                id='phone'
                                                required
                                                validate
                                                value={contact.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 text-center my-2">
                                        <CButton
                                            shape="pill"
                                            color="primary"
                                            disabled={isWaitingOnResponse}
                                            onClick={submitContact}
                                        >
                                            Create
                                        </CButton>
                                    </div>
                                </div>


                                <div className="mr-3 mt-2 d-flex justify-content-end">
                                    <a href="https://www.mcleodsoftware.com" target="_blank">
                                        <img src={mcleodLogoBlack} height="50" />
                                    </a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </AdminPageFraming>
    );
}

export default AddContact;
