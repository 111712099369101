import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { models, Report as PbiReport, Page as PbiPage } from 'powerbi-client'
import { useTelemetry } from './TelemetryContext';

//necessary items to pull GeoMaster into filter context
import { ProFilterInfo, GeographyComplete } from '../models/filters';

interface FilterContextValue {
    activeFilters: ProFilterInfo[];
    allFilters: ProFilterInfo[];
    removeFilter: Function;
    updateFilter: Function;
    setEmbed: Function;
    canRemoveAll: boolean;
    removeAll: Function;
    registerPreloadedFilters: Function;
    isReady: boolean;
    getFilterValue: Function;
    areBasicFiltersVisible: boolean;
    areAdvancedFiltersVisible: boolean;
    toggleBasicFilters: Function;
    toggleAdvancedFilters: Function;
    geoMaster: GeographyComplete[];
}

const filterContextDefault: FilterContextValue = {
    activeFilters: [],
    allFilters: [],
    removeFilter: () => { },
    updateFilter: () => { },
    setEmbed: () => { },
    canRemoveAll: false,
    removeAll: () => { },
    getFilterValue: (id: string) => { return null; },
    registerPreloadedFilters: Function,
    isReady: false,
    areBasicFiltersVisible: false,
    areAdvancedFiltersVisible: false,
    toggleBasicFilters: () => { },
    toggleAdvancedFilters: () => { },
    geoMaster: []
};


export const FilterContext = React.createContext<FilterContextValue>(filterContextDefault);


export function useFilters() {
    const context = useContext(FilterContext);
    return context;
}



export function FilterProvider(props: any) {
    const [lockedFilters, setLockedFilters] = useState<any[]>([]);
    const [preloadFilters, setPreloadFilters] = useState<ProFilterInfo[]>([]);
    const [paneFilters, setPaneFilters] = useState<ProFilterInfo[]>([]);

    const [geographies, setGeographies] = useState<GeographyComplete[]>([]);

    const { logAction } = useTelemetry();

    const [activeFilters, setActiveFilters] = useState<ProFilterInfo[]>([]);
    const [allFilters, setAllFilters] = useState<ProFilterInfo[]>([]);

    const [hasEmbed, setHasEmbed] = useState<boolean>(false);

    const embed = useRef<any>(false);
    const initialRender = useRef<boolean>(true);

    const [areBasicFiltersVisible, setAreBasicFiltersVisible] = useState<boolean>(false);
    const [areAdvancedFiltersVisible, setAreAdvancedFiltersVisible] = useState<boolean>(false);

    const [isInitialRenderComplete, setIsInitialRenderComplete] = useState<boolean>(false);

    const [geoMaster, setGeoMaster] = useState<GeographyComplete[]>([]);

    useEffect(() => {
        console.log("Filter Context initial render");
        downloadAndSetGeographies();
        setIsInitialRenderComplete(true);

        return () => {
            console.log("Filter context destroyed");
        };
    }, [])


    const toggleBasicFilters = useCallback(() => {
        let wasVisible;
        setAreBasicFiltersVisible(vis => {
            wasVisible = vis;
            return !vis;
        });

        if (wasVisible) {
            setAreAdvancedFiltersVisible(false);
        }
    }, []);

    const toggleAdvancedFilters = useCallback(() => {
        setAreAdvancedFiltersVisible(vis => !vis);
    }, []);


    useEffect(() => {
        let all = [...preloadFilters];
        all.push(...paneFilters);
        setActiveFilters(all);
        setAllFilters(all);

        const reportFilters = all.map(rf => rf.reportFilter);
        reportFilters.push(...lockedFilters);

        if (hasEmbed) {
            if (initialRender.current === false) {
                (async function () {
                    await embed.current.updateFilters(models.FiltersOperations.ReplaceAll, reportFilters);
                }());
            }
            else {
                initialRender.current = false;
            }
        }
    }, [preloadFilters, paneFilters, hasEmbed, lockedFilters])

    useEffect(() => {
        if (!geographies || geographies.length === 0) {
            return;
        }
        setGeoMaster(geographies);
    }, [geographies]);

    const downloadAndSetGeographies = async () => {
        let serverResponse = await fetch('/api/filters/geographiesComplete', { method: 'get' });
        if (serverResponse.ok) {
            let geos = await serverResponse.json();
            setGeographies(geos);
        } else {
            throw Error("Failed to download geographies.");
        }
    };

    function getFilterValue(tableName: string, columnName: string): any {
        let all = [...preloadFilters];
        /*all.push(...activeFilters);*/
        all.push(...paneFilters);

        for (var i = 0; i < all.length; i++) {
            let currentFilter = all[i];
            let currentTarget = currentFilter.reportFilter.target;

            if (currentTarget.table === tableName && currentTarget.column === columnName) {
                return currentFilter.rawValue;
            }
        }
        return null;
    }

    async function removeFilter(filterInfo: ProFilterInfo) {
        if (embed.current === false || !filterInfo) {
            return;
        }

        let preloadRemoved = preloadFilters.filter(f => f.id !== filterInfo.id);
        setPreloadFilters(preloadRemoved);

        let paneRemoved = paneFilters.filter(f => f.id !== filterInfo.id);
        setPaneFilters(paneRemoved);

        let details: any = {};
        details["table"] = filterInfo.reportFilter.target.table;
        details["column"] = filterInfo.reportFilter.target.column;
        details["value"] = filterInfo.reportFilter.values || filterInfo.reportFilter.conditions;

        logAction("removeFilter", details);
    }

    async function removeAll() {
        if (embed.current === false) {
            return;
        }

        logAction("removeAllFilters", "");

        let fromPane = [...paneFilters];
        setPaneFilters([]);
        fromPane.forEach(pf => { pf.reset(); })
    }

    async function updateFilter(filterInfo: ProFilterInfo) {
        if (embed.current === false || !filterInfo) {
            return;
        }

        let details: any = {};
        details["table"] = filterInfo.reportFilter.target.table;
        details["column"] = filterInfo.reportFilter.target.column;
        details["value"] = filterInfo.reportFilter.values || filterInfo.reportFilter.conditions;

        logAction("addOrUpdateFilter", details);

        let paneUpdates = paneFilters.filter(pf => pf.id !== filterInfo.id);
        paneUpdates.push(filterInfo);

        setPaneFilters(paneUpdates);

        const targetTable = filterInfo.reportFilter.target.table;
        if (preloadFilters.some(pf => pf.reportFilter.target.table === targetTable)) {
            let removed = preloadFilters.filter(pf => pf.reportFilter.target.table !== targetTable);
            setPreloadFilters(removed);
        }
    }

    function registerPreloadedFilters(infos: ProFilterInfo[]) {
        if (infos.length === 0 || preloadFilters.length > 0) {
            return;
        }
        setPreloadFilters(infos);
    }

    async function trySetEmbed(report: PbiReport) {
        if (embed.current === false) {
            embed.current = report;
            let reportFilters = await embed.current.getFilters();
            let locked = [...reportFilters.filter(p => p.displaySettings?.isLockedInViewMode)];
            setLockedFilters(locked);
            setHasEmbed(true);
        }
    }

    const contextValue: FilterContextValue = {
        activeFilters,
        allFilters,
        removeFilter,
        updateFilter,
        setEmbed: trySetEmbed,
        canRemoveAll: paneFilters.length > 0,
        removeAll,
        registerPreloadedFilters,
        isReady: embed !== null,
        getFilterValue,
        areBasicFiltersVisible,
        areAdvancedFiltersVisible,
        toggleBasicFilters,
        toggleAdvancedFilters,
        geoMaster
    };

    if (isInitialRenderComplete) {
        return <FilterContext.Provider value={contextValue}>{props.children}</FilterContext.Provider>
    }

    return <><span>Loading</span></>
}
