import React from 'react';
import AdminPageFraming from './AdminPageFraming';
import FullSizeCentered from '../FullSizeCentered';

export default function Unauthorized() {
    return (
        <AdminPageFraming>
            <FullSizeCentered>
                <h3 className="text-white">You are not authorized to view this page.</h3>
            </FullSizeCentered>
        </AdminPageFraming>
    );
}