import React, { useState, useEffect } from 'react';
import { SelectionItem, ProFilterInfo, RadioButtonInputProps } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
import { useDebouncedValue } from '../hooks/useDebouncedValue';

function PbiRadioButtonFilterInput({
    id,
    group,
    table,
    column,
    displayName,
    className,
    defaultValue,
    values,
    disabled,
    sortIndex
}: RadioButtonInputProps) {

    const { updateFilter, getFilterValue } = useFilters();
    const { createBasicFilter } = useFilterFactory();

    const [selected, setSelected] = useState<SelectionItem>(() => {
        let val = values.find(di => di.value === defaultValue);
        return val!;
    });

    //const [selectedValue, setSelectedValue] = useState<string>();

    const debouncedSelection = useDebouncedValue(selected, 500);

    useEffect(() => {

        // commented out by Richard 
        //var defaultValueProxy = getFilterValue(id);

        //if (defaultValueProxy === null || defaultValueProxy === undefined) {
        //    defaultValueProxy = defaultValue;
        //}
        //else {
        //    defaultValueProxy = defaultValueProxy.value;
        //}

        //if (defaultValueProxy) {
        //    let newValue = values.find(di => di.value === defaultValueProxy)!;
        //    if (newValue) {
        //        setSelected(newValue);
        //    }
        //    else {
        //        let newValue = values.find(di => di.value === defaultValue)!;
        //        setSelected(newValue);
        //    }
        //}
        //else {
            let newValue = values.find(di => di.value === defaultValue)!;
            setSelected(newValue);
        //}
    }, [])

    useEffect(() => {
        if (selected === undefined || selected === null) {
            let newValue = values.find(di => di.value === defaultValue)!;
            setSelected(newValue);
        }

        let value = selected.value;

        let reportFilter = createBasicFilter({ table, column, value })

        let filter: ProFilterInfo = {
            id,
            group,
            reportFilter,
            displayTextHeader: `${displayName}`,
            displayText: `${selected.value}`,
            reset,
            isReadonly: false,
            isPreload: selected.value === defaultValue,
            rawValue: { value },
            sortIndex: sortIndex
        };

        updateFilter(filter);

    }, [debouncedSelection]);



    function reset() {
        let newValue = values.find(di => di.value === defaultValue)!;
        setSelected(newValue);
    }

    //was
    //key={`rb_${ix}`}

    function RenderInput(item: SelectionItem, ix: number) {
        return (
            <>
                <input
                    className="force-inline2"
                    key={item.key}
                    disabled={disabled}
                    type="radio"
                    value={item.value}
                    checked={item.value === selected.value}
                    onChange={handleChange}
                    name={id} />{" "}<span>{item.label}</span>
                <br />
            </>
        );
    }

    function RenderKey(item: SelectionItem, ix: number) {
        return {ix}
    }

    function handleChange(evt: any) {
        let val = evt.target.value;
        let selection: SelectionItem = values.find(v => v.value == val)!;
        setSelected(selection);
    }

    return (
        <div className={className ?? ""}>
            <div className="force-inline">
                {values.map(RenderInput)}
            </div>
        </div>
    );



}


export default PbiRadioButtonFilterInput;
