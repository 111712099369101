import React, { useState, useEffect } from 'react';
import AdminPageFraming from './AdminPageFraming';
import FullSizeCentered from '../FullSizeCentered';
import { useNavigate, Link } from 'react-router-dom';
import { useAxios } from 'use-axios-client';
import { CButton, CBadge, CListGroup, CListGroupItem } from '@coreui/react'
import { useAdminPage } from '../hooks/useAdminPage';


interface Batch {
    id: number;
    total: number;
    registered: number;
}


function Batches() {
    const { isAuthPending } = useAdminPage();
    const { data, loading, error } = useAxios<Batch[]>('/api/admin/batches');
    const [batches, setBatches] = useState<Batch[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            let batches = [...data].sort((a, b) => a.id - b.id);
            setBatches(batches);
        }

    }, [data]);


    function RenderBatch(batch: Batch) {
        const downloadLink = `/api/admin/downloadBatch?id=${batch.id}`;
        const navLink = `/admin/batch/${batch.id}`;
        const batchName = `Batch ${batch.id}`;
        return (
            <CListGroupItem key={batch.id} className="d-flex justify-content-between align-items-center">
                <a href={downloadLink} download>
                    <i className="fa fa-download text-primary" title="Download Batch" />
                </a>
                <span className="cursor-pointer" onClick={() => { navigate(navLink) }}><strong>{batchName}</strong></span>
                <CBadge
                    color="primary"
                    shape="pill"
                    style={{ width: '25%' }}
                >
                    {`${batch.registered} of ${batch.total}`}
                </CBadge>
            </CListGroupItem>
        );
    }

    if (loading || isAuthPending) {
        return (
            <AdminPageFraming>
                <FullSizeCentered>
                    <h1 className="text-white">Loading Batches ...</h1>
                </FullSizeCentered>
            </AdminPageFraming>
        );
    }

    if (error) {
        return (
            <AdminPageFraming>
                <FullSizeCentered>
                    <h1 className="text-danger">Error loading batches</h1>
                </FullSizeCentered>
            </AdminPageFraming>
        )
    }

    return (
        <AdminPageFraming>
            <div className="container p-5">
                <div className="row">
                    <div className="col col-10 offset-1">
                        <CButton size="sm" color="primary" onClick={() => navigate('/admin/addContact')}>Add Contact</CButton>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col col-10 offset-1">
                        <CListGroup>
                            {batches.map(RenderBatch)}
                        </CListGroup>
                    </div>
                </div>
            </div>
        </AdminPageFraming>
    );
}

export default Batches;