import React, { useState, useEffect } from 'react';

import logoWhite from './assets/img/mpact_rate_white.png';
//import logoWhite from './assets/img/MPACT_Logo_white_sm.png';
import upgradeLogo from './assets/img/mpact_ratepro_black.png';

import { COffcanvas, COffcanvasHeader, COffcanvasBody, CListGroup, CListGroupItem } from '@coreui/react'
import { Page } from './models/page';
import { Report } from './models/report';

import { useNavigate } from 'react-router-dom';

interface HeaderProps {
    title: string;
    onPageClick: (page: Page) => void;
    userReport: Report;
    hideMenuButton: boolean;
    showProUpgrade: boolean;
};


function Header({ title, userReport, onPageClick, hideMenuButton, showProUpgrade }: HeaderProps) {
    const [pages, setPages] = useState<Page[]>([])
    const [showSideNav, setShowSideNav] = useState<boolean>(true);
    const [sideNavFirstTime, setSideNavFirstTime] = useState<boolean>(true);
    const navigate = useNavigate();
    const [hamburgerColor, setHamburgerColor] = useState<string>("white");
    const [hamburgerSize, setHamburgerSize] = useState<number>(24);
    const [fauxTitle, setFauxTitle] = useState<string>("<==  Activate Menu Here");
    const [blinking, setBlinking] = useState<boolean>(false);


    useEffect(() => {
        let pages = [...userReport.pages];
        pages.sort((a, b) => a.ordinal - b.ordinal);
        setShowSideNav(true);
        setPages(pages);
    }, [userReport])

    useEffect(() => {
        if (!showSideNav && sideNavFirstTime) {
            waitOnBlink();
            setSideNavFirstTime(false);
            setFauxTitle(title);
        }
        else {
            setHamburgerColor("white");
            setHamburgerSize(36);
        }
    }, [showSideNav])

    useEffect(() => {
        setFauxTitle(title);
    }, [title])

    useEffect(() => {
        if (!blinking) {
            setHamburgerColor("white");
            setHamburgerSize(36);
        }
    }, [blinking, fauxTitle])

    async function blinkOnExit() {
        setBlinking(true);
        restoreTitle();
        var i = 16;
        var func = () => {
            if (i == 0) {
                clearInterval(intervalId);
                setFauxTitle(title);
                setHamburgerColor("white");
                setHamburgerSize(36);
            }
            else {
                setFauxTitle("<==  Activate Menu Here");
            }

            if (isOdd(i)) {
                setHamburgerColor("white");
                setHamburgerSize(36);
            }
            else {
                setHamburgerColor("red");
                setHamburgerSize(48);
            }

            i--;
        };
        let intervalId = setInterval(func, 500);
        func();
        setBlinking(false);
    }

    function restoreTitle() {
        var func = () => {
            setHamburgerColor("white");
            setHamburgerSize(36);
        };
        let intervalId = setInterval(func, 500);
        func();
    }

    async function waitOnBlink() {
        await blinkOnExit();
    }

    function isOdd(num) {
        return num % 2;
    }

    function toggleSideNav() {
        setShowSideNav(sn => !sn);
    }


    function MenuButton() {
        if (hideMenuButton)
            return null;

        return (
            <>
                <button className="btn btn-sm button-fixed" onClick={toggleSideNav} aria-label="Open reports menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width={hamburgerSize} height={hamburgerSize} fill={hamburgerColor} viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>

            </>
        );
    }

    function MenuTitle() {
        if (hideMenuButton)
            return null;

        return <span className="text-white title-pos">{fauxTitle}</span>
    }

    function CloseSideNavButton() {
        return (
            <>
                <button className="btn btn-sm button-border-none" onClick={toggleSideNav} aria-label="Close reports menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
            </>
        );
    }



    function PageListItem(page: Page, onClick: (page: Page) => void) {
        const nav = () => {
            setShowSideNav(false);
            if (onClick) {
                onClick(page);
            }
        };

        return (
            <CListGroupItem
                key={page.uri}
                className="mt-2 mb-2 report-card"
                onClick={nav}
                active={page.displayName === title}
            >
                {page.displayName}
            </CListGroupItem>
        )
    }



    return (
        <>
            <header className="app-header">
                <nav>
                    <MenuButton />
                    <MenuTitle />
                </nav>
                <img className="header-logo" src={logoWhite} alt="MPact Logo" height="24" />
            </header>

            <COffcanvas placement="start" visible={showSideNav} className="report-side-nav">
                <COffcanvasHeader>
                    <img src={logoWhite} alt="MPact Logo" height="24" className="mr-5" />
                    <CloseSideNavButton />
                </COffcanvasHeader>
                <COffcanvasBody>
                    <CListGroup>
                        {pages.map(p => {
                            return PageListItem(p, onPageClick);
                        })}


                        {showProUpgrade &&
                            <CListGroupItem className="mt-3 mb-2 report-card upgrade-card" onClick={() => { navigate('/upgrade') }} >
                                <img src={upgradeLogo} title="Upgrade Today!" className='pro-brand' />
                                <span className="d-block">Upgrade Today!</span>
                            </CListGroupItem>
                        }


                    </CListGroup>
                </COffcanvasBody>
            </COffcanvas>
        </>
    );
}

export default Header;
