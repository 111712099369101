import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useLazyAxios } from 'use-axios-client';

interface UserAction {
    action: string;
    timeUtc: Date;
    details?: string;
    userId: string;
}

interface ActionLog {
    sessionId: string;
    frameTimeUtc: Date;
    userActions: UserAction[];
}

interface TelemetryContextValue {
    logAction(action: string, details?: any): void;
    setSession(sessionId: string): void;
    setUser(userId: string): void;
}

const telemetryContextDefault: TelemetryContextValue = {
    logAction(action: string, details?: any) { },
    setSession(sessionId: string) { },
    setUser(userId: string) { }
}

export const TelemetryContext = React.createContext<TelemetryContextValue>(telemetryContextDefault);

export function useTelemetry() {
    const context = useContext(TelemetryContext);
    return context;
}


const telemetryInterval = 1000 * 60 * 2;

export function TelemetryProvider(props: any) {
    const sessionId = useRef<any>("");
    const userId = useRef<any>("");

    const [actions, setActions] = useState<UserAction[]>([]);
    const [sendToServer, { error }] = useLazyAxios<void>('/api/telemetry/log', { method: 'POST' });

    const timerCallback = useRef(reportTelemetry);

    function reportTelemetry() {
        if (actions.length === 0) {
            return;
        }

        let reports = [...actions];
        setActions([]);

        let telemetryLog: ActionLog = {
            sessionId: sessionId.current,
            frameTimeUtc: new Date(),
            userActions: reports
        };

        sendToServer(telemetryLog);
    }

    useEffect(() => { timerCallback.current = reportTelemetry; });

    useEffect(() => {
        function tick() {
            timerCallback.current();
        }

        const handle = window.setInterval(tick, telemetryInterval);
        window.addEventListener("beforeunload", reportTelemetry);


        return () => {
            window.clearInterval(handle);
            window.removeEventListener("beforeunload", reportTelemetry);
            tick();
        };
    }, []);



    function logAction(action: string, details: any) {
        let deets = details ? JSON.stringify(details) : "";

        let ua: UserAction = {
            timeUtc: new Date(),
            action,
            userId: userId.current,
            details: deets
        };

        setActions(a => {
            let entries = [...a];
            entries.push(ua);
            return entries;
        });
    }

    function setSession(session: string) {
        sessionId.current = session;
    }

    function setUser(userName: string) {
        userId.current = userName;
    }

    const val: TelemetryContextValue = {
        logAction,
        setSession,
        setUser
    };


    return <TelemetryContext.Provider value={val}>{props.children}</TelemetryContext.Provider>
}
