import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { GeographyComplete, SelectionItem } from '../models/filters';

import DatePicker2 from './DatePicker';
import xClear from '../assets/img/xGray.png';
import infoImage from '../assets/img/i.png';

import PbiSelectFilterInput from './PbiSelectFilterInput';
import PbiRadioButtonFilterInput from './PbiRadioButtonFilterInput';
import PbiToggleButtonTwoFilterInput from './PbiToggleButtonTwoFilterInput';
import PbiToggleButtonThreeFilterInput from './PbiToggleButtonThreeFilterInput';
import PbiSliderToggleFilterInput from './PbiSliderToggleFilterInput';
import PbiCheckboxFilterInput from './PbiCheckboxFilterInput';

import { useSharedScacCentrics } from '../hooks/useScacCentricSource';
import { useSharedAll } from '../hooks/useSharedAll';
import { useSharedHauls } from '../hooks/useSharedHaulFilter';
import { useFilters } from './FilterContext';

import { CContainer, CRow, CCol } from "@coreui/react";
import { MDBDropdown, MDBDropdownToggle } from "mdbreact";

import './FilterPane.css';

function FilterPane(props) {
    const sliderFlat = useRef(null);
    const sliderRPM = useRef(null);

    const {
        removeAll,
        isReady,
        areBasicFiltersVisible,
        areAdvancedFiltersVisible,
        toggleBasicFilters,
        toggleAdvancedFilters,
        activeFilters
    } = useFilters();

    const hauls = useSharedHauls();
    const sourceSharedAll = useSharedAll(props.geoMaster);
    const destinationSharedAll = useSharedAll(props.geoMaster);
    const scacCentrics = useSharedScacCentrics();
    const startDate = createDate(90);
    const endDate = createDate(0);

    const [showInformation, setShowInformation] = useState<boolean>(false);
    const [toggleSlider, setToggleSlider] = useState<string>("Flat");
    const info = "The origin and destination dropdown boxes contain geography elements for Zone, ZIP3 and KMA.   You can start typing, which will constrict the possible selections.   Once you choose a Zone, ZIP3 or KMA, the selections will be constricted for only that type.   If you are not satisfied with your selection, press the X and it will clear the component.";
    const [dataRange, setDateRange] = useState<any>({ startDate: startDate, endDate: endDate })

    const [isInitialRenderComplete, setIsInitialRenderComplete] = useState<boolean>(false);

    useEffect(() => {
        console.log("Filter pane initial render done");
        setIsInitialRenderComplete(true);

        return () => {
            console.log("filter pane destroyed");
        };
    }, [])

    const carrierTypes: SelectionItem[] = [
        { label: "Asset", value: "Asset" },
        { label: "Broker", value: "Broker" },
        { label: "Both", value: "Both" },
    ];

    const trailerTypes: SelectionItem[] = [
        { label: "Flat", value: "Flat" },
        { label: "Reefer", value: "Reefer" },
        { label: "Van", value: "Van" },
    ];


    const marketPositions: SelectionItem[] = [
        { label: "Buy", value: "Buy" },
        { label: "Sell", value: "Sell" },
    ];

    const mileageGroup: SelectionItem[] = [
        { label: "<=250", value: "<=250" },
        { label: ">250", value: ">250" },
    ];

    const rateBases: SelectionItem[] = [
        { label: "Flat", value: "Flat" },
        { label: "RPM", value: "RPM" },
    ];


    const rateComparisons: SelectionItem[] = [
        { label: "Average", value: "Average", key: 1 },
        { label: "Median", value: "Median", key: 2 },
    ];


    const fuelOptions: SelectionItem[] = [
        { label: "With Fuel", value: "With Fuel" },
        { label: "Without Fuel", value: "Without Fuel" },
    ];


    const marketDataOptions: SelectionItem[] = [
        { label: "With Me", value: "With Me" },
        { label: "Without Me", value: "Without Me" },
    ];

    const transactionTypes: SelectionItem[] = [
        { label: "Spot", value: "Spot" },
        { label: "Contract", value: "Contract" },
    ];

    function createDate(offset: number) {
        let date = new Date();

        date.setDate(date.getDate() - offset);

        date.setHours(0, 0, 0, 0);

        return date;
    }

    const handleChange = (value: any) => {
        setDateRange(value)
    }

    function reset() {
        removeAll();
    }

    useEffect(() => {
        const contentFlat: any = sliderFlat.current;
        const contentRpm: any = sliderRPM.current;

        if (!contentFlat || !contentRpm) {
            return;
        }

        console.log("toggleSlider");
        console.log(toggleSlider);
        
        if (toggleSlider === "Flat") {
            contentFlat.style.fontWeight = 600;
            contentRpm.style.fontWeight = 200;
        }
        else {
            contentFlat.style.fontWeight = 200;
            contentRpm.style.fontWeight = 600;
        }
    }, [toggleSlider])

    const slideToggle = useCallback((val) => {
        setToggleSlider(val);
    }, []);

    const filterPaneCssClass = useMemo(() => {
        let baseClass = "filter-pane";
        baseClass += !areBasicFiltersVisible ? " hidden" : "";
        return baseClass;
    }, [areBasicFiltersVisible]);

    const expandedPanelCssClass = useMemo(() => {
        let baseClass = "expanded-panel";
        baseClass += !areAdvancedFiltersVisible ? " hidden" : "";
        return baseClass;
    }, [areAdvancedFiltersVisible]);

    //useEffect(() => {
    //    console.log("sourceSharedAll.zonesZipsKmas.length.toString()");
    //    console.log(sourceSharedAll.zonesZipsKmas.length.toString());
    //}, [sourceSharedAll]);
    

    if (!isInitialRenderComplete) {
        return (<div className={filterPaneCssClass}></div>)
    }

    return (
        <div className={filterPaneCssClass}>
            <div className="filters text-black"  >
                <div className="p-2 mr-2">

                    <CContainer className="container-fp">
                        <CRow>
                            <CCol>
                                <h5 className="filter-header"><strong>Report Filters</strong></h5>
                            </CCol>

                            <div className="fp-buttons">
                                <button className="fp-button-cancel" type="button" onClick={reset}>{"Clear"}</button>
                                <button className="fp-button-apply" type="button" onClick={_ => toggleBasicFilters()}>Apply</button>
                                <img className="fp-x" src={xClear} onClick={_ => toggleBasicFilters()} alt="close filter pane" />
                            </div>

                        </CRow>

                        <div className="date-range-picker">
                            <DatePicker2
                                onChange={handleChange}
                                value={dataRange}
                                startDateLabelText="From Ship Date"
                                endDateLabelText="To Ship Date"
                                startDateDefault={dataRange?.startDate}
                                endDateDefault={dataRange?.endDate}
                                id="shipdate_range"
                                group="shipdate"
                                table="Dates"
                                column="Date"
                                displayName="Ship Date"
                                sortIndex={3}
                            />
                        </div>


                        <div className="fp-info">
                            <img
                                className="fp-i"
                                src={infoImage}
                                alt="Information"
                                onMouseOver={() => setShowInformation(true)}
                                onMouseLeave={() => setShowInformation(false)}
                            />
                            {showInformation && (<div className="showInformation"> <span>{info}</span> </div>)}
                        </div>
                        <div className="mt-1 fp-row-1">
                            <div className="dropdown-spacer-1a">
                                <PbiSelectFilterInput
                                    id="origin_zone"
                                    group="zoneZipKma"
                                    table="Origin"
                                    column="Orig Zone"
                                    displayName="Origin"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    values={sourceSharedAll.zonesZipsKmas}
                                    onSetFilterItems={sourceSharedAll.setFilterValues}
                                    passRawValuesToFilterFn={true}
                                    disabled={!isReady}
                                    sortIndex={1}
                                />
                            </div>

                            <div className="dropdown-spacer-1b">
                                <PbiSelectFilterInput
                                    id="destination_zone"
                                    group="zoneZipKma"
                                    table="Destination"
                                    column="Dest Zone"
                                    displayName="Destination"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    values={destinationSharedAll.zonesZipsKmas}
                                    onSetFilterItems={destinationSharedAll.setFilterValues}
                                    passRawValuesToFilterFn={true}
                                    disabled={!isReady}
                                    sortIndex={2}
                                />
                            </div>
                            <div className="dropdown-spacer-1c">
                                <PbiSelectFilterInput
                                    id="trailer_type"
                                    group=""
                                    table="Exchange Type"
                                    column="Exchange Type Name"
                                    displayName="Trailer Type"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    values={trailerTypes}
                                    isMultiSelect={true}
                                    defaultValue="Van"
                                    disabled={!isReady}
                                    sortIndex={4}
                                />
                            </div>
                        </div>
                        <CRow className="fp-row-2">
                            <CCol className="">
                                <PbiToggleButtonTwoFilterInput
                                    id="market_position"
                                    group=""
                                    table="Market Position"
                                    column="Market Position Filter"
                                    displayName="Market Position"
                                    operator="Is"
                                    className="mt-1"
                                    defaultValue="Sell"
                                    values={marketPositions}
                                    disabled={!isReady}
                                    sortIndex={5}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="fp-row-3">
                                <PbiToggleButtonThreeFilterInput
                                    id="carrier_type"
                                    defaultValue="Both"
                                    group=""
                                    table="Carrier Type"
                                    column="Carrier Type Name"
                                    displayName="Carrier Type"
                                    operator="In"
                                    className="mt-1"
                                    values={carrierTypes}
                                    disabled={!isReady}
                                    sortIndex={6}
                                />

                            </CCol>
                            <CCol className="fp-row-4">
                                <div className="vertical-line"></div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="fp-row-5">
                                <PbiCheckboxFilterInput
                                    id="fuel"
                                    group=""
                                    table="Fuel"
                                    column="Fuel Filter"
                                    displayName="Include Fuel"
                                    operator="Is"
                                    className="mt-1"
                                    values={fuelOptions}
                                    defaultValue="With Fuel"
                                    disabled={!isReady}
                                    sortIndex={7}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="fp-row-6">
                                <PbiCheckboxFilterInput
                                    id="market_data"
                                    group=""
                                    table="SCAC Inclusion"
                                    column="SCAC Inclusion Filter"
                                    displayName="Include My Data"
                                    operator="Is"
                                    className="mt-1"
                                    values={marketDataOptions}
                                    defaultValue="With Me"
                                    disabled={!isReady}
                                    sortIndex={8}
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol className="fp-row-6b">
                                <div className="vertical-line3"></div>
                            </CCol>

                            <CCol className="fp-row-7">
                                <div>
                                    <label ref={sliderFlat} className="toggle-flat">Flat</label>
                                </div>

                                <PbiSliderToggleFilterInput
                                    id="rate_basis"
                                    group=""
                                    table="Rate Basis"
                                    column="Rate Basis Filter"
                                    displayName="Rate Basis Filter"
                                    operator="Is"
                                    className=""
                                    defaultValue="Flat"
                                    values={rateBases}
                                    disabled={!isReady}
                                    slideToggle={slideToggle}
                                    toggleSlider={toggleSlider}
                                    sortIndex={9}
                                />
                                <div>
                                    <label ref={sliderRPM} className="toggle-rpm">RPM</label>
                                </div>
                            </CCol>
                            <CCol className="fp-row-8">
                                <MDBDropdown>
                                    <MDBDropdownToggle className="expanded-dropdown" nav caret onClick={_ => toggleAdvancedFilters()}>
                                        <span>Advanced Filters</span>
                                    </MDBDropdownToggle>
                                </MDBDropdown>
                            </CCol>
                        </CRow>
                    </CContainer>

                    <div className={expandedPanelCssClass}>
                        <div className="mt-1 fp-row-9">
                            <div className="dropdown-spacer-2a">
                                <PbiSelectFilterInput
                                    id="origin_domain"
                                    group="domains"
                                    table="Origin Domain"
                                    column="Orig Dom Sub"
                                    displayName="Origin Domain"
                                    operator="In"
                                    className="top-row-boxes2"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={false}
                                    isSearchable={true}
                                    values={sourceSharedAll.domains}
                                    onSetFilterItems={sourceSharedAll.setFilterValues}
                                    passRawValuesToFilterFn={true}
                                    disabled={!isReady}
                                    sortIndex={10}
                                />
                            </div>

                            <div className="dropdown-spacer-2b">
                                <PbiSelectFilterInput
                                    id="destination_domain"
                                    group="domains"
                                    table="Destination Domain"
                                    column="Dest Dom Sub"
                                    displayName="Destination Domain"
                                    operator="In"
                                    className="top-row-boxes2"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={false}
                                    isSearchable={true}
                                    values={destinationSharedAll.domains}
                                    onSetFilterItems={destinationSharedAll.setFilterValues}
                                    passRawValuesToFilterFn={true}
                                    disabled={!isReady}
                                    sortIndex={11}
                                />
                            </div>
                            <div className="dropdown-spacer-3a">
                                <PbiSelectFilterInput
                                    id="loh_group"
                                    group="loh"
                                    table="LOH"
                                    column="LOH Name"
                                    displayName="Length of Haul Group"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    isSearchable={false}
                                    values={hauls.haulLengths}
                                    onSetFilterItems={hauls.setFilterValues}
                                    disabled={!isReady}
                                    sortIndex={12}
                                />
                            </div>

                            <div className="dropdown-spacer-3b">
                                <PbiSelectFilterInput
                                    id="cust_group"
                                    group="cust"
                                    table="Customers"
                                    column="Customer"
                                    displayName="Customer"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    isSearchable={true}
                                    values={scacCentrics.customers}
                                    onSetFilterItems={scacCentrics.setFilterValues}
                                    disabled={!isReady}
                                    sortIndex={13}
                                />
                            </div>
                            <div className="dropdown-spacer-3c">
                                <PbiSelectFilterInput
                                    id="op_user_group"
                                    group="user"
                                    table="Operations Users"
                                    column="Operations User"
                                    displayName="Operations User"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    isSearchable={false}
                                    values={scacCentrics.operationsUsers}
                                    onSetFilterItems={scacCentrics.setFilterValues}
                                    disabled={!isReady}
                                    sortIndex={14}
                                />
                            </div>
                            <div className="dropdown-spacer-3d">
                                <PbiSelectFilterInput
                                    id="disp_group"
                                    group="dispatch"
                                    table="Dispatch Users"
                                    column="Dispatcher"
                                    displayName="Dispatcher"
                                    operator="In"
                                    className="top-row-boxes"
                                    noSelectionPlaceholder="All"
                                    isMultiSelect={true}
                                    isSearchable={false}
                                    values={scacCentrics.dispatchers}
                                    onSetFilterItems={scacCentrics.setFilterValues}
                                    disabled={!isReady}
                                    sortIndex={15}
                                />
                            </div>

                        </div>

                        <div className="mt-1 fp-row-10">
                            <PbiToggleButtonThreeFilterInput
                                id="transaction_type"
                                group="transType"
                                table="Transaction Type"
                                column="Transaction Type Name"
                                displayName="Transaction Type"
                                operator="In"
                                className="mt-1"
                                values={transactionTypes}
                                defaultValue="Both"
                                disabled={!isReady}
                                sortIndex={16}
                            />
                        </div>
                        <div className="mt-1 fp-row-11">
                            <PbiToggleButtonThreeFilterInput
                                id="mileage_group"
                                group="mileage"
                                table="LOH"
                                column="LOH Mileage Group"
                                displayName="Mileage Group"
                                operator="In"
                                className="mt-1"
                                values={mileageGroup}
                                defaultValue=">250"
                                disabled={!isReady}
                                sortIndex={17}
                            />
                        </div>

                        <div className="fp-row-12">
                            <div className="vertical-line2"></div>
                        </div>

                        <div className="mt-1 fp-row-13">
                            <label className="radio-label">Compare Rate To</label>
                            <PbiRadioButtonFilterInput
                                id="rate_comparison"
                                group=""
                                table="Compare Rate"
                                column="Compare Rate Switch"
                                displayName="Compare Rate To"
                                operator="Is"
                                className="radio-boxes"
                                defaultValue="Average"
                                values={rateComparisons}
                                disabled={!isReady}
                                sortIndex={18}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div >
    );
}


export default FilterPane;