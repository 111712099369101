import { useEffect, useState } from 'react';
import FullSizeCentered from './FullSizeCentered';
import { GoogleLogin } from '@react-oauth/google';
import * as msal from '@azure/msal-browser';
import { CCard, CCardBody } from '@coreui/react'
import mcLeodLogoBlack from './assets/img/McLeodLogo_black.png';
import mpactLogoWhite from './assets/img/mpact_rate_white.png';
import { useLazyAxios } from 'use-axios-client';
import { useQueryString } from './hooks/useQueryString';
import MicrosoftSignInButton from './MicrosoftSignInButton';
import ExternalAuth from './models/external-auth';
import LoginStatus from './models/login-status';


function ensureValidReturnUrl(query: string | null): string {
    let protocol = "https://";
    let host = window.location.host;
    let base = protocol + host;

    if (!query) {
        return base;
    }

    try {
        const url = new URL(query);
        return base;
    } catch (err) {
        const returnUrl = query.replace(/[^a-zA-Z0-9-]/g, '');
        return `${base}/${returnUrl}`;
    }
}

//https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
const msalConfig: msal.Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID!,
        redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI_LOGIN!
    }
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

function LoginIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
    );
}

function Login() {
    const [login, { error: loginError, data: loginResult, loading: isAwaitingLoginResult }] = useLazyAxios<LoginStatus>('api/authentication/login', {method:'post'});
    const [authError, setAuthError] = useState<string>("");
    const [returnUrl, setReturnUrl] = useState<string>("/");
    const query = useQueryString();

    useEffect(() => {
        let returnUrl = ensureValidReturnUrl(query.get("sender"))
        setReturnUrl(returnUrl);

        msalInstance.handleRedirectPromise()
            .then((tokenResponse) => {
                if (tokenResponse) {
                    const loginDetails: ExternalAuth = {
                        identityToken: tokenResponse.idToken,
                        uniqueId: tokenResponse.uniqueId,
                        provider: "Microsoft"
                    };
                    login(loginDetails);
                }
            })
            .catch(() => {
                setAuthError("Could not sign in with Microsoft.");
            });
    }, []);

    useEffect(() => {
        if (!loginResult)
            return;

        if (loginResult.isLoggedIn) {
            returnToApplication();
        }
        else {
            setAuthError(`${loginResult.provider}: ${loginResult.message}`);
        }

        return () => { setAuthError(""); }
    }, [loginResult]);

    useEffect(() => {
        setAuthError(loginError?.message || "")
    }, [loginError])

    async function onMsButtonClick() {
        var redirectRequest: msal.RedirectRequest = {
            scopes: ['openid', 'profile'],
            prompt: "select_account",
        };

        await msalInstance.loginRedirect(redirectRequest);
    }

    async function onGoogleAuthSuccess(googleResponse: any) {
        let loginDetails: ExternalAuth = {
            identityToken: googleResponse.credential,
            uniqueId: "",
            provider: "Google"
        };
        login(loginDetails);
    }

    function onGoogleAuthFail() {
        setAuthError("Can not sign in with Google.");
    }

    function returnToApplication() {
        window.location.replace(returnUrl);
    }

    return (
        <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-login">
            <img src={mpactLogoWhite} height="30" className="pos-absolute left-0 top-0 m-3" alt="MPact Logo" />

            <FullSizeCentered>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12 mx-auto">

                            <CCard>
                                <CCardBody>
                                    <div className="form-header">
                                        <h3 className="font-weight-500 my-2 py-1"><LoginIcon /> Log in</h3>
                                    </div>
                                    <div className="container text-danger font-weight-bold mb-2">
                                        {authError}
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col">
                                                <MicrosoftSignInButton
                                                    disabled={isAwaitingLoginResult}
                                                    onClick={onMsButtonClick} />
                                            </div>
                                            <div className="col">
                                                <GoogleLogin onSuccess={onGoogleAuthSuccess} onError={onGoogleAuthFail} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mr-3 mt-2 d-flex justify-content-end">
                                        <a href="https://www.mcleodsoftware.com" target="_blank" rel="noreferrer">
                                            <img src={mcLeodLogoBlack} height="70" alt="McLeod Logo" />
                                        </a>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </div>
                    </div>
                </div>
            </FullSizeCentered>
        </div>
    );
}

export default Login;