import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import PageNotFound from "./PageNotFound";
import Register from "./Register";
import Batches from "./admin/Batches";
import Contacts from "./admin/Contacts";
import AddContact from "./admin/AddContact";
import Unauthorized from "./admin/Unauthorized";
import Login from './Login';
import ReportSelector from './ReportSelector';
import Upgrade from './Upgrade';
/*import RatePredictor from './pro/RatePredictor';*/


function App() {
    //const [isRatePred, setIsRatePred] = useState(false);

    return (
        <Routes>
            <Route path="/" element={<ReportSelector/>} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/upgrade" element={<Upgrade/>}/>
            <Route path="/:pageUri" element={<ReportSelector/>} />
            {/*{isRatePred && (<Route path="/ratepredictor" element={<RatePredictor isItExpanded={true} setIsRatePred={setIsRatePred} />} />)}*/}
            <Route path="/admin/" element={<Batches />} />
            <Route path="/admin/batch/:id" element={<Contacts />} />
            <Route path="/admin/AddContact" element={<AddContact />} />

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default App;
