import React from 'react';
import FullSizeCentered from './FullSizeCentered';
import loadingImage from './assets/img/MPACT_Logo_white.png';
import { Link } from 'react-router-dom';

function PageNotFound() {
    return (
        <>
            <div className="pos-absolute left-0 right-0 bottom-0 top-0 bg bg-report">
                <FullSizeCentered className="text-white">
                    <div>
                        <h1 className="text-center mb-5">Page Not Found</h1>
                        <h5>The page you are attempting to access does not exist.  Please click the MPact logo to go to the home page.</h5>
                    </div>
                </FullSizeCentered>
            </div>

            <Link to="/">
                <img src={loadingImage} className="pos-absolute left-0 top-0 mt-2 ml-2" height="20" />
            </Link>
        </>
    );



}

export default PageNotFound;