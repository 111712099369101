import React, { useState, useEffect } from 'react';
import { useAxios } from 'use-axios-client';
import { Geography, SelectionItem, MarketZipUnion } from '../models/filters';


interface MarketPlusZipProvider {
    marketsAndZip: SelectionItem[];
}

function unique(value, index, self) {
    return self.indexOf(value) === index;
}


export function useMarketPlusZip(): MarketPlusZipProvider {
    const { data} = useAxios<MarketZipUnion[]>('/api/filters/geographiesUnion');
    const [marketZips, setMarketZips] = useState<MarketZipUnion[]>([]);

    const [marketsAndZip, setMarketsAndZip] = useState<SelectionItem[]>([]);

    useEffect(() => {
        if (!data) {
            return;
        }
        setMarketZips(data);
    }, [data]);


    useEffect(() => {
        if (!marketZips) {
            return;
        }

        let marketsAndZip = toSelectionList(marketZips, "marketsAndZip", true);
        
        setMarketsAndZip(marketsAndZip);
    }, [marketZips])


    
    function toSelectionList(filtered: MarketZipUnion[], key: string, alphaSort: boolean = false): SelectionItem[] {
        let selection = filtered.map(f => f[key])
            .filter(unique)
            .map(i => { return { label: i, value: i } });

        if (alphaSort) {
            selection.sort((a, b) => { return a.label.localeCompare(b.label); })
        }

        return selection;
    }

    return {
        marketsAndZip
    };
}
