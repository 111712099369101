import React, { useState, useEffect } from 'react';
import { useAxios, useLazyAxios } from 'use-axios-client';
import { ScacCentric, SelectionItem } from '../models/filters';


interface SharedScacCentricProvider {
    customers: SelectionItem[];
    operationsUsers: SelectionItem[];
    dispatchers: SelectionItem[];
    setFilterValues: (sourceName: string, values: string[] | null) => void;
}

function unique(value, index, self) {
    return self.indexOf(value) === index;
}

const baseUri = "https://" + window.location.host + "/";

export function useSharedScacCentrics(): SharedScacCentricProvider {
    const { data } = useAxios<ScacCentric[]>('/api/filters/scacCentricCall');
    //const [scacCentricCall, { data: scacCentricResult, loading: isWaitingForScacCentricResponse }] = useLazyAxios<any>(baseUri + '/api/filters/scacCentrics', { method: 'POST' });

    const [scacCentrics, setScacCentrics] = useState<ScacCentric[]>([]);

    const [customers, setCustomers] = useState<SelectionItem[]>([]);
    const [operationsUsers, setOperationsUsers] = useState<SelectionItem[]>([]);
    const [dispatchers, setDispatchers] = useState<SelectionItem[]>([]);

    const [customerFilter, setCustomerFilter] = useState<string[]>([]);
    const [operationsUserFilter, setOperationsUserFilter] = useState<string[]>([]);
    const [dispatcherFilter, setDispatcherFilter] = useState<string[]>([]);

    

    //useEffect(() => {
    //    scacCentricCall(scac);
    //}, []);

    useEffect(() => {
        if (!data) {
            return;
        }
        setScacCentrics(data);
    }, [data]);


    useEffect(() => {
        if (!scacCentrics) {
            return;
        }

        let customers = toSelectionList(scacCentrics, "customer",true);
        let operationsUsers = toSelectionList(scacCentrics, "operationsUser", true);
        let dispatchers = toSelectionList(scacCentrics, "dispatcher", true);

        setCustomers(customers);
        setOperationsUsers(operationsUsers);
        setDispatchers(dispatchers);
    }, [scacCentrics])


    useEffect(() => {
        if (!scacCentrics) {
            return;
        }

        let filtered = filterScacCentrics();
        let operationsUsers = toSelectionList(filtered, "operationsUser", true);
        let dispatchers = toSelectionList(filtered, "dispatcher", true);
        
        setOperationsUsers(operationsUsers);
        setDispatchers(dispatchers);
        
        if (customerFilter.length === 0) {
            let customers = toSelectionList(filtered, "customer", true);
            setCustomers(customers);
        }

    }, [customerFilter])


    useEffect(() => {
        if (!scacCentrics) {
            return;
        }

        let filtered = filterScacCentrics();

        let customers = toSelectionList(filtered, "customer", true);
        let dispatchers = toSelectionList(filtered, "dispatcher", true);

        setCustomers(customers);
        setDispatchers(dispatchers);

        if (operationsUserFilter.length === 0) {
            let operationsUsers = toSelectionList(filtered, "operationsUser", true);
            setOperationsUsers(operationsUsers);
        }
    }, [operationsUserFilter])


    useEffect(() => {
        if (!scacCentrics) {
            return;
        }

        let filtered = filterScacCentrics();

        let customers = toSelectionList(filtered, "customer", true);
        let operationsUsers = toSelectionList(filtered, "operationsUser", true);
        
        setCustomers(customers);
        setOperationsUsers(operationsUsers);

        if (dispatcherFilter.length === 0) {
            let dispatchers = toSelectionList(filtered, "dispatcher", true);
            setDispatchers(dispatchers);
        }


    }, [dispatcherFilter])





    function filterScacCentrics(): ScacCentric[] {
        if (!customerFilter.length && !operationsUserFilter.length && !dispatcherFilter.length)
            return scacCentrics;


        return scacCentrics.filter(scac => {
            return (customerFilter.length && customerFilter.some(customer => scac.customer === customer)) ||
                (operationsUserFilter.length && operationsUserFilter.some(operationsUser => scac.operationsUser === operationsUser)) ||
                (dispatcherFilter.length && dispatcherFilter.some(dispatcher => scac.dispatcher === dispatcher));
        });
    }


    function toSelectionList(filtered: ScacCentric[], key: string, alphaSort: boolean = false): SelectionItem[] {
        let selection = filtered.map(f => f[key])
            .filter(unique)
            .map(i => { return { label: i, value: i } });

        if (alphaSort) {
            selection.sort((a, b) => { return a.label.localeCompare(b.label); })
        }

        return selection;
    }


    function setFilterValues(sourceName: string, values: string[] | null) {
        if (values === null) {
            values = [];
        }
        if (sourceName === "cust") {
            setCustomerFilter(values);
        }

        if (sourceName === "user") {
            setOperationsUserFilter(values);
        }

        if (sourceName === "dispatch") {
            setDispatcherFilter(values);
        }
    }


    return {
        customers,
        operationsUsers,
        dispatchers,
        setFilterValues
    };
}
