import { useState } from 'react';

export function usePopoutLink(isPro: boolean = false) {
    const [link] = useState<string>(() => {
        const queryString: any = new URLSearchParams(window.location.search);

        let protocol = "https://";
        let host = window.location.host;
        let path = window.location.pathname;

        path = isPro ? `/trends` : `/dashboard`;

        let link = protocol + host + path;
        let url = new URL(link)

        for (const [key, value] of queryString) {
            if (key === "hf") continue;
            url.searchParams.append(key, value);
        }
        url.searchParams.append("hf", "f");
        console.log(url.href);
        return url.href;
    });


    return {
        link
    };
};