import React, { useState, useEffect, useRef } from 'react';
import './RatePredictor2.css';

import Card14 from './Card14';
import DsDropdown from './DsDropdown';

import { SelectionItem } from '../models/filters';

import { CContainer, CRow, CCol } from "@coreui/react";

import { useSharedGeographies } from '../hooks/useSharedGeographySource';
import { useMarketPlusZip } from '../hooks/useMarketPlusZip';

import { useLazyAxios, useAxios } from 'use-axios-client';

import { CButton } from '@coreui/react';
import logo from '../assets/img/MPACT_Logo_white_sm.png'

import xClearRP from '../assets/img/x.png';
import { type } from 'os';

import { CModal } from '@coreui/react';

const baseUri = "https://" + window.location.host + "/";

function RatePredictor(props) {
	let origRef = useRef("");
	let destRef = useRef("");


	const [isLoading, setIsLoading] = useState('false');
	const [isLoadingNew, setIsLoadingNew] = useState('false');
	//const [isCompleted, setIsCompleted] = useState(false);
	const [isTriggered, setIsTriggered] = useState(false);
	const [isInitial, setIsInitial] = useState(true);
	const [isReset, setIsReset] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [showThree, setShowThree] = useState(false);
	const [calculatedOrigKMA, setCalculatedOrigKMA] = useState("");
	const [calculatedDestKMA, setCalculatedDestKMA] = useState("");
	const [displayOrigKMA, setDisplayOrigKMA] = useState(false);
	const [displayDestKMA, setDisplayDestKMA] = useState(false);
	//const [newOrig, setNewOrig] = useState("");
	//const [newDest, setNewDest] = useState("");
	const [loadingMessage, setLoadingMessage] = useState("");


	const [resultBuy, setResultBuy] = useState("");
	const [resultSell, setResultSell] = useState("");

	let [RatePredictorBuyCall, { data: ratepredResult, loading: isWaitingForRatePredResponse }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/RatePredictorBuyCall', { method: 'POST' });
	let [RatePredictorSellCall, { data: ratepredSellResult, loading: isWaitingForRatePredSellResponse }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/RatePredictorSellCall', { method: 'POST' });

	const [ratepredResultArray, setRatepredResultArray] = useState<string[]>([]);
	const [ratepredSellResultArray, setRatepredSellResultArray] = useState<string[]>([]);
	
	const [payDistanceCall, { data: payDistanceResult, loading: isWaitingForPayDistanceResponse }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/payDistanceCall', { method: 'POST' });

	//const [convertZipToKMAOrig, { data: convertZipToKMAResultOrig, loading: isWaitingForconvertZipToKMAResponseOrig }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/convertZipToKMA', { method: 'POST' });
	//const [convertZipToKMADest, { data: convertZipToKMAResultDest, loading: isWaitingForconvertZipToKMAResponseDest }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/convertZipToKMA', { method: 'POST' });

	//const [getConfidenceIntervalBuy, { data: confidenceResult, loading: confidenceResponse }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/getConfidenceIntervalBuy', { method: 'GET' });
	//const [getConfidenceIntervalSell, { data: confidenceResultSell, loading: confidenceResponseSell }] = useLazyAxios<any>(baseUri + 'api/ratepredictor/getConfidenceIntervalSell', { method: 'GET' });

	const [getMarketIds, { data: dataMarket, loading: loadingMarket, error: errorMarket }] = useLazyAxios<MarketIds[]>(baseUri + 'api/ratepredictor/getMarketIds', { method: 'GET' });

	const [markets, setMarkets] = useState<MarketIds[]>([]);

	const [confidenceFactorsBuy, setConfidenceFactorsBuy] = useState<ConfidenceIntervalCompleteRecord[]>([]);
	const [confidenceFactorsSell, setConfidenceFactorsSell] = useState<ConfidenceIntervalCompleteRecord[]>([]);

	const [dateArray, setDateArray] = useState<string[]>([]);
	const [array21, setArray21] = useState<string[]>([]);

	const sourceMarketZip = useMarketPlusZip();
	//sourceGeographies.marketsAndZip
	//destinationGeographies.zips
	const destinationGeographies = useSharedGeographies();

	interface FormData {
		commodity: string,
		exchangeType: string,
		scac: string,
		payDistance: number,
		originMarketID: string,
		destinationMarketID: string,
		customerType: string,
		date: string,
		confidence: number,
		isLoading: string,
		result: string
	}

	interface OdPairRecord { odPair: string }

	interface BuySell { buySell: string }

	interface MarketIds { market: string }

	interface ConfidenceIntervalRecord { interval: string }

	interface ZipRecord { zip: string }

	interface ConfidenceIntervalCompleteRecord {
		confidence: string,
		factor: string
	}

	interface MarketAndZipRecord {
		market: string,
		label: string
	}


	const trailerType = [
		{ value: 'V', label: 'Van' },
		{ value: 'R', label: 'Reefer' },
		{ value: 'F', label: 'Flatbed' }
	];

	const carrierType = [
		{ value: 'Broker', label: 'Broker' },
		{ value: 'Asset', label: 'Asset' }
	];

	const confidenceVals = [
		{ value: '0.50', label: '0.50' },
		{ value: '0.55', label: '0.55' },
		{ value: '0.60', label: '0.60' },
		{ value: '0.65', label: '0.65' },
		{ value: '0.70', label: '0.70' },
		{ value: '0.75', label: '0.75' },
		{ value: '0.80', label: '0.80' },
		{ value: '0.85', label: '0.85' },
		{ value: '0.90', label: '0.90' },
		{ value: '0.95', label: '0.95' }
	];

	const commodityType = [
		{ value: '01 – Live animals and fish', label: '01 – Live animals and fish' },
		{ value: '02 – Cereal grains', label: '02 – Cereal grains' },
		{ value: '03 – Other agricultural products', label: '03 – Other agricultural products' },
		{ value: '04 – Feed and products of animal origin', label: '04 – Feed and products of animal origin' },
		{ value: '05 – Meat and seafood', label: '05 – Meat and seafood' },
		{ value: '06 – Milled grain products', label: '06 – Milled grain products' },
		{ value: '07 – Other prepared foodstuffs', label: '07 – Other prepared foodstuffs' },
		{ value: '08 – Alcoholic beverages', label: '08 – Alcoholic beverages' },
		{ value: '09 – Tobacco products', label: '09 – Tobacco products' },
		{ value: '10 – Building stone', label: '10 – Building stone' },
		{ value: '11 – Natural sands', label: '11 – Natural sands' },
		{ value: '12 – Gravel and crushed stone', label: '12 – Gravel and crushed stone' },
		{ value: '13 – Other nonmetallic minerals', label: '13 – Other nonmetallic minerals' },
		{ value: '14 – Metallic ores', label: '14 – Metallic ores' },
		{ value: '15 – Coal', label: '15 – Coal' },
		{ value: '16 – Crude petroleum', label: '16 – Crude petroleum' },
		{ value: '17 – Gasoline, kerosene, and ethanol', label: '17 – Gasoline, kerosene, and ethanol' },
		{ value: '18 – Diesel and other fuel oils', label: '18 – Diesel and other fuel oils' },
		{ value: '19 – Other fossil fuel products', label: '19 – Other fossil fuel products' },
		{ value: '20 – Basic chemicals', label: '20 – Basic chemicals' },
		{ value: '21 – Pharmaceutical products', label: '21 – Pharmaceutical products' },
		{ value: '22 – Fertilizers', label: '22 – Fertilizers' },
		{ value: '23 – Chemical products and preparations', label: '23 – Chemical products and preparations' },
		{ value: '24 – Plastics and rubber', label: '24 – Plastics and rubber' },
		{ value: '25 – Logs and wood in the rough', label: '25 – Logs and wood in the rough' },
		{ value: '26 – Wood products', label: '26 – Wood products' },
		{ value: '27 – Pulp, paper, and paperboard', label: '27 – Pulp, paper, and paperboard' },
		{ value: '28 – Paper or paperboard articles', label: '28 – Paper or paperboard articles' },
		{ value: '29 – Printed products', label: '29 – Printed products' },
		{ value: '30 – Textiles and leather', label: '30 – Textiles and leather' },
		{ value: '31 – Nonmetallic mineral products', label: '31 – Nonmetallic mineral products' },
		{ value: '32 – Metal in basic shapes', label: '32 – Metal in basic shapes' },
		{ value: '33 – Articles of metal', label: '33 – Articles of metal' },
		{ value: '34 – Machinery', label: '34 – Machinery' },
		{ value: '35 – Electronics', label: '35 – Electronics' },
		{ value: '36 – Motorized and other vehicles', label: '36 – Motorized and other vehicles' },
		{ value: '37 – Other transportation equipment', label: '37 – Other transportation equipment' },
		{ value: '38 – Precision instruments', label: '38 – Precision instruments' },
		{ value: '39 – Furniture', label: '39 – Furniture' },
		{ value: '40 – Miscellaneous manufactured products', label: '40 – Miscellaneous manufactured products' },
		{ value: '41 – Waste and scrap', label: '41 – Waste and scrap' },
		{ value: '43 – Mixed freight', label: '43 – Mixed freight' }
	];

	const kmaFromZip3: SelectionItem[] = [
		{ label: "005", value: "NY_BRN" },
		{ label: "010", value: "MA_SPR" },
		{ label: "011", value: "MA_SPR" },
		{ label: "012", value: "MA_SPR" },
		{ label: "013", value: "MA_SPR" },
		{ label: "014", value: "MA_BOS" },
		{ label: "015", value: "MA_BOS" },
		{ label: "016", value: "MA_BOS" },
		{ label: "017", value: "MA_BOS" },
		{ label: "018", value: "MA_BOS" },
		{ label: "019", value: "MA_BOS" },
		{ label: "020", value: "MA_BOS" },
		{ label: "021", value: "MA_BOS" },
		{ label: "022", value: "MA_BOS" },
		{ label: "023", value: "MA_BOS" },
		{ label: "024", value: "MA_BOS" },
		{ label: "025", value: "MA_BOS" },
		{ label: "026", value: "MA_BOS" },
		{ label: "027", value: "MA_BOS" },
		{ label: "028", value: "MA_BOS" },
		{ label: "029", value: "MA_BOS" },
		{ label: "030", value: "NH_BRI" },
		{ label: "031", value: "NH_BRI" },
		{ label: "032", value: "NH_BRI" },
		{ label: "033", value: "NH_BRI" },
		{ label: "034", value: "NH_BRI" },
		{ label: "035", value: "NH_BRI" },
		{ label: "036", value: "NH_BRI" },
		{ label: "037", value: "NH_BRI" },
		{ label: "038", value: "ME_AUG" },
		{ label: "039", value: "ME_AUG" },
		{ label: "040", value: "ME_AUG" },
		{ label: "041", value: "ME_AUG" },
		{ label: "042", value: "ME_AUG" },
		{ label: "043", value: "ME_AUG" },
		{ label: "044", value: "ME_AUG" },
		{ label: "045", value: "ME_AUG" },
		{ label: "046", value: "ME_AUG" },
		{ label: "047", value: "ME_AUG" },
		{ label: "048", value: "ME_AUG" },
		{ label: "049", value: "ME_AUG" },
		{ label: "050", value: "NH_BRI" },
		{ label: "051", value: "NH_BRI" },
		{ label: "052", value: "NY_ALB" },
		{ label: "053", value: "NY_ALB" },
		{ label: "054", value: "NY_ALB" },
		{ label: "055", value: "MA_BOS" },
		{ label: "056", value: "NH_BRI" },
		{ label: "057", value: "NH_BRI" },
		{ label: "058", value: "NH_BRI" },
		{ label: "059", value: "NH_BRI" },
		{ label: "060", value: "CT_HAR" },
		{ label: "061", value: "CT_HAR" },
		{ label: "062", value: "CT_HAR" },
		{ label: "063", value: "CT_HAR" },
		{ label: "064", value: "CT_HAR" },
		{ label: "065", value: "CT_HAR" },
		{ label: "066", value: "CT_HAR" },
		{ label: "067", value: "CT_HAR" },
		{ label: "068", value: "CT_HAR" },
		{ label: "069", value: "CT_HAR" },
		{ label: "070", value: "NJ_ELI" },
		{ label: "071", value: "NJ_ELI" },
		{ label: "072", value: "NJ_ELI" },
		{ label: "073", value: "NJ_ELI" },
		{ label: "074", value: "NJ_ELI" },
		{ label: "075", value: "NJ_ELI" },
		{ label: "076", value: "NJ_ELI" },
		{ label: "077", value: "NJ_ELI" },
		{ label: "078", value: "NJ_ELI" },
		{ label: "079", value: "NJ_ELI" },
		{ label: "080", value: "PA_PHI" },
		{ label: "081", value: "PA_PHI" },
		{ label: "082", value: "PA_PHI" },
		{ label: "083", value: "PA_PHI" },
		{ label: "084", value: "PA_PHI" },
		{ label: "085", value: "NJ_ELI" },
		{ label: "086", value: "NJ_ELI" },
		{ label: "087", value: "NJ_ELI" },
		{ label: "088", value: "NJ_ELI" },
		{ label: "089", value: "NJ_ELI" },
		{ label: "100", value: "NY_BRN" },
		{ label: "101", value: "NY_BRN" },
		{ label: "102", value: "NY_BRN" },
		{ label: "103", value: "NY_BRN" },
		{ label: "104", value: "NY_BRN" },
		{ label: "105", value: "CT_HAR" },
		{ label: "106", value: "CT_HAR" },
		{ label: "107", value: "CT_HAR" },
		{ label: "108", value: "CT_HAR" },
		{ label: "109", value: "CT_HAR" },
		{ label: "110", value: "NY_BRN" },
		{ label: "111", value: "NY_BRN" },
		{ label: "112", value: "NY_BRN" },
		{ label: "113", value: "NY_BRN" },
		{ label: "114", value: "NY_BRN" },
		{ label: "115", value: "NY_BRN" },
		{ label: "116", value: "NY_BRN" },
		{ label: "117", value: "NY_BRN" },
		{ label: "118", value: "NY_BRN" },
		{ label: "119", value: "NY_BRN" },
		{ label: "120", value: "NY_ALB" },
		{ label: "121", value: "NY_ALB" },
		{ label: "122", value: "NY_ALB" },
		{ label: "123", value: "NY_ALB" },
		{ label: "124", value: "NY_ALB" },
		{ label: "125", value: "NY_ALB" },
		{ label: "126", value: "NY_ALB" },
		{ label: "127", value: "NY_ALB" },
		{ label: "128", value: "NY_ALB" },
		{ label: "129", value: "NY_ALB" },
		{ label: "130", value: "NY_SYR" },
		{ label: "131", value: "NY_SYR" },
		{ label: "132", value: "NY_SYR" },
		{ label: "133", value: "NY_SYR" },
		{ label: "134", value: "NY_SYR" },
		{ label: "135", value: "NY_SYR" },
		{ label: "136", value: "NY_SYR" },
		{ label: "137", value: "NY_ELM" },
		{ label: "138", value: "NY_ELM" },
		{ label: "139", value: "NY_ELM" },
		{ label: "140", value: "NY_BUF" },
		{ label: "141", value: "NY_BUF" },
		{ label: "142", value: "NY_BUF" },
		{ label: "143", value: "NY_BUF" },
		{ label: "144", value: "NY_ROC" },
		{ label: "145", value: "NY_ROC" },
		{ label: "146", value: "NY_ROC" },
		{ label: "147", value: "NY_BUF" },
		{ label: "148", value: "NY_ELM" },
		{ label: "149", value: "NY_ELM" },
		{ label: "150", value: "PA_PIT" },
		{ label: "151", value: "PA_PIT" },
		{ label: "152", value: "PA_PIT" },
		{ label: "153", value: "PA_PIT" },
		{ label: "154", value: "PA_PIT" },
		{ label: "155", value: "PA_PIT" },
		{ label: "156", value: "PA_PIT" },
		{ label: "157", value: "PA_PIT" },
		{ label: "158", value: "PA_PIT" },
		{ label: "159", value: "PA_PIT" },
		{ label: "160", value: "PA_PIT" },
		{ label: "161", value: "PA_PIT" },
		{ label: "162", value: "PA_PIT" },
		{ label: "163", value: "PA_ERI" },
		{ label: "164", value: "PA_ERI" },
		{ label: "165", value: "PA_ERI" },
		{ label: "166", value: "PA_HAR" },
		{ label: "167", value: "PA_ERI" },
		{ label: "168", value: "PA_HAR" },
		{ label: "169", value: "NY_ELM" },
		{ label: "170", value: "PA_HAR" },
		{ label: "171", value: "PA_HAR" },
		{ label: "172", value: "PA_HAR" },
		{ label: "173", value: "PA_HAR" },
		{ label: "174", value: "PA_HAR" },
		{ label: "175", value: "PA_HAR" },
		{ label: "176", value: "PA_HAR" },
		{ label: "177", value: "PA_HAR" },
		{ label: "178", value: "PA_HAR" },
		{ label: "179", value: "PA_HAR" },
		{ label: "180", value: "PA_ALL" },
		{ label: "181", value: "PA_ALL" },
		{ label: "182", value: "PA_ALL" },
		{ label: "183", value: "PA_ALL" },
		{ label: "184", value: "PA_ALL" },
		{ label: "185", value: "PA_ALL" },
		{ label: "186", value: "PA_ALL" },
		{ label: "187", value: "PA_ALL" },
		{ label: "188", value: "NY_ELM" },
		{ label: "189", value: "PA_ALL" },
		{ label: "190", value: "PA_PHI" },
		{ label: "191", value: "PA_PHI" },
		{ label: "192", value: "PA_PHI" },
		{ label: "193", value: "PA_PHI" },
		{ label: "194", value: "PA_PHI" },
		{ label: "195", value: "PA_PHI" },
		{ label: "196", value: "PA_PHI" },
		{ label: "197", value: "MD_BAL" },
		{ label: "198", value: "MD_BAL" },
		{ label: "199", value: "MD_BAL" },
		{ label: "200", value: "VA_ALE" },
		{ label: "201", value: "VA_ALE" },
		{ label: "202", value: "VA_ALE" },
		{ label: "203", value: "VA_ALE" },
		{ label: "204", value: "VA_ALE" },
		{ label: "205", value: "VA_ALE" },
		{ label: "206", value: "VA_ALE" },
		{ label: "207", value: "MD_BAL" },
		{ label: "208", value: "MD_BAL" },
		{ label: "209", value: "MD_BAL" },
		{ label: "210", value: "MD_BAL" },
		{ label: "211", value: "MD_BAL" },
		{ label: "212", value: "MD_BAL" },
		{ label: "214", value: "MD_BAL" },
		{ label: "215", value: "VA_WIN" },
		{ label: "216", value: "MD_BAL" },
		{ label: "217", value: "VA_WIN" },
		{ label: "218", value: "MD_BAL" },
		{ label: "219", value: "MD_BAL" },
		{ label: "220", value: "VA_ALE" },
		{ label: "221", value: "VA_ALE" },
		{ label: "222", value: "VA_ALE" },
		{ label: "223", value: "VA_ALE" },
		{ label: "224", value: "VA_RCH" },
		{ label: "225", value: "VA_RCH" },
		{ label: "226", value: "VA_WIN" },
		{ label: "227", value: "VA_ALE" },
		{ label: "228", value: "VA_WIN" },
		{ label: "229", value: "VA_RCH" },
		{ label: "230", value: "VA_RCH" },
		{ label: "231", value: "VA_RCH" },
		{ label: "232", value: "VA_RCH" },
		{ label: "233", value: "VA_NOR" },
		{ label: "234", value: "VA_NOR" },
		{ label: "235", value: "VA_NOR" },
		{ label: "236", value: "VA_NOR" },
		{ label: "237", value: "VA_NOR" },
		{ label: "238", value: "VA_RCH" },
		{ label: "239", value: "VA_ROA" },
		{ label: "240", value: "VA_ROA" },
		{ label: "241", value: "VA_ROA" },
		{ label: "242", value: "TN_KNO" },
		{ label: "243", value: "VA_ROA" },
		{ label: "244", value: "VA_ROA" },
		{ label: "245", value: "VA_ROA" },
		{ label: "246", value: "WV_CHA" },
		{ label: "247", value: "WV_CHA" },
		{ label: "248", value: "WV_CHA" },
		{ label: "249", value: "WV_CHA" },
		{ label: "250", value: "WV_CHA" },
		{ label: "251", value: "WV_CHA" },
		{ label: "252", value: "WV_CHA" },
		{ label: "253", value: "WV_CHA" },
		{ label: "254", value: "VA_WIN" },
		{ label: "255", value: "WV_HUN" },
		{ label: "256", value: "WV_CHA" },
		{ label: "257", value: "WV_HUN" },
		{ label: "258", value: "WV_CHA" },
		{ label: "259", value: "WV_CHA" },
		{ label: "260", value: "PA_PIT" },
		{ label: "261", value: "WV_CHA" },
		{ label: "262", value: "WV_CHA" },
		{ label: "263", value: "WV_CHA" },
		{ label: "264", value: "WV_CHA" },
		{ label: "265", value: "PA_PIT" },
		{ label: "266", value: "WV_CHA" },
		{ label: "267", value: "VA_WIN" },
		{ label: "268", value: "VA_WIN" },
		{ label: "270", value: "NC_GRE" },
		{ label: "271", value: "NC_GRE" },
		{ label: "272", value: "NC_GRE" },
		{ label: "273", value: "NC_GRE" },
		{ label: "274", value: "NC_GRE" },
		{ label: "275", value: "NC_RAL" },
		{ label: "276", value: "NC_RAL" },
		{ label: "277", value: "NC_RAL" },
		{ label: "278", value: "NC_RAL" },
		{ label: "279", value: "VA_NOR" },
		{ label: "280", value: "NC_CHA" },
		{ label: "281", value: "NC_CHA" },
		{ label: "282", value: "NC_CHA" },
		{ label: "283", value: "NC_CHA" },
		{ label: "284", value: "NC_WIL" },
		{ label: "285", value: "NC_WIL" },
		{ label: "286", value: "NC_CHA" },
		{ label: "287", value: "SC_GRE" },
		{ label: "288", value: "SC_GRE" },
		{ label: "289", value: "TN_CHA" },
		{ label: "290", value: "SC_COL" },
		{ label: "291", value: "SC_COL" },
		{ label: "292", value: "SC_COL" },
		{ label: "293", value: "SC_GRE" },
		{ label: "294", value: "SC_CHA" },
		{ label: "295", value: "SC_COL" },
		{ label: "296", value: "SC_GRE" },
		{ label: "297", value: "NC_CHA" },
		{ label: "298", value: "SC_GRE" },
		{ label: "299", value: "GA_SAV" },
		{ label: "300", value: "GA_ATL" },
		{ label: "301", value: "GA_ATL" },
		{ label: "302", value: "GA_ATL" },
		{ label: "303", value: "GA_ATL" },
		{ label: "304", value: "GA_SAV" },
		{ label: "305", value: "GA_ATL" },
		{ label: "306", value: "GA_ATL" },
		{ label: "307", value: "TN_CHA" },
		{ label: "308", value: "SC_GRE" },
		{ label: "309", value: "SC_GRE" },
		{ label: "310", value: "GA_MAC" },
		{ label: "311", value: "GA_ATL" },
		{ label: "312", value: "GA_MAC" },
		{ label: "313", value: "GA_SAV" },
		{ label: "314", value: "GA_SAV" },
		{ label: "315", value: "GA_SAV" },
		{ label: "316", value: "GA_TIF" },
		{ label: "317", value: "GA_TIF" },
		{ label: "318", value: "AL_MON" },
		{ label: "319", value: "AL_MON" },
		{ label: "320", value: "FL_JAX" },
		{ label: "321", value: "FL_JAX" },
		{ label: "322", value: "FL_JAX" },
		{ label: "323", value: "FL_TAL" },
		{ label: "324", value: "FL_TAL" },
		{ label: "325", value: "AL_MOB" },
		{ label: "326", value: "FL_JAX" },
		{ label: "327", value: "FL_LAK" },
		{ label: "328", value: "FL_LAK" },
		{ label: "329", value: "FL_LAK" },
		{ label: "330", value: "FL_MIA" },
		{ label: "331", value: "FL_MIA" },
		{ label: "332", value: "FL_MIA" },
		{ label: "333", value: "FL_MIA" },
		{ label: "334", value: "FL_MIA" },
		{ label: "335", value: "FL_LAK" },
		{ label: "336", value: "FL_LAK" },
		{ label: "337", value: "FL_LAK" },
		{ label: "338", value: "FL_LAK" },
		{ label: "339", value: "FL_MIA" },
		{ label: "341", value: "FL_MIA" },
		{ label: "342", value: "FL_LAK" },
		{ label: "344", value: "FL_LAK" },
		{ label: "346", value: "FL_LAK" },
		{ label: "347", value: "FL_LAK" },
		{ label: "349", value: "FL_MIA" },
		{ label: "350", value: "AL_BIR" },
		{ label: "351", value: "AL_BIR" },
		{ label: "352", value: "AL_BIR" },
		{ label: "354", value: "AL_BIR" },
		{ label: "355", value: "AL_DEC" },
		{ label: "356", value: "AL_DEC" },
		{ label: "357", value: "AL_DEC" },
		{ label: "358", value: "AL_DEC" },
		{ label: "359", value: "TN_CHA" },
		{ label: "360", value: "AL_MON" },
		{ label: "361", value: "AL_MON" },
		{ label: "362", value: "AL_BIR" },
		{ label: "363", value: "AL_MON" },
		{ label: "364", value: "AL_MOB" },
		{ label: "365", value: "AL_MOB" },
		{ label: "366", value: "AL_MOB" },
		{ label: "367", value: "AL_MON" },
		{ label: "368", value: "AL_MON" },
		{ label: "369", value: "AL_MON" },
		{ label: "370", value: "TN_NAS" },
		{ label: "371", value: "TN_NAS" },
		{ label: "372", value: "TN_NAS" },
		{ label: "373", value: "TN_CHA" },
		{ label: "374", value: "TN_CHA" },
		{ label: "375", value: "TN_MEM" },
		{ label: "376", value: "TN_KNO" },
		{ label: "377", value: "TN_KNO" },
		{ label: "378", value: "TN_KNO" },
		{ label: "379", value: "TN_KNO" },
		{ label: "380", value: "TN_MEM" },
		{ label: "381", value: "TN_MEM" },
		{ label: "382", value: "TN_MEM" },
		{ label: "383", value: "TN_MEM" },
		{ label: "384", value: "AL_DEC" },
		{ label: "385", value: "TN_NAS" },
		{ label: "386", value: "TN_MEM" },
		{ label: "387", value: "MS_JAC" },
		{ label: "388", value: "AL_DEC" },
		{ label: "389", value: "MS_JAC" },
		{ label: "390", value: "MS_JAC" },
		{ label: "391", value: "MS_JAC" },
		{ label: "392", value: "MS_JAC" },
		{ label: "393", value: "MS_JAC" },
		{ label: "394", value: "AL_MOB" },
		{ label: "395", value: "AL_MOB" },
		{ label: "396", value: "MS_JAC" },
		{ label: "397", value: "MS_JAC" },
		{ label: "398", value: "GA_TIF" },
		{ label: "399", value: "GA_ATL" },
		{ label: "400", value: "KY_LOU" },
		{ label: "401", value: "KY_LOU" },
		{ label: "402", value: "KY_LOU" },
		{ label: "403", value: "KY_LEX" },
		{ label: "404", value: "KY_LEX" },
		{ label: "405", value: "KY_LEX" },
		{ label: "406", value: "KY_LEX" },
		{ label: "407", value: "TN_KNO" },
		{ label: "408", value: "TN_KNO" },
		{ label: "409", value: "TN_KNO" },
		{ label: "410", value: "KY_LEX" },
		{ label: "411", value: "WV_HUN" },
		{ label: "412", value: "WV_HUN" },
		{ label: "413", value: "KY_LEX" },
		{ label: "414", value: "KY_LEX" },
		{ label: "415", value: "WV_CHA" },
		{ label: "416", value: "WV_CHA" },
		{ label: "417", value: "KY_LEX" },
		{ label: "418", value: "KY_LEX" },
		{ label: "419", value: "KY_LEX" },
		{ label: "420", value: "MO_GIR" },
		{ label: "421", value: "KY_BOW" },
		{ label: "422", value: "KY_BOW" },
		{ label: "423", value: "IN_EVA" },
		{ label: "424", value: "IN_EVA" },
		{ label: "425", value: "TN_KNO" },
		{ label: "426", value: "TN_KNO" },
		{ label: "427", value: "KY_BOW" },
		{ label: "430", value: "OH_COL" },
		{ label: "431", value: "OH_COL" },
		{ label: "432", value: "OH_COL" },
		{ label: "433", value: "OH_COL" },
		{ label: "434", value: "OH_TOL" },
		{ label: "435", value: "OH_TOL" },
		{ label: "436", value: "OH_TOL" },
		{ label: "437", value: "OH_COL" },
		{ label: "438", value: "OH_COL" },
		{ label: "439", value: "PA_PIT" },
		{ label: "440", value: "OH_CLE" },
		{ label: "441", value: "OH_CLE" },
		{ label: "442", value: "OH_CLE" },
		{ label: "443", value: "OH_CLE" },
		{ label: "444", value: "OH_CLE" },
		{ label: "445", value: "OH_CLE" },
		{ label: "446", value: "OH_CLE" },
		{ label: "447", value: "OH_CLE" },
		{ label: "448", value: "OH_CLE" },
		{ label: "449", value: "OH_CLE" },
		{ label: "450", value: "OH_CIN" },
		{ label: "451", value: "OH_CIN" },
		{ label: "452", value: "OH_CIN" },
		{ label: "453", value: "OH_COL" },
		{ label: "454", value: "OH_COL" },
		{ label: "455", value: "OH_COL" },
		{ label: "456", value: "WV_HUN" },
		{ label: "457", value: "WV_CHA" },
		{ label: "458", value: "OH_TOL" },
		{ label: "459", value: "OH_CIN" },
		{ label: "460", value: "IN_IND" },
		{ label: "461", value: "IN_IND" },
		{ label: "462", value: "IN_IND" },
		{ label: "463", value: "IN_GRY" },
		{ label: "464", value: "IN_GRY" },
		{ label: "465", value: "IN_SBD" },
		{ label: "466", value: "IN_SBD" },
		{ label: "467", value: "IN_FTW" },
		{ label: "468", value: "IN_FTW" },
		{ label: "469", value: "IN_FTW" },
		{ label: "470", value: "OH_CIN" },
		{ label: "471", value: "KY_LOU" },
		{ label: "472", value: "IN_IND" },
		{ label: "473", value: "IN_IND" },
		{ label: "474", value: "IN_IND" },
		{ label: "475", value: "IN_EVA" },
		{ label: "476", value: "IN_EVA" },
		{ label: "477", value: "IN_EVA" },
		{ label: "478", value: "IN_TER" },
		{ label: "479", value: "IN_IND" },
		{ label: "480", value: "MI_DET" },
		{ label: "481", value: "MI_DET" },
		{ label: "482", value: "MI_DET" },
		{ label: "483", value: "MI_DET" },
		{ label: "484", value: "MI_DET" },
		{ label: "485", value: "MI_DET" },
		{ label: "486", value: "MI_SAG" },
		{ label: "487", value: "MI_SAG" },
		{ label: "488", value: "MI_RAP" },
		{ label: "489", value: "MI_RAP" },
		{ label: "490", value: "MI_RAP" },
		{ label: "491", value: "IN_SBD" },
		{ label: "492", value: "MI_DET" },
		{ label: "493", value: "MI_RAP" },
		{ label: "494", value: "MI_RAP" },
		{ label: "495", value: "MI_RAP" },
		{ label: "496", value: "MI_RAP" },
		{ label: "497", value: "MI_SAG" },
		{ label: "498", value: "MN_DUL" },
		{ label: "499", value: "MN_DUL" },
		{ label: "500", value: "IA_DES" },
		{ label: "501", value: "IA_DES" },
		{ label: "502", value: "IA_DES" },
		{ label: "503", value: "IA_DES" },
		{ label: "504", value: "IA_DES" },
		{ label: "505", value: "IA_DES" },
		{ label: "506", value: "IA_DUB" },
		{ label: "507", value: "IA_DUB" },
		{ label: "508", value: "IA_DES" },
		{ label: "509", value: "IA_DES" },
		{ label: "510", value: "SD_SXF" },
		{ label: "511", value: "SD_SXF" },
		{ label: "512", value: "SD_SXF" },
		{ label: "513", value: "SD_SXF" },
		{ label: "514", value: "IA_DES" },
		{ label: "515", value: "NE_OMA" },
		{ label: "516", value: "NE_OMA" },
		{ label: "520", value: "IA_DUB" },
		{ label: "521", value: "IA_DUB" },
		{ label: "522", value: "IA_CED" },
		{ label: "523", value: "IA_CED" },
		{ label: "524", value: "IA_CED" },
		{ label: "525", value: "IL_ROC" },
		{ label: "526", value: "IL_ROC" },
		{ label: "527", value: "IL_ROC" },
		{ label: "528", value: "IL_ROC" },
		{ label: "530", value: "WI_MIL" },
		{ label: "531", value: "WI_MIL" },
		{ label: "532", value: "WI_MIL" },
		{ label: "534", value: "WI_MIL" },
		{ label: "535", value: "WI_MAD" },
		{ label: "537", value: "WI_MAD" },
		{ label: "538", value: "IA_DUB" },
		{ label: "539", value: "WI_MAD" },
		{ label: "540", value: "WI_EAU" },
		{ label: "541", value: "WI_GRE" },
		{ label: "542", value: "WI_GRE" },
		{ label: "543", value: "WI_GRE" },
		{ label: "544", value: "WI_GRE" },
		{ label: "545", value: "MN_DUL" },
		{ label: "546", value: "WI_EAU" },
		{ label: "547", value: "WI_EAU" },
		{ label: "548", value: "MN_DUL" },
		{ label: "549", value: "WI_GRE" },
		{ label: "550", value: "MN_MIN" },
		{ label: "551", value: "MN_MIN" },
		{ label: "553", value: "MN_MIN" },
		{ label: "554", value: "MN_MIN" },
		{ label: "555", value: "MN_MIN" },
		{ label: "556", value: "MN_DUL" },
		{ label: "557", value: "MN_DUL" },
		{ label: "558", value: "MN_DUL" },
		{ label: "559", value: "WI_EAU" },
		{ label: "560", value: "MN_MIN" },
		{ label: "561", value: "SD_SXF" },
		{ label: "562", value: "SD_SXF" },
		{ label: "563", value: "MN_STC" },
		{ label: "564", value: "MN_STC" },
		{ label: "565", value: "ND_FAR" },
		{ label: "566", value: "ND_FAR" },
		{ label: "567", value: "ND_FAR" },
		{ label: "569", value: "VA_ALE" },
		{ label: "570", value: "SD_SXF" },
		{ label: "571", value: "SD_SXF" },
		{ label: "572", value: "SD_SXF" },
		{ label: "573", value: "SD_SXF" },
		{ label: "574", value: "SD_SXF" },
		{ label: "575", value: "SD_RAP" },
		{ label: "576", value: "SD_RAP" },
		{ label: "577", value: "SD_RAP" },
		{ label: "580", value: "ND_FAR" },
		{ label: "581", value: "ND_FAR" },
		{ label: "582", value: "ND_FAR" },
		{ label: "583", value: "ND_BIS" },
		{ label: "584", value: "ND_BIS" },
		{ label: "585", value: "ND_BIS" },
		{ label: "586", value: "ND_BIS" },
		{ label: "587", value: "ND_BIS" },
		{ label: "588", value: "ND_BIS" },
		{ label: "590", value: "MT_BIL" },
		{ label: "591", value: "MT_BIL" },
		{ label: "592", value: "MT_BIL" },
		{ label: "593", value: "MT_BIL" },
		{ label: "594", value: "MT_MIS" },
		{ label: "595", value: "MT_MIS" },
		{ label: "596", value: "MT_MIS" },
		{ label: "597", value: "MT_MIS" },
		{ label: "598", value: "MT_MIS" },
		{ label: "599", value: "MT_MIS" },
		{ label: "600", value: "IL_CHI" },
		{ label: "601", value: "IL_CHI" },
		{ label: "602", value: "IL_CHI" },
		{ label: "603", value: "IL_CHI" },
		{ label: "604", value: "IL_JOL" },
		{ label: "605", value: "IL_JOL" },
		{ label: "606", value: "IL_CHI" },
		{ label: "607", value: "IL_CHI" },
		{ label: "608", value: "IL_CHI" },
		{ label: "609", value: "IL_BLO" },
		{ label: "610", value: "IL_RFD" },
		{ label: "611", value: "IL_RFD" },
		{ label: "612", value: "IL_ROC" },
		{ label: "613", value: "IL_ROC" },
		{ label: "614", value: "IL_ROC" },
		{ label: "615", value: "IL_BLO" },
		{ label: "616", value: "IL_BLO" },
		{ label: "617", value: "IL_BLO" },
		{ label: "618", value: "IL_BLO" },
		{ label: "619", value: "IN_TER" },
		{ label: "620", value: "MO_STL" },
		{ label: "622", value: "MO_STL" },
		{ label: "623", value: "IL_QUI" },
		{ label: "624", value: "IN_TER" },
		{ label: "625", value: "IL_TAY" },
		{ label: "626", value: "IL_TAY" },
		{ label: "627", value: "IL_TAY" },
		{ label: "628", value: "IN_EVA" },
		{ label: "629", value: "MO_GIR" },
		{ label: "630", value: "MO_STL" },
		{ label: "631", value: "MO_STL" },
		{ label: "633", value: "MO_STL" },
		{ label: "634", value: "IL_QUI" },
		{ label: "635", value: "IL_QUI" },
		{ label: "636", value: "MO_GIR" },
		{ label: "637", value: "MO_GIR" },
		{ label: "638", value: "MO_GIR" },
		{ label: "639", value: "MO_GIR" },
		{ label: "640", value: "MO_KAN" },
		{ label: "641", value: "MO_KAN" },
		{ label: "644", value: "MO_KAN" },
		{ label: "645", value: "MO_KAN" },
		{ label: "646", value: "MO_KAN" },
		{ label: "647", value: "MO_JOP" },
		{ label: "648", value: "MO_JOP" },
		{ label: "649", value: "MO_KAN" },
		{ label: "650", value: "MO_JEF" },
		{ label: "651", value: "MO_JEF" },
		{ label: "652", value: "MO_JEF" },
		{ label: "653", value: "MO_JEF" },
		{ label: "654", value: "MO_JOP" },
		{ label: "655", value: "MO_JOP" },
		{ label: "656", value: "MO_JOP" },
		{ label: "657", value: "MO_JOP" },
		{ label: "658", value: "MO_JOP" },
		{ label: "660", value: "MO_KAN" },
		{ label: "661", value: "MO_KAN" },
		{ label: "662", value: "MO_KAN" },
		{ label: "664", value: "MO_KAN" },
		{ label: "665", value: "MO_KAN" },
		{ label: "666", value: "MO_KAN" },
		{ label: "667", value: "MO_JOP" },
		{ label: "668", value: "KS_HUT" },
		{ label: "669", value: "KS_HUT" },
		{ label: "670", value: "KS_HUT" },
		{ label: "671", value: "KS_HUT" },
		{ label: "672", value: "KS_HUT" },
		{ label: "673", value: "KS_HUT" },
		{ label: "674", value: "KS_HUT" },
		{ label: "675", value: "KS_HUT" },
		{ label: "676", value: "KS_HUT" },
		{ label: "677", value: "KS_HUT" },
		{ label: "678", value: "KS_HUT" },
		{ label: "679", value: "KS_HUT" },
		{ label: "680", value: "NE_OMA" },
		{ label: "681", value: "NE_OMA" },
		{ label: "683", value: "NE_OMA" },
		{ label: "684", value: "NE_OMA" },
		{ label: "685", value: "NE_OMA" },
		{ label: "686", value: "NE_OMA" },
		{ label: "687", value: "NE_OMA" },
		{ label: "688", value: "NE_OMA" },
		{ label: "689", value: "NE_OMA" },
		{ label: "690", value: "NE_NPL" },
		{ label: "691", value: "NE_NPL" },
		{ label: "692", value: "NE_NPL" },
		{ label: "693", value: "NE_NPL" },
		{ label: "700", value: "LA_NEW" },
		{ label: "701", value: "LA_NEW" },
		{ label: "703", value: "LA_NEW" },
		{ label: "704", value: "LA_NEW" },
		{ label: "705", value: "LA_NEW" },
		{ label: "706", value: "LA_NEW" },
		{ label: "707", value: "LA_NEW" },
		{ label: "708", value: "LA_NEW" },
		{ label: "710", value: "LA_SHR" },
		{ label: "711", value: "LA_SHR" },
		{ label: "712", value: "LA_SHR" },
		{ label: "713", value: "LA_SHR" },
		{ label: "714", value: "LA_SHR" },
		{ label: "716", value: "AR_LIT" },
		{ label: "717", value: "AR_LIT" },
		{ label: "718", value: "TX_TEX" },
		{ label: "719", value: "AR_LIT" },
		{ label: "720", value: "AR_LIT" },
		{ label: "721", value: "AR_LIT" },
		{ label: "722", value: "AR_LIT" },
		{ label: "723", value: "TN_MEM" },
		{ label: "724", value: "TN_MEM" },
		{ label: "725", value: "AR_LIT" },
		{ label: "726", value: "AR_FAY" },
		{ label: "727", value: "AR_FAY" },
		{ label: "728", value: "AR_LIT" },
		{ label: "729", value: "AR_FAY" },
		{ label: "730", value: "OK_OKC" },
		{ label: "731", value: "OK_OKC" },
		{ label: "733", value: "TX_AUS" },
		{ label: "734", value: "OK_OKC" },
		{ label: "735", value: "OK_OKC" },
		{ label: "736", value: "OK_OKC" },
		{ label: "737", value: "OK_OKC" },
		{ label: "738", value: "OK_OKC" },
		{ label: "739", value: "TX_AMA" },
		{ label: "740", value: "OK_TUL" },
		{ label: "741", value: "OK_TUL" },
		{ label: "743", value: "OK_TUL" },
		{ label: "744", value: "OK_TUL" },
		{ label: "745", value: "OK_OKC" },
		{ label: "746", value: "OK_TUL" },
		{ label: "747", value: "OK_OKC" },
		{ label: "748", value: "OK_OKC" },
		{ label: "749", value: "AR_FAY" },
		{ label: "750", value: "TX_DAL" },
		{ label: "751", value: "TX_DAL" },
		{ label: "752", value: "TX_DAL" },
		{ label: "753", value: "TX_DAL" },
		{ label: "754", value: "TX_DAL" },
		{ label: "755", value: "TX_TEX" },
		{ label: "756", value: "LA_SHR" },
		{ label: "757", value: "TX_DAL" },
		{ label: "758", value: "TX_DAL" },
		{ label: "759", value: "LA_SHR" },
		{ label: "760", value: "TX_FTW" },
		{ label: "761", value: "TX_FTW" },
		{ label: "762", value: "TX_FTW" },
		{ label: "763", value: "TX_FTW" },
		{ label: "764", value: "TX_FTW" },
		{ label: "765", value: "TX_AUS" },
		{ label: "766", value: "TX_FTW" },
		{ label: "767", value: "TX_FTW" },
		{ label: "768", value: "TX_AUS" },
		{ label: "769", value: "TX_LUB" },
		{ label: "770", value: "TX_HOU" },
		{ label: "772", value: "TX_HOU" },
		{ label: "773", value: "TX_HOU" },
		{ label: "774", value: "TX_HOU" },
		{ label: "775", value: "TX_HOU" },
		{ label: "776", value: "TX_HOU" },
		{ label: "777", value: "TX_HOU" },
		{ label: "778", value: "TX_HOU" },
		{ label: "779", value: "TX_ANT" },
		{ label: "780", value: "TX_LAR" },
		{ label: "781", value: "TX_ANT" },
		{ label: "782", value: "TX_ANT" },
		{ label: "783", value: "TX_MCA" },
		{ label: "784", value: "TX_MCA" },
		{ label: "785", value: "TX_MCA" },
		{ label: "786", value: "TX_AUS" },
		{ label: "787", value: "TX_AUS" },
		{ label: "788", value: "TX_LAR" },
		{ label: "789", value: "TX_AUS" },
		{ label: "790", value: "TX_AMA" },
		{ label: "791", value: "TX_AMA" },
		{ label: "792", value: "TX_LUB" },
		{ label: "793", value: "TX_LUB" },
		{ label: "794", value: "TX_LUB" },
		{ label: "795", value: "TX_LUB" },
		{ label: "796", value: "TX_LUB" },
		{ label: "797", value: "TX_LUB" },
		{ label: "798", value: "TX_ELP" },
		{ label: "799", value: "TX_ELP" },
		{ label: "800", value: "CO_DEN" },
		{ label: "801", value: "CO_DEN" },
		{ label: "802", value: "CO_DEN" },
		{ label: "803", value: "CO_DEN" },
		{ label: "804", value: "CO_DEN" },
		{ label: "805", value: "CO_DEN" },
		{ label: "806", value: "CO_DEN" },
		{ label: "807", value: "NE_NPL" },
		{ label: "808", value: "CO_DEN" },
		{ label: "809", value: "CO_DEN" },
		{ label: "810", value: "CO_DEN" },
		{ label: "811", value: "NM_ALB" },
		{ label: "812", value: "CO_GRA" },
		{ label: "813", value: "CO_GRA" },
		{ label: "814", value: "CO_GRA" },
		{ label: "815", value: "CO_GRA" },
		{ label: "816", value: "CO_GRA" },
		{ label: "820", value: "CO_DEN" },
		{ label: "821", value: "MT_BIL" },
		{ label: "822", value: "NE_NPL" },
		{ label: "823", value: "WY_GRE" },
		{ label: "824", value: "MT_BIL" },
		{ label: "825", value: "WY_GRE" },
		{ label: "826", value: "SD_RAP" },
		{ label: "827", value: "SD_RAP" },
		{ label: "828", value: "MT_BIL" },
		{ label: "829", value: "WY_GRE" },
		{ label: "830", value: "MT_BIL" },
		{ label: "831", value: "WY_GRE" },
		{ label: "832", value: "ID_TWI" },
		{ label: "833", value: "ID_TWI" },
		{ label: "834", value: "ID_TWI" },
		{ label: "835", value: "WA_SPO" },
		{ label: "836", value: "ID_TWI" },
		{ label: "837", value: "ID_TWI" },
		{ label: "838", value: "WA_SPO" },
		{ label: "840", value: "UT_SLC" },
		{ label: "841", value: "UT_SLC" },
		{ label: "842", value: "UT_SLC" },
		{ label: "843", value: "UT_SLC" },
		{ label: "844", value: "UT_SLC" },
		{ label: "845", value: "CO_GRA" },
		{ label: "846", value: "UT_SLC" },
		{ label: "847", value: "UT_SLC" },
		{ label: "850", value: "AZ_PHO" },
		{ label: "851", value: "AZ_PHO" },
		{ label: "852", value: "AZ_PHO" },
		{ label: "853", value: "AZ_PHO" },
		{ label: "855", value: "AZ_FLA" },
		{ label: "856", value: "AZ_TUC" },
		{ label: "857", value: "AZ_TUC" },
		{ label: "859", value: "AZ_FLA" },
		{ label: "860", value: "AZ_FLA" },
		{ label: "863", value: "AZ_FLA" },
		{ label: "864", value: "AZ_FLA" },
		{ label: "865", value: "AZ_FLA" },
		{ label: "870", value: "NM_ALB" },
		{ label: "871", value: "NM_ALB" },
		{ label: "872", value: "NM_ALB" },
		{ label: "873", value: "NM_ALB" },
		{ label: "874", value: "NM_ALB" },
		{ label: "875", value: "NM_ALB" },
		{ label: "877", value: "NM_ALB" },
		{ label: "878", value: "NM_ALB" },
		{ label: "879", value: "NM_ALB" },
		{ label: "880", value: "TX_ELP" },
		{ label: "881", value: "TX_LUB" },
		{ label: "882", value: "TX_LUB" },
		{ label: "883", value: "NM_ALB" },
		{ label: "884", value: "NM_ALB" },
		{ label: "885", value: "TX_ELP" },
		{ label: "889", value: "NV_VEG" },
		{ label: "890", value: "NV_VEG" },
		{ label: "891", value: "NV_VEG" },
		{ label: "893", value: "NV_VEG" },
		{ label: "894", value: "NV_REN" },
		{ label: "895", value: "NV_REN" },
		{ label: "897", value: "NV_REN" },
		{ label: "898", value: "NV_REN" },
		{ label: "900", value: "CA_LAX" },
		{ label: "901", value: "CA_LAX" },
		{ label: "902", value: "CA_LAX" },
		{ label: "903", value: "CA_LAX" },
		{ label: "904", value: "CA_LAX" },
		{ label: "905", value: "CA_LAX" },
		{ label: "906", value: "CA_LAX" },
		{ label: "907", value: "CA_LAX" },
		{ label: "908", value: "CA_LAX" },
		{ label: "910", value: "CA_LAX" },
		{ label: "911", value: "CA_LAX" },
		{ label: "912", value: "CA_LAX" },
		{ label: "913", value: "CA_LAX" },
		{ label: "914", value: "CA_LAX" },
		{ label: "915", value: "CA_LAX" },
		{ label: "916", value: "CA_LAX" },
		{ label: "917", value: "CA_ONT" },
		{ label: "918", value: "CA_LAX" },
		{ label: "919", value: "CA_SDI" },
		{ label: "920", value: "CA_SDI" },
		{ label: "921", value: "CA_SDI" },
		{ label: "922", value: "CA_ONT" },
		{ label: "923", value: "CA_ONT" },
		{ label: "924", value: "CA_ONT" },
		{ label: "925", value: "CA_ONT" },
		{ label: "926", value: "CA_LAX" },
		{ label: "927", value: "CA_LAX" },
		{ label: "928", value: "CA_LAX" },
		{ label: "930", value: "CA_LAX" },
		{ label: "931", value: "CA_FRS" },
		{ label: "932", value: "CA_FRS" },
		{ label: "933", value: "CA_FRS" },
		{ label: "934", value: "CA_FRS" },
		{ label: "935", value: "CA_FRS" },
		{ label: "936", value: "CA_FRS" },
		{ label: "937", value: "CA_FRS" },
		{ label: "938", value: "CA_FRS" },
		{ label: "939", value: "CA_SFR" },
		{ label: "940", value: "CA_SFR" },
		{ label: "941", value: "CA_SFR" },
		{ label: "942", value: "CA_STK" },
		{ label: "943", value: "CA_STK" },
		{ label: "944", value: "CA_STK" },
		{ label: "945", value: "CA_SFR" },
		{ label: "946", value: "CA_SFR" },
		{ label: "947", value: "CA_SFR" },
		{ label: "948", value: "CA_SFR" },
		{ label: "949", value: "CA_SFR" },
		{ label: "950", value: "CA_SFR" },
		{ label: "951", value: "CA_SFR" },
		{ label: "952", value: "CA_STK" },
		{ label: "953", value: "CA_STK" },
		{ label: "954", value: "CA_SFR" },
		{ label: "955", value: "OR_MED" },
		{ label: "956", value: "CA_STK" },
		{ label: "957", value: "CA_STK" },
		{ label: "958", value: "CA_STK" },
		{ label: "959", value: "CA_STK" },
		{ label: "960", value: "OR_MED" },
		{ label: "961", value: "NV_REN" },
		{ label: "970", value: "OR_POR" },
		{ label: "971", value: "OR_POR" },
		{ label: "972", value: "OR_POR" },
		{ label: "973", value: "OR_POR" },
		{ label: "974", value: "OR_MED" },
		{ label: "975", value: "OR_MED" },
		{ label: "976", value: "OR_MED" },
		{ label: "977", value: "OR_MED" },
		{ label: "978", value: "OR_PEN" },
		{ label: "979", value: "ID_TWI" },
		{ label: "980", value: "WA_SEA" },
		{ label: "981", value: "WA_SEA" },
		{ label: "982", value: "WA_SEA" },
		{ label: "983", value: "WA_SEA" },
		{ label: "984", value: "WA_SEA" },
		{ label: "985", value: "WA_SEA" },
		{ label: "986", value: "OR_POR" },
		{ label: "988", value: "WA_SPO" },
		{ label: "989", value: "OR_PEN" },
		{ label: "990", value: "WA_SPO" },
		{ label: "991", value: "WA_SPO" },
		{ label: "992", value: "WA_SPO" },
		{ label: "993", value: "OR_PEN" },
		{ label: "994", value: "WA_SPO" },
		{ label: "A0A", value: "NF_STJ" },
		{ label: "A0B", value: "NF_STJ" },
		{ label: "A0C", value: "NF_STJ" },
		{ label: "A0E", value: "NF_STJ" },
		{ label: "A0G", value: "NF_STJ" },
		{ label: "A0H", value: "NF_STJ" },
		{ label: "A0J", value: "NF_STJ" },
		{ label: "A0K", value: "NF_STJ" },
		{ label: "A0L", value: "NF_STJ" },
		{ label: "A0M", value: "NF_STJ" },
		{ label: "A0N", value: "NF_STJ" },
		{ label: "A0P", value: "NF_STJ" },
		{ label: "A0R", value: "NF_STJ" },
		{ label: "A1A", value: "NF_STJ" },
		{ label: "A1B", value: "NF_STJ" },
		{ label: "A1C", value: "NF_STJ" },
		{ label: "A1E", value: "NF_STJ" },
		{ label: "A1G", value: "NF_STJ" },
		{ label: "A1H", value: "NF_STJ" },
		{ label: "A1K", value: "NF_STJ" },
		{ label: "A1L", value: "NF_STJ" },
		{ label: "A1M", value: "NF_STJ" },
		{ label: "A1N", value: "NF_STJ" },
		{ label: "A1S", value: "NF_STJ" },
		{ label: "A1V", value: "NF_STJ" },
		{ label: "A1W", value: "NF_STJ" },
		{ label: "A1X", value: "NF_STJ" },
		{ label: "A1Y", value: "NF_STJ" },
		{ label: "A2A", value: "NF_STJ" },
		{ label: "A2B", value: "NF_STJ" },
		{ label: "A2H", value: "NF_STJ" },
		{ label: "A2N", value: "NF_STJ" },
		{ label: "A2V", value: "NF_STJ" },
		{ label: "A5A", value: "NF_STJ" },
		{ label: "A8A", value: "NF_STJ" },
		{ label: "B0C", value: "NS_HAL" },
		{ label: "B0E", value: "NS_HAL" },
		{ label: "B0H", value: "NS_HAL" },
		{ label: "B0J", value: "NS_HAL" },
		{ label: "B0K", value: "NS_HAL" },
		{ label: "B0L", value: "NS_HAL" },
		{ label: "B0M", value: "NS_HAL" },
		{ label: "B0N", value: "NS_HAL" },
		{ label: "B0P", value: "NS_HAL" },
		{ label: "B0R", value: "NS_HAL" },
		{ label: "B0S", value: "NS_HAL" },
		{ label: "B0T", value: "NS_HAL" },
		{ label: "B0V", value: "NS_HAL" },
		{ label: "B0W", value: "NS_HAL" },
		{ label: "B1A", value: "NS_HAL" },
		{ label: "B1B", value: "NS_HAL" },
		{ label: "B1C", value: "NS_HAL" },
		{ label: "B1E", value: "NS_HAL" },
		{ label: "B1G", value: "NS_HAL" },
		{ label: "B1H", value: "NS_HAL" },
		{ label: "B1J", value: "NS_HAL" },
		{ label: "B1K", value: "NS_HAL" },
		{ label: "B1L", value: "NS_HAL" },
		{ label: "B1M", value: "NS_HAL" },
		{ label: "B1N", value: "NS_HAL" },
		{ label: "B1P", value: "NS_HAL" },
		{ label: "B1R", value: "NS_HAL" },
		{ label: "B1S", value: "NS_HAL" },
		{ label: "B1T", value: "NS_HAL" },
		{ label: "B1V", value: "NS_HAL" },
		{ label: "B1W", value: "NS_HAL" },
		{ label: "B1X", value: "NS_HAL" },
		{ label: "B1Y", value: "NS_HAL" },
		{ label: "B2A", value: "NS_HAL" },
		{ label: "B2C", value: "NS_HAL" },
		{ label: "B2E", value: "NS_HAL" },
		{ label: "B2G", value: "NS_HAL" },
		{ label: "B2H", value: "NS_HAL" },
		{ label: "B2J", value: "NS_HAL" },
		{ label: "B2N", value: "NS_HAL" },
		{ label: "B2R", value: "NS_HAL" },
		{ label: "B2S", value: "NS_HAL" },
		{ label: "B2T", value: "NS_HAL" },
		{ label: "B2V", value: "NS_HAL" },
		{ label: "B2W", value: "NS_HAL" },
		{ label: "B2X", value: "NS_HAL" },
		{ label: "B2Y", value: "NS_HAL" },
		{ label: "B2Z", value: "NS_HAL" },
		{ label: "B3A", value: "NS_HAL" },
		{ label: "B3B", value: "NS_HAL" },
		{ label: "B3E", value: "NS_HAL" },
		{ label: "B3G", value: "NS_HAL" },
		{ label: "B3H", value: "NS_HAL" },
		{ label: "B3J", value: "NS_HAL" },
		{ label: "B3K", value: "NS_HAL" },
		{ label: "B3L", value: "NS_HAL" },
		{ label: "B3M", value: "NS_HAL" },
		{ label: "B3N", value: "NS_HAL" },
		{ label: "B3P", value: "NS_HAL" },
		{ label: "B3R", value: "NS_HAL" },
		{ label: "B3S", value: "NS_HAL" },
		{ label: "B3T", value: "NS_HAL" },
		{ label: "B3V", value: "NS_HAL" },
		{ label: "B3Z", value: "NS_HAL" },
		{ label: "B4A", value: "NS_HAL" },
		{ label: "B4B", value: "NS_HAL" },
		{ label: "B4C", value: "NS_HAL" },
		{ label: "B4E", value: "NS_HAL" },
		{ label: "B4G", value: "NS_HAL" },
		{ label: "B4H", value: "NS_HAL" },
		{ label: "B4N", value: "NS_HAL" },
		{ label: "B4P", value: "NS_HAL" },
		{ label: "B4R", value: "NS_HAL" },
		{ label: "B4V", value: "NS_HAL" },
		{ label: "B5A", value: "NS_HAL" },
		{ label: "B9A", value: "NS_HAL" },
		{ label: "C0A", value: "NB_MON" },
		{ label: "C0B", value: "NB_MON" },
		{ label: "C1A", value: "NB_MON" },
		{ label: "C1B", value: "NB_MON" },
		{ label: "C1C", value: "NB_MON" },
		{ label: "C1E", value: "NB_MON" },
		{ label: "C1N", value: "NB_MON" },
		{ label: "E1A", value: "NB_MON" },
		{ label: "E1B", value: "NB_MON" },
		{ label: "E1C", value: "NB_MON" },
		{ label: "E1E", value: "NB_MON" },
		{ label: "E1G", value: "NB_MON" },
		{ label: "E1H", value: "NB_MON" },
		{ label: "E1J", value: "NB_MON" },
		{ label: "E1N", value: "NB_MON" },
		{ label: "E1V", value: "NB_MON" },
		{ label: "E1W", value: "NB_MON" },
		{ label: "E1X", value: "NB_MON" },
		{ label: "E2A", value: "NB_MON" },
		{ label: "E2E", value: "NB_MON" },
		{ label: "E2G", value: "NB_MON" },
		{ label: "E2H", value: "NB_MON" },
		{ label: "E2J", value: "NB_MON" },
		{ label: "E2K", value: "NB_MON" },
		{ label: "E2L", value: "NB_MON" },
		{ label: "E2M", value: "NB_MON" },
		{ label: "E2N", value: "NB_MON" },
		{ label: "E2P", value: "NB_MON" },
		{ label: "E2R", value: "NB_MON" },
		{ label: "E2S", value: "NB_MON" },
		{ label: "E2V", value: "NB_MON" },
		{ label: "E3A", value: "NB_MON" },
		{ label: "E3B", value: "NB_MON" },
		{ label: "E3C", value: "NB_MON" },
		{ label: "E3E", value: "NB_MON" },
		{ label: "E3L", value: "NB_MON" },
		{ label: "E3N", value: "NB_MON" },
		{ label: "E3V", value: "NB_MON" },
		{ label: "E3Y", value: "NB_MON" },
		{ label: "E3Z", value: "NB_MON" },
		{ label: "E4A", value: "NB_MON" },
		{ label: "E4B", value: "NB_MON" },
		{ label: "E4C", value: "NB_MON" },
		{ label: "E4E", value: "NB_MON" },
		{ label: "E4G", value: "NB_MON" },
		{ label: "E4H", value: "NB_MON" },
		{ label: "E4J", value: "NB_MON" },
		{ label: "E4K", value: "NB_MON" },
		{ label: "E4L", value: "NB_MON" },
		{ label: "E4M", value: "NB_MON" },
		{ label: "E4N", value: "NB_MON" },
		{ label: "E4P", value: "NB_MON" },
		{ label: "E4R", value: "NB_MON" },
		{ label: "E4S", value: "NB_MON" },
		{ label: "E4T", value: "NB_MON" },
		{ label: "E4V", value: "NB_MON" },
		{ label: "E4W", value: "NB_MON" },
		{ label: "E4X", value: "NB_MON" },
		{ label: "E4Y", value: "NB_MON" },
		{ label: "E4Z", value: "NB_MON" },
		{ label: "E5A", value: "NB_MON" },
		{ label: "E5B", value: "NB_MON" },
		{ label: "E5C", value: "NB_MON" },
		{ label: "E5E", value: "NB_MON" },
		{ label: "E5G", value: "NB_MON" },
		{ label: "E5H", value: "NB_MON" },
		{ label: "E5J", value: "NB_MON" },
		{ label: "E5K", value: "NB_MON" },
		{ label: "E5L", value: "NB_MON" },
		{ label: "E5M", value: "NB_MON" },
		{ label: "E5N", value: "NB_MON" },
		{ label: "E5P", value: "NB_MON" },
		{ label: "E5R", value: "NB_MON" },
		{ label: "E5S", value: "NB_MON" },
		{ label: "E5T", value: "NB_MON" },
		{ label: "E5V", value: "NB_MON" },
		{ label: "E6A", value: "NB_MON" },
		{ label: "E6B", value: "NB_MON" },
		{ label: "E6C", value: "NB_MON" },
		{ label: "E6E", value: "NB_MON" },
		{ label: "E6G", value: "NB_MON" },
		{ label: "E6H", value: "NB_MON" },
		{ label: "E6J", value: "NB_MON" },
		{ label: "E6K", value: "NB_MON" },
		{ label: "E6L", value: "NB_MON" },
		{ label: "E7A", value: "NB_MON" },
		{ label: "E7B", value: "NB_MON" },
		{ label: "E7C", value: "NB_MON" },
		{ label: "E7E", value: "NB_MON" },
		{ label: "E7G", value: "NB_MON" },
		{ label: "E7H", value: "NB_MON" },
		{ label: "E7J", value: "NB_MON" },
		{ label: "E7K", value: "NB_MON" },
		{ label: "E7L", value: "NB_MON" },
		{ label: "E7M", value: "NB_MON" },
		{ label: "E7N", value: "NB_MON" },
		{ label: "E7P", value: "NB_MON" },
		{ label: "E8A", value: "NB_MON" },
		{ label: "E8B", value: "NB_MON" },
		{ label: "E8C", value: "NB_MON" },
		{ label: "E8E", value: "NB_MON" },
		{ label: "E8G", value: "NB_MON" },
		{ label: "E8J", value: "NB_MON" },
		{ label: "E8K", value: "NB_MON" },
		{ label: "E8L", value: "NB_MON" },
		{ label: "E8M", value: "NB_MON" },
		{ label: "E8N", value: "NB_MON" },
		{ label: "E8P", value: "NB_MON" },
		{ label: "E8R", value: "NB_MON" },
		{ label: "E8S", value: "NB_MON" },
		{ label: "E8T", value: "NB_MON" },
		{ label: "E9A", value: "NB_MON" },
		{ label: "E9B", value: "NB_MON" },
		{ label: "E9C", value: "NB_MON" },
		{ label: "E9E", value: "NB_MON" },
		{ label: "E9G", value: "NB_MON" },
		{ label: "E9H", value: "NB_MON" },
		{ label: "G0A", value: "PQ_QUE" },
		{ label: "G0C", value: "PQ_QUE" },
		{ label: "G0E", value: "PQ_QUE" },
		{ label: "G0G", value: "PQ_QUE" },
		{ label: "G0H", value: "PQ_QUE" },
		{ label: "G0J", value: "PQ_QUE" },
		{ label: "G0K", value: "PQ_QUE" },
		{ label: "G0L", value: "PQ_QUE" },
		{ label: "G0M", value: "PQ_QUE" },
		{ label: "G0N", value: "PQ_QUE" },
		{ label: "G0P", value: "PQ_QUE" },
		{ label: "G0R", value: "PQ_QUE" },
		{ label: "G0S", value: "PQ_QUE" },
		{ label: "G0T", value: "PQ_QUE" },
		{ label: "G0V", value: "PQ_QUE" },
		{ label: "G0W", value: "PQ_QUE" },
		{ label: "G0X", value: "PQ_QUE" },
		{ label: "G0Y", value: "PQ_QUE" },
		{ label: "G0Z", value: "PQ_QUE" },
		{ label: "G1A", value: "PQ_QUE" },
		{ label: "G1B", value: "PQ_QUE" },
		{ label: "G1C", value: "PQ_QUE" },
		{ label: "G1E", value: "PQ_QUE" },
		{ label: "G1G", value: "PQ_QUE" },
		{ label: "G1H", value: "PQ_QUE" },
		{ label: "G1J", value: "PQ_QUE" },
		{ label: "G1K", value: "PQ_QUE" },
		{ label: "G1L", value: "PQ_QUE" },
		{ label: "G1M", value: "PQ_QUE" },
		{ label: "G1N", value: "PQ_QUE" },
		{ label: "G1P", value: "PQ_QUE" },
		{ label: "G1R", value: "PQ_QUE" },
		{ label: "G1S", value: "PQ_QUE" },
		{ label: "G1T", value: "PQ_QUE" },
		{ label: "G1V", value: "PQ_QUE" },
		{ label: "G1W", value: "PQ_QUE" },
		{ label: "G1X", value: "PQ_QUE" },
		{ label: "G1Y", value: "PQ_QUE" },
		{ label: "G2A", value: "PQ_QUE" },
		{ label: "G2B", value: "PQ_QUE" },
		{ label: "G2C", value: "PQ_QUE" },
		{ label: "G2E", value: "PQ_QUE" },
		{ label: "G2G", value: "PQ_QUE" },
		{ label: "G2J", value: "PQ_QUE" },
		{ label: "G2K", value: "PQ_QUE" },
		{ label: "G2L", value: "PQ_QUE" },
		{ label: "G2M", value: "PQ_QUE" },
		{ label: "G2N", value: "PQ_QUE" },
		{ label: "G3A", value: "PQ_QUE" },
		{ label: "G3E", value: "PQ_QUE" },
		{ label: "G3G", value: "PQ_QUE" },
		{ label: "G3H", value: "PQ_QUE" },
		{ label: "G3J", value: "PQ_QUE" },
		{ label: "G3K", value: "PQ_QUE" },
		{ label: "G3L", value: "PQ_QUE" },
		{ label: "G3M", value: "PQ_QUE" },
		{ label: "G3Z", value: "PQ_QUE" },
		{ label: "G4A", value: "PQ_QUE" },
		{ label: "G4R", value: "PQ_QUE" },
		{ label: "G4S", value: "PQ_QUE" },
		{ label: "G4T", value: "PQ_QUE" },
		{ label: "G4V", value: "PQ_QUE" },
		{ label: "G4W", value: "PQ_QUE" },
		{ label: "G4X", value: "PQ_QUE" },
		{ label: "G4Z", value: "PQ_QUE" },
		{ label: "G5A", value: "PQ_QUE" },
		{ label: "G5B", value: "PQ_QUE" },
		{ label: "G5C", value: "PQ_QUE" },
		{ label: "G5H", value: "PQ_QUE" },
		{ label: "G5J", value: "PQ_QUE" },
		{ label: "G5L", value: "PQ_QUE" },
		{ label: "G5M", value: "PQ_QUE" },
		{ label: "G5N", value: "PQ_QUE" },
		{ label: "G5R", value: "PQ_QUE" },
		{ label: "G5T", value: "PQ_QUE" },
		{ label: "G5V", value: "PQ_QUE" },
		{ label: "G5X", value: "PQ_QUE" },
		{ label: "G5Y", value: "PQ_QUE" },
		{ label: "G5Z", value: "PQ_QUE" },
		{ label: "G6A", value: "PQ_QUE" },
		{ label: "G6B", value: "PQ_QUE" },
		{ label: "G6C", value: "PQ_QUE" },
		{ label: "G6E", value: "PQ_QUE" },
		{ label: "G6G", value: "PQ_QUE" },
		{ label: "G6H", value: "PQ_QUE" },
		{ label: "G6J", value: "PQ_QUE" },
		{ label: "G6K", value: "PQ_QUE" },
		{ label: "G6L", value: "PQ_QUE" },
		{ label: "G6P", value: "PQ_QUE" },
		{ label: "G6R", value: "PQ_QUE" },
		{ label: "G6S", value: "PQ_QUE" },
		{ label: "G6T", value: "PQ_QUE" },
		{ label: "G6V", value: "PQ_QUE" },
		{ label: "G6W", value: "PQ_QUE" },
		{ label: "G6X", value: "PQ_QUE" },
		{ label: "G6Z", value: "PQ_QUE" },
		{ label: "G7A", value: "PQ_QUE" },
		{ label: "G7B", value: "PQ_QUE" },
		{ label: "G7G", value: "PQ_QUE" },
		{ label: "G7H", value: "PQ_QUE" },
		{ label: "G7J", value: "PQ_QUE" },
		{ label: "G7K", value: "PQ_QUE" },
		{ label: "G7N", value: "PQ_QUE" },
		{ label: "G7P", value: "PQ_QUE" },
		{ label: "G7S", value: "PQ_QUE" },
		{ label: "G7T", value: "PQ_QUE" },
		{ label: "G7X", value: "PQ_QUE" },
		{ label: "G7Y", value: "PQ_QUE" },
		{ label: "G7Z", value: "PQ_QUE" },
		{ label: "G8A", value: "PQ_QUE" },
		{ label: "G8B", value: "PQ_QUE" },
		{ label: "G8C", value: "PQ_QUE" },
		{ label: "G8E", value: "PQ_QUE" },
		{ label: "G8G", value: "PQ_QUE" },
		{ label: "G8H", value: "PQ_QUE" },
		{ label: "G8J", value: "PQ_QUE" },
		{ label: "G8K", value: "PQ_QUE" },
		{ label: "G8L", value: "PQ_QUE" },
		{ label: "G8M", value: "PQ_QUE" },
		{ label: "G8N", value: "PQ_QUE" },
		{ label: "G8P", value: "PQ_QUE" },
		{ label: "G8T", value: "PQ_QUE" },
		{ label: "G8V", value: "PQ_QUE" },
		{ label: "G8W", value: "PQ_QUE" },
		{ label: "G8Y", value: "PQ_QUE" },
		{ label: "G8Z", value: "PQ_QUE" },
		{ label: "G9A", value: "PQ_QUE" },
		{ label: "G9B", value: "PQ_QUE" },
		{ label: "G9C", value: "PQ_QUE" },
		{ label: "G9H", value: "PQ_QUE" },
		{ label: "G9N", value: "PQ_QUE" },
		{ label: "G9P", value: "PQ_QUE" },
		{ label: "G9R", value: "PQ_QUE" },
		{ label: "G9T", value: "PQ_QUE" },
		{ label: "G9X", value: "PQ_QUE" },
		{ label: "H0M", value: "PQ_MON" },
		{ label: "H1A", value: "PQ_MON" },
		{ label: "H1B", value: "PQ_MON" },
		{ label: "H1C", value: "PQ_MON" },
		{ label: "H1E", value: "PQ_MON" },
		{ label: "H1G", value: "PQ_MON" },
		{ label: "H1H", value: "PQ_MON" },
		{ label: "H1J", value: "PQ_MON" },
		{ label: "H1K", value: "PQ_MON" },
		{ label: "H1L", value: "PQ_MON" },
		{ label: "H1M", value: "PQ_MON" },
		{ label: "H1N", value: "PQ_MON" },
		{ label: "H1P", value: "PQ_MON" },
		{ label: "H1R", value: "PQ_MON" },
		{ label: "H1S", value: "PQ_MON" },
		{ label: "H1T", value: "PQ_MON" },
		{ label: "H1V", value: "PQ_MON" },
		{ label: "H1W", value: "PQ_MON" },
		{ label: "H1X", value: "PQ_MON" },
		{ label: "H1Y", value: "PQ_MON" },
		{ label: "H1Z", value: "PQ_MON" },
		{ label: "H2A", value: "PQ_MON" },
		{ label: "H2B", value: "PQ_MON" },
		{ label: "H2C", value: "PQ_MON" },
		{ label: "H2E", value: "PQ_MON" },
		{ label: "H2G", value: "PQ_MON" },
		{ label: "H2H", value: "PQ_MON" },
		{ label: "H2J", value: "PQ_MON" },
		{ label: "H2K", value: "PQ_MON" },
		{ label: "H2L", value: "PQ_MON" },
		{ label: "H2M", value: "PQ_MON" },
		{ label: "H2N", value: "PQ_MON" },
		{ label: "H2P", value: "PQ_MON" },
		{ label: "H2R", value: "PQ_MON" },
		{ label: "H2S", value: "PQ_MON" },
		{ label: "H2T", value: "PQ_MON" },
		{ label: "H2V", value: "PQ_MON" },
		{ label: "H2W", value: "PQ_MON" },
		{ label: "H2X", value: "PQ_MON" },
		{ label: "H2Y", value: "PQ_MON" },
		{ label: "H2Z", value: "PQ_MON" },
		{ label: "H3A", value: "PQ_MON" },
		{ label: "H3B", value: "PQ_MON" },
		{ label: "H3C", value: "PQ_MON" },
		{ label: "H3E", value: "PQ_MON" },
		{ label: "H3G", value: "PQ_MON" },
		{ label: "H3H", value: "PQ_MON" },
		{ label: "H3J", value: "PQ_MON" },
		{ label: "H3K", value: "PQ_MON" },
		{ label: "H3L", value: "PQ_MON" },
		{ label: "H3M", value: "PQ_MON" },
		{ label: "H3N", value: "PQ_MON" },
		{ label: "H3P", value: "PQ_MON" },
		{ label: "H3R", value: "PQ_MON" },
		{ label: "H3S", value: "PQ_MON" },
		{ label: "H3T", value: "PQ_MON" },
		{ label: "H3V", value: "PQ_MON" },
		{ label: "H3W", value: "PQ_MON" },
		{ label: "H3X", value: "PQ_MON" },
		{ label: "H3Y", value: "PQ_MON" },
		{ label: "H3Z", value: "PQ_MON" },
		{ label: "H4A", value: "PQ_MON" },
		{ label: "H4B", value: "PQ_MON" },
		{ label: "H4C", value: "PQ_MON" },
		{ label: "H4E", value: "PQ_MON" },
		{ label: "H4G", value: "PQ_MON" },
		{ label: "H4H", value: "PQ_MON" },
		{ label: "H4J", value: "PQ_MON" },
		{ label: "H4K", value: "PQ_MON" },
		{ label: "H4L", value: "PQ_MON" },
		{ label: "H4M", value: "PQ_MON" },
		{ label: "H4N", value: "PQ_MON" },
		{ label: "H4P", value: "PQ_MON" },
		{ label: "H4R", value: "PQ_MON" },
		{ label: "H4S", value: "PQ_MON" },
		{ label: "H4T", value: "PQ_MON" },
		{ label: "H4V", value: "PQ_MON" },
		{ label: "H4W", value: "PQ_MON" },
		{ label: "H4X", value: "PQ_MON" },
		{ label: "H4Y", value: "PQ_MON" },
		{ label: "H4Z", value: "PQ_MON" },
		{ label: "H5A", value: "PQ_MON" },
		{ label: "H5B", value: "PQ_MON" },
		{ label: "H7A", value: "PQ_MON" },
		{ label: "H7B", value: "PQ_MON" },
		{ label: "H7C", value: "PQ_MON" },
		{ label: "H7E", value: "PQ_MON" },
		{ label: "H7G", value: "PQ_MON" },
		{ label: "H7H", value: "PQ_MON" },
		{ label: "H7J", value: "PQ_MON" },
		{ label: "H7K", value: "PQ_MON" },
		{ label: "H7L", value: "PQ_MON" },
		{ label: "H7M", value: "PQ_MON" },
		{ label: "H7N", value: "PQ_MON" },
		{ label: "H7P", value: "PQ_MON" },
		{ label: "H7R", value: "PQ_MON" },
		{ label: "H7S", value: "PQ_MON" },
		{ label: "H7T", value: "PQ_MON" },
		{ label: "H7V", value: "PQ_MON" },
		{ label: "H7W", value: "PQ_MON" },
		{ label: "H7X", value: "PQ_MON" },
		{ label: "H7Y", value: "PQ_MON" },
		{ label: "H8N", value: "PQ_MON" },
		{ label: "H8P", value: "PQ_MON" },
		{ label: "H8R", value: "PQ_MON" },
		{ label: "H8S", value: "PQ_MON" },
		{ label: "H8T", value: "PQ_MON" },
		{ label: "H8Y", value: "PQ_MON" },
		{ label: "H8Z", value: "PQ_MON" },
		{ label: "H9A", value: "PQ_MON" },
		{ label: "H9B", value: "PQ_MON" },
		{ label: "H9C", value: "PQ_MON" },
		{ label: "H9E", value: "PQ_MON" },
		{ label: "H9G", value: "PQ_MON" },
		{ label: "H9H", value: "PQ_MON" },
		{ label: "H9J", value: "PQ_MON" },
		{ label: "H9K", value: "PQ_MON" },
		{ label: "H9P", value: "PQ_MON" },
		{ label: "H9R", value: "PQ_MON" },
		{ label: "H9S", value: "PQ_MON" },
		{ label: "H9W", value: "PQ_MON" },
		{ label: "H9X", value: "PQ_MON" },
		{ label: "J0A", value: "PQ_MON" },
		{ label: "J0B", value: "PQ_MON" },
		{ label: "J0C", value: "PQ_MON" },
		{ label: "J0E", value: "PQ_MON" },
		{ label: "J0G", value: "PQ_MON" },
		{ label: "J0H", value: "PQ_MON" },
		{ label: "J0J", value: "PQ_MON" },
		{ label: "J0K", value: "PQ_MON" },
		{ label: "J0L", value: "PQ_MON" },
		{ label: "J0M", value: "PQ_MON" },
		{ label: "J0N", value: "PQ_MON" },
		{ label: "J0P", value: "PQ_MON" },
		{ label: "J0R", value: "PQ_MON" },
		{ label: "J0S", value: "PQ_MON" },
		{ label: "J0T", value: "PQ_MON" },
		{ label: "J0V", value: "PQ_MON" },
		{ label: "J0W", value: "PQ_MON" },
		{ label: "J0X", value: "PQ_MON" },
		{ label: "J0Y", value: "PQ_MON" },
		{ label: "J0Z", value: "PQ_MON" },
		{ label: "J1A", value: "PQ_MON" },
		{ label: "J1E", value: "PQ_MON" },
		{ label: "J1G", value: "PQ_MON" },
		{ label: "J1H", value: "PQ_MON" },
		{ label: "J1J", value: "PQ_MON" },
		{ label: "J1K", value: "PQ_MON" },
		{ label: "J1L", value: "PQ_MON" },
		{ label: "J1M", value: "PQ_MON" },
		{ label: "J1N", value: "PQ_MON" },
		{ label: "J1S", value: "PQ_MON" },
		{ label: "J1T", value: "PQ_MON" },
		{ label: "J1X", value: "PQ_MON" },
		{ label: "J1Z", value: "PQ_MON" },
		{ label: "J2A", value: "PQ_MON" },
		{ label: "J2B", value: "PQ_MON" },
		{ label: "J2C", value: "PQ_MON" },
		{ label: "J2E", value: "PQ_MON" },
		{ label: "J2G", value: "PQ_MON" },
		{ label: "J2H", value: "PQ_MON" },
		{ label: "J2J", value: "PQ_MON" },
		{ label: "J2K", value: "PQ_MON" },
		{ label: "J2L", value: "PQ_MON" },
		{ label: "J2N", value: "PQ_MON" },
		{ label: "J2R", value: "PQ_MON" },
		{ label: "J2S", value: "PQ_MON" },
		{ label: "J2T", value: "PQ_MON" },
		{ label: "J2W", value: "PQ_MON" },
		{ label: "J2X", value: "PQ_MON" },
		{ label: "J2Y", value: "PQ_MON" },
		{ label: "J3A", value: "PQ_MON" },
		{ label: "J3B", value: "PQ_MON" },
		{ label: "J3E", value: "PQ_MON" },
		{ label: "J3G", value: "PQ_MON" },
		{ label: "J3H", value: "PQ_MON" },
		{ label: "J3L", value: "PQ_MON" },
		{ label: "J3M", value: "PQ_MON" },
		{ label: "J3N", value: "PQ_MON" },
		{ label: "J3P", value: "PQ_MON" },
		{ label: "J3R", value: "PQ_MON" },
		{ label: "J3T", value: "PQ_MON" },
		{ label: "J3V", value: "PQ_MON" },
		{ label: "J3X", value: "PQ_MON" },
		{ label: "J3Y", value: "PQ_MON" },
		{ label: "J3Z", value: "PQ_MON" },
		{ label: "J4B", value: "PQ_MON" },
		{ label: "J4G", value: "PQ_MON" },
		{ label: "J4H", value: "PQ_MON" },
		{ label: "J4J", value: "PQ_MON" },
		{ label: "J4K", value: "PQ_MON" },
		{ label: "J4L", value: "PQ_MON" },
		{ label: "J4M", value: "PQ_MON" },
		{ label: "J4N", value: "PQ_MON" },
		{ label: "J4P", value: "PQ_MON" },
		{ label: "J4R", value: "PQ_MON" },
		{ label: "J4S", value: "PQ_MON" },
		{ label: "J4T", value: "PQ_MON" },
		{ label: "J4V", value: "PQ_MON" },
		{ label: "J4W", value: "PQ_MON" },
		{ label: "J4X", value: "PQ_MON" },
		{ label: "J4Y", value: "PQ_MON" },
		{ label: "J4Z", value: "PQ_MON" },
		{ label: "J5A", value: "PQ_MON" },
		{ label: "J5B", value: "PQ_MON" },
		{ label: "J5J", value: "PQ_MON" },
		{ label: "J5K", value: "PQ_MON" },
		{ label: "J5L", value: "PQ_MON" },
		{ label: "J5M", value: "PQ_MON" },
		{ label: "J5R", value: "PQ_MON" },
		{ label: "J5T", value: "PQ_MON" },
		{ label: "J5V", value: "PQ_MON" },
		{ label: "J5W", value: "PQ_MON" },
		{ label: "J5X", value: "PQ_MON" },
		{ label: "J5Y", value: "PQ_MON" },
		{ label: "J5Z", value: "PQ_MON" },
		{ label: "J6A", value: "PQ_MON" },
		{ label: "J6E", value: "PQ_MON" },
		{ label: "J6J", value: "PQ_MON" },
		{ label: "J6K", value: "PQ_MON" },
		{ label: "J6N", value: "PQ_MON" },
		{ label: "J6R", value: "PQ_MON" },
		{ label: "J6S", value: "PQ_MON" },
		{ label: "J6T", value: "PQ_MON" },
		{ label: "J6V", value: "PQ_MON" },
		{ label: "J6W", value: "PQ_MON" },
		{ label: "J6X", value: "PQ_MON" },
		{ label: "J6Y", value: "PQ_MON" },
		{ label: "J6Z", value: "PQ_MON" },
		{ label: "J7A", value: "PQ_MON" },
		{ label: "J7B", value: "PQ_MON" },
		{ label: "J7C", value: "PQ_MON" },
		{ label: "J7E", value: "PQ_MON" },
		{ label: "J7G", value: "PQ_MON" },
		{ label: "J7H", value: "PQ_MON" },
		{ label: "J7J", value: "PQ_MON" },
		{ label: "J7K", value: "PQ_MON" },
		{ label: "J7L", value: "PQ_MON" },
		{ label: "J7M", value: "PQ_MON" },
		{ label: "J7N", value: "PQ_MON" },
		{ label: "J7P", value: "PQ_MON" },
		{ label: "J7R", value: "PQ_MON" },
		{ label: "J7T", value: "PQ_MON" },
		{ label: "J7V", value: "PQ_MON" },
		{ label: "J7X", value: "PQ_MON" },
		{ label: "J7Y", value: "PQ_MON" },
		{ label: "J7Z", value: "PQ_MON" },
		{ label: "J8A", value: "PQ_MON" },
		{ label: "J8B", value: "PQ_MON" },
		{ label: "J8C", value: "PQ_MON" },
		{ label: "J8E", value: "PQ_MON" },
		{ label: "J8G", value: "PQ_MON" },
		{ label: "J8H", value: "PQ_MON" },
		{ label: "J8L", value: "PQ_MON" },
		{ label: "J8M", value: "PQ_MON" },
		{ label: "J8N", value: "PQ_MON" },
		{ label: "J8P", value: "PQ_MON" },
		{ label: "J8R", value: "PQ_MON" },
		{ label: "J8T", value: "PQ_MON" },
		{ label: "J8V", value: "PQ_MON" },
		{ label: "J8X", value: "PQ_MON" },
		{ label: "J8Y", value: "PQ_MON" },
		{ label: "J8Z", value: "PQ_MON" },
		{ label: "J9A", value: "PQ_MON" },
		{ label: "J9B", value: "PQ_MON" },
		{ label: "J9E", value: "PQ_MON" },
		{ label: "J9H", value: "PQ_MON" },
		{ label: "J9J", value: "PQ_MON" },
		{ label: "J9L", value: "PQ_MON" },
		{ label: "J9P", value: "PQ_MON" },
		{ label: "J9T", value: "PQ_MON" },
		{ label: "J9V", value: "PQ_MON" },
		{ label: "J9X", value: "PQ_MON" },
		{ label: "J9Y", value: "PQ_MON" },
		{ label: "J9Z", value: "PQ_MON" },
		{ label: "K0A", value: "ON_OTT" },
		{ label: "K0B", value: "ON_OTT" },
		{ label: "K0C", value: "ON_OTT" },
		{ label: "K0E", value: "ON_OTT" },
		{ label: "K0G", value: "ON_OTT" },
		{ label: "K0H", value: "ON_OTT" },
		{ label: "K0J", value: "ON_OTT" },
		{ label: "K0K", value: "ON_OTT" },
		{ label: "K0L", value: "ON_OTT" },
		{ label: "K0M", value: "ON_OTT" },
		{ label: "K1A", value: "ON_OTT" },
		{ label: "K1B", value: "ON_OTT" },
		{ label: "K1C", value: "ON_OTT" },
		{ label: "K1E", value: "ON_OTT" },
		{ label: "K1G", value: "ON_OTT" },
		{ label: "K1H", value: "ON_OTT" },
		{ label: "K1J", value: "ON_OTT" },
		{ label: "K1K", value: "ON_OTT" },
		{ label: "K1L", value: "ON_OTT" },
		{ label: "K1M", value: "ON_OTT" },
		{ label: "K1N", value: "ON_OTT" },
		{ label: "K1P", value: "ON_OTT" },
		{ label: "K1R", value: "ON_OTT" },
		{ label: "K1S", value: "ON_OTT" },
		{ label: "K1T", value: "ON_OTT" },
		{ label: "K1V", value: "ON_OTT" },
		{ label: "K1W", value: "ON_OTT" },
		{ label: "K1X", value: "ON_OTT" },
		{ label: "K1Y", value: "ON_OTT" },
		{ label: "K1Z", value: "ON_OTT" },
		{ label: "K2A", value: "ON_OTT" },
		{ label: "K2B", value: "ON_OTT" },
		{ label: "K2C", value: "ON_OTT" },
		{ label: "K2E", value: "ON_OTT" },
		{ label: "K2G", value: "ON_OTT" },
		{ label: "K2H", value: "ON_OTT" },
		{ label: "K2J", value: "ON_OTT" },
		{ label: "K2K", value: "ON_OTT" },
		{ label: "K2L", value: "ON_OTT" },
		{ label: "K2M", value: "ON_OTT" },
		{ label: "K2P", value: "ON_OTT" },
		{ label: "K2R", value: "ON_OTT" },
		{ label: "K2S", value: "ON_OTT" },
		{ label: "K2T", value: "ON_OTT" },
		{ label: "K2V", value: "ON_OTT" },
		{ label: "K2W", value: "ON_OTT" },
		{ label: "K4A", value: "ON_OTT" },
		{ label: "K4B", value: "ON_OTT" },
		{ label: "K4C", value: "ON_OTT" },
		{ label: "K4K", value: "ON_OTT" },
		{ label: "K4M", value: "ON_OTT" },
		{ label: "K4P", value: "ON_OTT" },
		{ label: "K4R", value: "ON_OTT" },
		{ label: "K6A", value: "ON_OTT" },
		{ label: "K6H", value: "ON_OTT" },
		{ label: "K6J", value: "ON_OTT" },
		{ label: "K6K", value: "ON_OTT" },
		{ label: "K6T", value: "ON_OTT" },
		{ label: "K6V", value: "ON_OTT" },
		{ label: "K7A", value: "ON_OTT" },
		{ label: "K7C", value: "ON_OTT" },
		{ label: "K7G", value: "ON_OTT" },
		{ label: "K7H", value: "ON_OTT" },
		{ label: "K7K", value: "ON_OTT" },
		{ label: "K7L", value: "ON_OTT" },
		{ label: "K7M", value: "ON_OTT" },
		{ label: "K7N", value: "ON_OTT" },
		{ label: "K7P", value: "ON_OTT" },
		{ label: "K7R", value: "ON_OTT" },
		{ label: "K7S", value: "ON_OTT" },
		{ label: "K7V", value: "ON_OTT" },
		{ label: "K8A", value: "ON_OTT" },
		{ label: "K8B", value: "ON_OTT" },
		{ label: "K8H", value: "ON_OTT" },
		{ label: "K8N", value: "ON_OTT" },
		{ label: "K8P", value: "ON_OTT" },
		{ label: "K8R", value: "ON_OTT" },
		{ label: "K8V", value: "ON_OTT" },
		{ label: "K9A", value: "ON_OTT" },
		{ label: "K9H", value: "ON_OTT" },
		{ label: "K9J", value: "ON_OTT" },
		{ label: "K9K", value: "ON_OTT" },
		{ label: "K9L", value: "ON_OTT" },
		{ label: "K9V", value: "ON_OTT" },
		{ label: "L0A", value: "ON_TOR" },
		{ label: "L0B", value: "ON_TOR" },
		{ label: "L0C", value: "ON_TOR" },
		{ label: "L0E", value: "ON_TOR" },
		{ label: "L0G", value: "ON_TOR" },
		{ label: "L0H", value: "ON_TOR" },
		{ label: "L0J", value: "ON_TOR" },
		{ label: "L0K", value: "ON_TOR" },
		{ label: "L0L", value: "ON_TOR" },
		{ label: "L0M", value: "ON_TOR" },
		{ label: "L0N", value: "ON_TOR" },
		{ label: "L0P", value: "ON_TOR" },
		{ label: "L0R", value: "ON_TOR" },
		{ label: "L0S", value: "ON_TOR" },
		{ label: "L1A", value: "ON_TOR" },
		{ label: "L1B", value: "ON_TOR" },
		{ label: "L1C", value: "ON_TOR" },
		{ label: "L1E", value: "ON_TOR" },
		{ label: "L1G", value: "ON_TOR" },
		{ label: "L1H", value: "ON_TOR" },
		{ label: "L1J", value: "ON_TOR" },
		{ label: "L1K", value: "ON_TOR" },
		{ label: "L1L", value: "ON_TOR" },
		{ label: "L1M", value: "ON_TOR" },
		{ label: "L1N", value: "ON_TOR" },
		{ label: "L1P", value: "ON_TOR" },
		{ label: "L1R", value: "ON_TOR" },
		{ label: "L1S", value: "ON_TOR" },
		{ label: "L1T", value: "ON_TOR" },
		{ label: "L1V", value: "ON_TOR" },
		{ label: "L1W", value: "ON_TOR" },
		{ label: "L1X", value: "ON_TOR" },
		{ label: "L1Y", value: "ON_TOR" },
		{ label: "L1Z", value: "ON_TOR" },
		{ label: "L2A", value: "ON_TOR" },
		{ label: "L2E", value: "ON_TOR" },
		{ label: "L2G", value: "ON_TOR" },
		{ label: "L2H", value: "ON_TOR" },
		{ label: "L2J", value: "ON_TOR" },
		{ label: "L2M", value: "ON_TOR" },
		{ label: "L2N", value: "ON_TOR" },
		{ label: "L2P", value: "ON_TOR" },
		{ label: "L2R", value: "ON_TOR" },
		{ label: "L2S", value: "ON_TOR" },
		{ label: "L2T", value: "ON_TOR" },
		{ label: "L2V", value: "ON_TOR" },
		{ label: "L2W", value: "ON_TOR" },
		{ label: "L3B", value: "ON_TOR" },
		{ label: "L3C", value: "ON_TOR" },
		{ label: "L3K", value: "ON_TOR" },
		{ label: "L3M", value: "ON_TOR" },
		{ label: "L3P", value: "ON_TOR" },
		{ label: "L3R", value: "ON_TOR" },
		{ label: "L3S", value: "ON_TOR" },
		{ label: "L3T", value: "ON_TOR" },
		{ label: "L3V", value: "ON_TOR" },
		{ label: "L3X", value: "ON_TOR" },
		{ label: "L3Y", value: "ON_TOR" },
		{ label: "L3Z", value: "ON_TOR" },
		{ label: "L4A", value: "ON_TOR" },
		{ label: "L4B", value: "ON_TOR" },
		{ label: "L4C", value: "ON_TOR" },
		{ label: "L4E", value: "ON_TOR" },
		{ label: "L4G", value: "ON_TOR" },
		{ label: "L4H", value: "ON_TOR" },
		{ label: "L4J", value: "ON_TOR" },
		{ label: "L4K", value: "ON_TOR" },
		{ label: "L4L", value: "ON_TOR" },
		{ label: "L4M", value: "ON_TOR" },
		{ label: "L4N", value: "ON_TOR" },
		{ label: "L4P", value: "ON_TOR" },
		{ label: "L4R", value: "ON_TOR" },
		{ label: "L4S", value: "ON_TOR" },
		{ label: "L4T", value: "ON_TOR" },
		{ label: "L4V", value: "ON_TOR" },
		{ label: "L4W", value: "ON_TOR" },
		{ label: "L4X", value: "ON_TOR" },
		{ label: "L4Y", value: "ON_TOR" },
		{ label: "L4Z", value: "ON_TOR" },
		{ label: "L5A", value: "ON_TOR" },
		{ label: "L5B", value: "ON_TOR" },
		{ label: "L5C", value: "ON_TOR" },
		{ label: "L5E", value: "ON_TOR" },
		{ label: "L5G", value: "ON_TOR" },
		{ label: "L5H", value: "ON_TOR" },
		{ label: "L5J", value: "ON_TOR" },
		{ label: "L5K", value: "ON_TOR" },
		{ label: "L5L", value: "ON_TOR" },
		{ label: "L5M", value: "ON_TOR" },
		{ label: "L5N", value: "ON_TOR" },
		{ label: "L5P", value: "ON_TOR" },
		{ label: "L5R", value: "ON_TOR" },
		{ label: "L5S", value: "ON_TOR" },
		{ label: "L5T", value: "ON_TOR" },
		{ label: "L5V", value: "ON_TOR" },
		{ label: "L5W", value: "ON_TOR" },
		{ label: "L6A", value: "ON_TOR" },
		{ label: "L6B", value: "ON_TOR" },
		{ label: "L6C", value: "ON_TOR" },
		{ label: "L6E", value: "ON_TOR" },
		{ label: "L6G", value: "ON_TOR" },
		{ label: "L6H", value: "ON_TOR" },
		{ label: "L6J", value: "ON_TOR" },
		{ label: "L6K", value: "ON_TOR" },
		{ label: "L6L", value: "ON_TOR" },
		{ label: "L6M", value: "ON_TOR" },
		{ label: "L6P", value: "ON_TOR" },
		{ label: "L6R", value: "ON_TOR" },
		{ label: "L6S", value: "ON_TOR" },
		{ label: "L6T", value: "ON_TOR" },
		{ label: "L6V", value: "ON_TOR" },
		{ label: "L6W", value: "ON_TOR" },
		{ label: "L6X", value: "ON_TOR" },
		{ label: "L6Y", value: "ON_TOR" },
		{ label: "L6Z", value: "ON_TOR" },
		{ label: "L7A", value: "ON_TOR" },
		{ label: "L7B", value: "ON_TOR" },
		{ label: "L7C", value: "ON_TOR" },
		{ label: "L7E", value: "ON_TOR" },
		{ label: "L7G", value: "ON_TOR" },
		{ label: "L7J", value: "ON_TOR" },
		{ label: "L7L", value: "ON_TOR" },
		{ label: "L7M", value: "ON_TOR" },
		{ label: "L7N", value: "ON_TOR" },
		{ label: "L7P", value: "ON_TOR" },
		{ label: "L7R", value: "ON_TOR" },
		{ label: "L7S", value: "ON_TOR" },
		{ label: "L7T", value: "ON_TOR" },
		{ label: "L8E", value: "ON_TOR" },
		{ label: "L8G", value: "ON_TOR" },
		{ label: "L8H", value: "ON_TOR" },
		{ label: "L8J", value: "ON_TOR" },
		{ label: "L8K", value: "ON_TOR" },
		{ label: "L8L", value: "ON_TOR" },
		{ label: "L8M", value: "ON_TOR" },
		{ label: "L8N", value: "ON_TOR" },
		{ label: "L8P", value: "ON_TOR" },
		{ label: "L8R", value: "ON_TOR" },
		{ label: "L8S", value: "ON_TOR" },
		{ label: "L8T", value: "ON_TOR" },
		{ label: "L8V", value: "ON_TOR" },
		{ label: "L8W", value: "ON_TOR" },
		{ label: "L9A", value: "ON_TOR" },
		{ label: "L9B", value: "ON_TOR" },
		{ label: "L9C", value: "ON_TOR" },
		{ label: "L9G", value: "ON_TOR" },
		{ label: "L9H", value: "ON_TOR" },
		{ label: "L9K", value: "ON_TOR" },
		{ label: "L9L", value: "ON_TOR" },
		{ label: "L9M", value: "ON_TOR" },
		{ label: "L9N", value: "ON_TOR" },
		{ label: "L9P", value: "ON_TOR" },
		{ label: "L9R", value: "ON_TOR" },
		{ label: "L9S", value: "ON_TOR" },
		{ label: "L9T", value: "ON_TOR" },
		{ label: "L9V", value: "ON_TOR" },
		{ label: "L9W", value: "ON_TOR" },
		{ label: "L9Y", value: "ON_TOR" },
		{ label: "M1B", value: "ON_TOR" },
		{ label: "M1C", value: "ON_TOR" },
		{ label: "M1E", value: "ON_TOR" },
		{ label: "M1G", value: "ON_TOR" },
		{ label: "M1H", value: "ON_TOR" },
		{ label: "M1J", value: "ON_TOR" },
		{ label: "M1K", value: "ON_TOR" },
		{ label: "M1L", value: "ON_TOR" },
		{ label: "M1M", value: "ON_TOR" },
		{ label: "M1N", value: "ON_TOR" },
		{ label: "M1P", value: "ON_TOR" },
		{ label: "M1R", value: "ON_TOR" },
		{ label: "M1S", value: "ON_TOR" },
		{ label: "M1T", value: "ON_TOR" },
		{ label: "M1V", value: "ON_TOR" },
		{ label: "M1W", value: "ON_TOR" },
		{ label: "M1X", value: "ON_TOR" },
		{ label: "M2H", value: "ON_TOR" },
		{ label: "M2J", value: "ON_TOR" },
		{ label: "M2K", value: "ON_TOR" },
		{ label: "M2L", value: "ON_TOR" },
		{ label: "M2M", value: "ON_TOR" },
		{ label: "M2N", value: "ON_TOR" },
		{ label: "M2P", value: "ON_TOR" },
		{ label: "M2R", value: "ON_TOR" },
		{ label: "M3A", value: "ON_TOR" },
		{ label: "M3B", value: "ON_TOR" },
		{ label: "M3C", value: "ON_TOR" },
		{ label: "M3H", value: "ON_TOR" },
		{ label: "M3J", value: "ON_TOR" },
		{ label: "M3K", value: "ON_TOR" },
		{ label: "M3L", value: "ON_TOR" },
		{ label: "M3M", value: "ON_TOR" },
		{ label: "M3N", value: "ON_TOR" },
		{ label: "M4A", value: "ON_TOR" },
		{ label: "M4B", value: "ON_TOR" },
		{ label: "M4C", value: "ON_TOR" },
		{ label: "M4E", value: "ON_TOR" },
		{ label: "M4G", value: "ON_TOR" },
		{ label: "M4H", value: "ON_TOR" },
		{ label: "M4J", value: "ON_TOR" },
		{ label: "M4K", value: "ON_TOR" },
		{ label: "M4L", value: "ON_TOR" },
		{ label: "M4M", value: "ON_TOR" },
		{ label: "M4N", value: "ON_TOR" },
		{ label: "M4P", value: "ON_TOR" },
		{ label: "M4R", value: "ON_TOR" },
		{ label: "M4S", value: "ON_TOR" },
		{ label: "M4T", value: "ON_TOR" },
		{ label: "M4V", value: "ON_TOR" },
		{ label: "M4W", value: "ON_TOR" },
		{ label: "M4X", value: "ON_TOR" },
		{ label: "M4Y", value: "ON_TOR" },
		{ label: "M5A", value: "ON_TOR" },
		{ label: "M5B", value: "ON_TOR" },
		{ label: "M5C", value: "ON_TOR" },
		{ label: "M5E", value: "ON_TOR" },
		{ label: "M5G", value: "ON_TOR" },
		{ label: "M5H", value: "ON_TOR" },
		{ label: "M5J", value: "ON_TOR" },
		{ label: "M5K", value: "ON_TOR" },
		{ label: "M5L", value: "ON_TOR" },
		{ label: "M5M", value: "ON_TOR" },
		{ label: "M5N", value: "ON_TOR" },
		{ label: "M5P", value: "ON_TOR" },
		{ label: "M5R", value: "ON_TOR" },
		{ label: "M5S", value: "ON_TOR" },
		{ label: "M5T", value: "ON_TOR" },
		{ label: "M5V", value: "ON_TOR" },
		{ label: "M5W", value: "ON_TOR" },
		{ label: "M5X", value: "ON_TOR" },
		{ label: "M6A", value: "ON_TOR" },
		{ label: "M6B", value: "ON_TOR" },
		{ label: "M6C", value: "ON_TOR" },
		{ label: "M6E", value: "ON_TOR" },
		{ label: "M6G", value: "ON_TOR" },
		{ label: "M6H", value: "ON_TOR" },
		{ label: "M6J", value: "ON_TOR" },
		{ label: "M6K", value: "ON_TOR" },
		{ label: "M6L", value: "ON_TOR" },
		{ label: "M6M", value: "ON_TOR" },
		{ label: "M6N", value: "ON_TOR" },
		{ label: "M6P", value: "ON_TOR" },
		{ label: "M6R", value: "ON_TOR" },
		{ label: "M6S", value: "ON_TOR" },
		{ label: "M7A", value: "ON_TOR" },
		{ label: "M7Y", value: "ON_TOR" },
		{ label: "M8V", value: "ON_TOR" },
		{ label: "M8W", value: "ON_TOR" },
		{ label: "M8X", value: "ON_TOR" },
		{ label: "M8Y", value: "ON_TOR" },
		{ label: "M8Z", value: "ON_TOR" },
		{ label: "M9A", value: "ON_TOR" },
		{ label: "M9B", value: "ON_TOR" },
		{ label: "M9C", value: "ON_TOR" },
		{ label: "M9L", value: "ON_TOR" },
		{ label: "M9M", value: "ON_TOR" },
		{ label: "M9N", value: "ON_TOR" },
		{ label: "M9P", value: "ON_TOR" },
		{ label: "M9R", value: "ON_TOR" },
		{ label: "M9V", value: "ON_TOR" },
		{ label: "M9W", value: "ON_TOR" },
		{ label: "N0A", value: "ON_LON" },
		{ label: "N0B", value: "ON_LON" },
		{ label: "N0C", value: "ON_LON" },
		{ label: "N0E", value: "ON_LON" },
		{ label: "N0G", value: "ON_LON" },
		{ label: "N0H", value: "ON_LON" },
		{ label: "N0J", value: "ON_LON" },
		{ label: "N0K", value: "ON_LON" },
		{ label: "N0L", value: "ON_LON" },
		{ label: "N0M", value: "ON_LON" },
		{ label: "N0N", value: "ON_LON" },
		{ label: "N0P", value: "ON_LON" },
		{ label: "N0R", value: "ON_LON" },
		{ label: "N1A", value: "ON_LON" },
		{ label: "N1C", value: "ON_LON" },
		{ label: "N1E", value: "ON_LON" },
		{ label: "N1G", value: "ON_LON" },
		{ label: "N1H", value: "ON_LON" },
		{ label: "N1K", value: "ON_LON" },
		{ label: "N1L", value: "ON_LON" },
		{ label: "N1M", value: "ON_LON" },
		{ label: "N1P", value: "ON_LON" },
		{ label: "N1R", value: "ON_LON" },
		{ label: "N1S", value: "ON_LON" },
		{ label: "N1T", value: "ON_LON" },
		{ label: "N2A", value: "ON_LON" },
		{ label: "N2B", value: "ON_LON" },
		{ label: "N2C", value: "ON_LON" },
		{ label: "N2E", value: "ON_LON" },
		{ label: "N2G", value: "ON_LON" },
		{ label: "N2H", value: "ON_LON" },
		{ label: "N2J", value: "ON_LON" },
		{ label: "N2K", value: "ON_LON" },
		{ label: "N2L", value: "ON_LON" },
		{ label: "N2M", value: "ON_LON" },
		{ label: "N2N", value: "ON_LON" },
		{ label: "N2P", value: "ON_LON" },
		{ label: "N2R", value: "ON_LON" },
		{ label: "N2T", value: "ON_LON" },
		{ label: "N2V", value: "ON_LON" },
		{ label: "N2Z", value: "ON_LON" },
		{ label: "N3A", value: "ON_LON" },
		{ label: "N3B", value: "ON_LON" },
		{ label: "N3C", value: "ON_LON" },
		{ label: "N3E", value: "ON_LON" },
		{ label: "N3H", value: "ON_LON" },
		{ label: "N3L", value: "ON_LON" },
		{ label: "N3P", value: "ON_LON" },
		{ label: "N3R", value: "ON_LON" },
		{ label: "N3S", value: "ON_LON" },
		{ label: "N3T", value: "ON_LON" },
		{ label: "N3V", value: "ON_LON" },
		{ label: "N3W", value: "ON_LON" },
		{ label: "N3Y", value: "ON_LON" },
		{ label: "N4B", value: "ON_LON" },
		{ label: "N4G", value: "ON_LON" },
		{ label: "N4K", value: "ON_LON" },
		{ label: "N4L", value: "ON_LON" },
		{ label: "N4N", value: "ON_LON" },
		{ label: "N4S", value: "ON_LON" },
		{ label: "N4T", value: "ON_LON" },
		{ label: "N4V", value: "ON_LON" },
		{ label: "N4W", value: "ON_LON" },
		{ label: "N4X", value: "ON_LON" },
		{ label: "N4Z", value: "ON_LON" },
		{ label: "N5A", value: "ON_LON" },
		{ label: "N5C", value: "ON_LON" },
		{ label: "N5H", value: "ON_LON" },
		{ label: "N5L", value: "ON_LON" },
		{ label: "N5P", value: "ON_LON" },
		{ label: "N5R", value: "ON_LON" },
		{ label: "N5V", value: "ON_LON" },
		{ label: "N5W", value: "ON_LON" },
		{ label: "N5X", value: "ON_LON" },
		{ label: "N5Y", value: "ON_LON" },
		{ label: "N5Z", value: "ON_LON" },
		{ label: "N6A", value: "ON_LON" },
		{ label: "N6B", value: "ON_LON" },
		{ label: "N6C", value: "ON_LON" },
		{ label: "N6E", value: "ON_LON" },
		{ label: "N6G", value: "ON_LON" },
		{ label: "N6H", value: "ON_LON" },
		{ label: "N6J", value: "ON_LON" },
		{ label: "N6K", value: "ON_LON" },
		{ label: "N6L", value: "ON_LON" },
		{ label: "N6M", value: "ON_LON" },
		{ label: "N6N", value: "ON_LON" },
		{ label: "N6P", value: "ON_LON" },
		{ label: "N7A", value: "ON_LON" },
		{ label: "N7G", value: "ON_LON" },
		{ label: "N7L", value: "ON_LON" },
		{ label: "N7M", value: "ON_LON" },
		{ label: "N7S", value: "ON_LON" },
		{ label: "N7T", value: "ON_LON" },
		{ label: "N7V", value: "ON_LON" },
		{ label: "N7W", value: "ON_LON" },
		{ label: "N7X", value: "ON_LON" },
		{ label: "N8A", value: "ON_LON" },
		{ label: "N8H", value: "ON_LON" },
		{ label: "N8M", value: "ON_LON" },
		{ label: "N8N", value: "ON_LON" },
		{ label: "N8P", value: "ON_LON" },
		{ label: "N8R", value: "ON_LON" },
		{ label: "N8S", value: "ON_LON" },
		{ label: "N8T", value: "ON_LON" },
		{ label: "N8V", value: "ON_LON" },
		{ label: "N8W", value: "ON_LON" },
		{ label: "N8X", value: "ON_LON" },
		{ label: "N8Y", value: "ON_LON" },
		{ label: "N9A", value: "ON_LON" },
		{ label: "N9B", value: "ON_LON" },
		{ label: "N9C", value: "ON_LON" },
		{ label: "N9E", value: "ON_LON" },
		{ label: "N9G", value: "ON_LON" },
		{ label: "N9H", value: "ON_LON" },
		{ label: "N9J", value: "ON_LON" },
		{ label: "N9K", value: "ON_LON" },
		{ label: "N9V", value: "ON_LON" },
		{ label: "N9Y", value: "ON_LON" },
		{ label: "P0A", value: "ON_SUD" },
		{ label: "P0B", value: "ON_SUD" },
		{ label: "P0C", value: "ON_SUD" },
		{ label: "P0E", value: "ON_SUD" },
		{ label: "P0G", value: "ON_SUD" },
		{ label: "P0H", value: "ON_SUD" },
		{ label: "P0J", value: "ON_SUD" },
		{ label: "P0K", value: "ON_SUD" },
		{ label: "P0L", value: "ON_SUD" },
		{ label: "P0M", value: "ON_SUD" },
		{ label: "P0N", value: "ON_SUD" },
		{ label: "P0P", value: "ON_SUD" },
		{ label: "P0R", value: "ON_SUD" },
		{ label: "P0S", value: "ON_SUD" },
		{ label: "P0T", value: "ON_SUD" },
		{ label: "P0V", value: "ON_SUD" },
		{ label: "P0W", value: "ON_SUD" },
		{ label: "P0X", value: "ON_SUD" },
		{ label: "P0Y", value: "ON_SUD" },
		{ label: "P1A", value: "ON_SUD" },
		{ label: "P1B", value: "ON_SUD" },
		{ label: "P1C", value: "ON_SUD" },
		{ label: "P1H", value: "ON_SUD" },
		{ label: "P1L", value: "ON_SUD" },
		{ label: "P1P", value: "ON_SUD" },
		{ label: "P2A", value: "ON_SUD" },
		{ label: "P2B", value: "ON_SUD" },
		{ label: "P2N", value: "ON_SUD" },
		{ label: "P3A", value: "ON_SUD" },
		{ label: "P3B", value: "ON_SUD" },
		{ label: "P3C", value: "ON_SUD" },
		{ label: "P3E", value: "ON_SUD" },
		{ label: "P3G", value: "ON_SUD" },
		{ label: "P3L", value: "ON_SUD" },
		{ label: "P3N", value: "ON_SUD" },
		{ label: "P3P", value: "ON_SUD" },
		{ label: "P3Y", value: "ON_SUD" },
		{ label: "P4N", value: "ON_SUD" },
		{ label: "P4P", value: "ON_SUD" },
		{ label: "P4R", value: "ON_SUD" },
		{ label: "P5A", value: "ON_SUD" },
		{ label: "P5E", value: "ON_SUD" },
		{ label: "P5N", value: "ON_SUD" },
		{ label: "P6A", value: "ON_SUD" },
		{ label: "P6B", value: "ON_SUD" },
		{ label: "P6C", value: "ON_SUD" },
		{ label: "P7A", value: "ON_SUD" },
		{ label: "P7B", value: "ON_SUD" },
		{ label: "P7C", value: "ON_SUD" },
		{ label: "P7E", value: "ON_SUD" },
		{ label: "P7G", value: "ON_SUD" },
		{ label: "P7J", value: "ON_SUD" },
		{ label: "P7K", value: "ON_SUD" },
		{ label: "P8N", value: "ON_SUD" },
		{ label: "P8T", value: "ON_SUD" },
		{ label: "P9A", value: "ON_SUD" },
		{ label: "P9N", value: "ON_SUD" },
		{ label: "R0A", value: "MB_WIN" },
		{ label: "R0B", value: "MB_WIN" },
		{ label: "R0C", value: "MB_WIN" },
		{ label: "R0E", value: "MB_WIN" },
		{ label: "R0G", value: "MB_WIN" },
		{ label: "R0H", value: "MB_WIN" },
		{ label: "R0J", value: "MB_WIN" },
		{ label: "R0K", value: "MB_WIN" },
		{ label: "R0L", value: "MB_WIN" },
		{ label: "R0M", value: "MB_WIN" },
		{ label: "R1A", value: "MB_WIN" },
		{ label: "R1B", value: "MB_WIN" },
		{ label: "R1N", value: "MB_WIN" },
		{ label: "R2C", value: "MB_WIN" },
		{ label: "R2E", value: "MB_WIN" },
		{ label: "R2G", value: "MB_WIN" },
		{ label: "R2H", value: "MB_WIN" },
		{ label: "R2J", value: "MB_WIN" },
		{ label: "R2K", value: "MB_WIN" },
		{ label: "R2L", value: "MB_WIN" },
		{ label: "R2M", value: "MB_WIN" },
		{ label: "R2N", value: "MB_WIN" },
		{ label: "R2P", value: "MB_WIN" },
		{ label: "R2R", value: "MB_WIN" },
		{ label: "R2V", value: "MB_WIN" },
		{ label: "R2W", value: "MB_WIN" },
		{ label: "R2X", value: "MB_WIN" },
		{ label: "R2Y", value: "MB_WIN" },
		{ label: "R3A", value: "MB_WIN" },
		{ label: "R3B", value: "MB_WIN" },
		{ label: "R3C", value: "MB_WIN" },
		{ label: "R3E", value: "MB_WIN" },
		{ label: "R3G", value: "MB_WIN" },
		{ label: "R3H", value: "MB_WIN" },
		{ label: "R3J", value: "MB_WIN" },
		{ label: "R3K", value: "MB_WIN" },
		{ label: "R3L", value: "MB_WIN" },
		{ label: "R3M", value: "MB_WIN" },
		{ label: "R3N", value: "MB_WIN" },
		{ label: "R3P", value: "MB_WIN" },
		{ label: "R3R", value: "MB_WIN" },
		{ label: "R3S", value: "MB_WIN" },
		{ label: "R3T", value: "MB_WIN" },
		{ label: "R3V", value: "MB_WIN" },
		{ label: "R3W", value: "MB_WIN" },
		{ label: "R3X", value: "MB_WIN" },
		{ label: "R3Y", value: "MB_WIN" },
		{ label: "R4A", value: "MB_WIN" },
		{ label: "R4H", value: "MB_WIN" },
		{ label: "R4J", value: "MB_WIN" },
		{ label: "R4K", value: "MB_WIN" },
		{ label: "R4L", value: "MB_WIN" },
		{ label: "R5A", value: "MB_WIN" },
		{ label: "R5G", value: "MB_WIN" },
		{ label: "R5H", value: "MB_WIN" },
		{ label: "R6M", value: "MB_WIN" },
		{ label: "R6W", value: "MB_WIN" },
		{ label: "R7A", value: "MB_WIN" },
		{ label: "R7B", value: "MB_WIN" },
		{ label: "R7C", value: "MB_WIN" },
		{ label: "R7N", value: "MB_WIN" },
		{ label: "R8A", value: "MB_WIN" },
		{ label: "R8N", value: "MB_WIN" },
		{ label: "R9A", value: "MB_WIN" },
		{ label: "S0A", value: "SK_REG" },
		{ label: "S0C", value: "SK_REG" },
		{ label: "S0E", value: "SK_REG" },
		{ label: "S0G", value: "SK_REG" },
		{ label: "S0H", value: "SK_REG" },
		{ label: "S0J", value: "SK_REG" },
		{ label: "S0K", value: "SK_REG" },
		{ label: "S0L", value: "SK_REG" },
		{ label: "S0M", value: "SK_REG" },
		{ label: "S0N", value: "SK_REG" },
		{ label: "S0P", value: "SK_REG" },
		{ label: "S2V", value: "SK_REG" },
		{ label: "S3N", value: "SK_REG" },
		{ label: "S4A", value: "SK_REG" },
		{ label: "S4H", value: "SK_REG" },
		{ label: "S4L", value: "SK_REG" },
		{ label: "S4N", value: "SK_REG" },
		{ label: "S4P", value: "SK_REG" },
		{ label: "S4R", value: "SK_REG" },
		{ label: "S4S", value: "SK_REG" },
		{ label: "S4T", value: "SK_REG" },
		{ label: "S4V", value: "SK_REG" },
		{ label: "S4W", value: "SK_REG" },
		{ label: "S4X", value: "SK_REG" },
		{ label: "S4Y", value: "SK_REG" },
		{ label: "S4Z", value: "SK_REG" },
		{ label: "S6H", value: "SK_REG" },
		{ label: "S6J", value: "SK_REG" },
		{ label: "S6K", value: "SK_REG" },
		{ label: "S6V", value: "SK_REG" },
		{ label: "S6W", value: "SK_REG" },
		{ label: "S6X", value: "SK_REG" },
		{ label: "S7H", value: "SK_REG" },
		{ label: "S7J", value: "SK_REG" },
		{ label: "S7K", value: "SK_REG" },
		{ label: "S7L", value: "SK_REG" },
		{ label: "S7M", value: "SK_REG" },
		{ label: "S7N", value: "SK_REG" },
		{ label: "S7P", value: "SK_REG" },
		{ label: "S7R", value: "SK_REG" },
		{ label: "S7S", value: "SK_REG" },
		{ label: "S7T", value: "SK_REG" },
		{ label: "S7V", value: "SK_REG" },
		{ label: "S7W", value: "SK_REG" },
		{ label: "S9A", value: "SK_REG" },
		{ label: "S9H", value: "SK_REG" },
		{ label: "S9V", value: "SK_REG" },
		{ label: "S9X", value: "SK_REG" },
		{ label: "T0A", value: "AB_CAL" },
		{ label: "T0B", value: "AB_CAL" },
		{ label: "T0C", value: "AB_CAL" },
		{ label: "T0E", value: "AB_CAL" },
		{ label: "T0G", value: "AB_CAL" },
		{ label: "T0H", value: "AB_CAL" },
		{ label: "T0J", value: "AB_CAL" },
		{ label: "T0K", value: "AB_CAL" },
		{ label: "T0L", value: "AB_CAL" },
		{ label: "T0M", value: "AB_CAL" },
		{ label: "T0P", value: "AB_CAL" },
		{ label: "T0V", value: "AB_CAL" },
		{ label: "T1A", value: "AB_CAL" },
		{ label: "T1B", value: "AB_CAL" },
		{ label: "T1C", value: "AB_CAL" },
		{ label: "T1G", value: "AB_CAL" },
		{ label: "T1H", value: "AB_CAL" },
		{ label: "T1J", value: "AB_CAL" },
		{ label: "T1K", value: "AB_CAL" },
		{ label: "T1L", value: "AB_CAL" },
		{ label: "T1M", value: "AB_CAL" },
		{ label: "T1P", value: "AB_CAL" },
		{ label: "T1R", value: "AB_CAL" },
		{ label: "T1S", value: "AB_CAL" },
		{ label: "T1V", value: "AB_CAL" },
		{ label: "T1W", value: "AB_CAL" },
		{ label: "T1X", value: "AB_CAL" },
		{ label: "T1Y", value: "AB_CAL" },
		{ label: "T2A", value: "AB_CAL" },
		{ label: "T2B", value: "AB_CAL" },
		{ label: "T2C", value: "AB_CAL" },
		{ label: "T2E", value: "AB_CAL" },
		{ label: "T2G", value: "AB_CAL" },
		{ label: "T2H", value: "AB_CAL" },
		{ label: "T2J", value: "AB_CAL" },
		{ label: "T2K", value: "AB_CAL" },
		{ label: "T2L", value: "AB_CAL" },
		{ label: "T2M", value: "AB_CAL" },
		{ label: "T2N", value: "AB_CAL" },
		{ label: "T2P", value: "AB_CAL" },
		{ label: "T2R", value: "AB_CAL" },
		{ label: "T2S", value: "AB_CAL" },
		{ label: "T2T", value: "AB_CAL" },
		{ label: "T2V", value: "AB_CAL" },
		{ label: "T2W", value: "AB_CAL" },
		{ label: "T2X", value: "AB_CAL" },
		{ label: "T2Y", value: "AB_CAL" },
		{ label: "T2Z", value: "AB_CAL" },
		{ label: "T3A", value: "AB_CAL" },
		{ label: "T3B", value: "AB_CAL" },
		{ label: "T3C", value: "AB_CAL" },
		{ label: "T3E", value: "AB_CAL" },
		{ label: "T3G", value: "AB_CAL" },
		{ label: "T3H", value: "AB_CAL" },
		{ label: "T3J", value: "AB_CAL" },
		{ label: "T3K", value: "AB_CAL" },
		{ label: "T3L", value: "AB_CAL" },
		{ label: "T3M", value: "AB_CAL" },
		{ label: "T3N", value: "AB_CAL" },
		{ label: "T3P", value: "AB_CAL" },
		{ label: "T3R", value: "AB_CAL" },
		{ label: "T3Z", value: "AB_CAL" },
		{ label: "T4A", value: "AB_CAL" },
		{ label: "T4B", value: "AB_CAL" },
		{ label: "T4C", value: "AB_CAL" },
		{ label: "T4E", value: "AB_CAL" },
		{ label: "T4G", value: "AB_CAL" },
		{ label: "T4H", value: "AB_CAL" },
		{ label: "T4J", value: "AB_CAL" },
		{ label: "T4L", value: "AB_CAL" },
		{ label: "T4N", value: "AB_CAL" },
		{ label: "T4P", value: "AB_CAL" },
		{ label: "T4R", value: "AB_CAL" },
		{ label: "T4S", value: "AB_CAL" },
		{ label: "T4T", value: "AB_CAL" },
		{ label: "T4V", value: "AB_CAL" },
		{ label: "T4X", value: "AB_CAL" },
		{ label: "T5A", value: "AB_CAL" },
		{ label: "T5B", value: "AB_CAL" },
		{ label: "T5C", value: "AB_CAL" },
		{ label: "T5E", value: "AB_CAL" },
		{ label: "T5G", value: "AB_CAL" },
		{ label: "T5H", value: "AB_CAL" },
		{ label: "T5J", value: "AB_CAL" },
		{ label: "T5K", value: "AB_CAL" },
		{ label: "T5L", value: "AB_CAL" },
		{ label: "T5M", value: "AB_CAL" },
		{ label: "T5N", value: "AB_CAL" },
		{ label: "T5P", value: "AB_CAL" },
		{ label: "T5R", value: "AB_CAL" },
		{ label: "T5S", value: "AB_CAL" },
		{ label: "T5T", value: "AB_CAL" },
		{ label: "T5V", value: "AB_CAL" },
		{ label: "T5W", value: "AB_CAL" },
		{ label: "T5X", value: "AB_CAL" },
		{ label: "T5Y", value: "AB_CAL" },
		{ label: "T5Z", value: "AB_CAL" },
		{ label: "T6A", value: "AB_CAL" },
		{ label: "T6B", value: "AB_CAL" },
		{ label: "T6C", value: "AB_CAL" },
		{ label: "T6E", value: "AB_CAL" },
		{ label: "T6G", value: "AB_CAL" },
		{ label: "T6H", value: "AB_CAL" },
		{ label: "T6J", value: "AB_CAL" },
		{ label: "T6K", value: "AB_CAL" },
		{ label: "T6L", value: "AB_CAL" },
		{ label: "T6M", value: "AB_CAL" },
		{ label: "T6N", value: "AB_CAL" },
		{ label: "T6P", value: "AB_CAL" },
		{ label: "T6R", value: "AB_CAL" },
		{ label: "T6S", value: "AB_CAL" },
		{ label: "T6T", value: "AB_CAL" },
		{ label: "T6V", value: "AB_CAL" },
		{ label: "T6W", value: "AB_CAL" },
		{ label: "T6X", value: "AB_CAL" },
		{ label: "T7A", value: "AB_CAL" },
		{ label: "T7E", value: "AB_CAL" },
		{ label: "T7N", value: "AB_CAL" },
		{ label: "T7P", value: "AB_CAL" },
		{ label: "T7S", value: "AB_CAL" },
		{ label: "T7V", value: "AB_CAL" },
		{ label: "T7X", value: "AB_CAL" },
		{ label: "T7Y", value: "AB_CAL" },
		{ label: "T7Z", value: "AB_CAL" },
		{ label: "T8A", value: "AB_CAL" },
		{ label: "T8B", value: "AB_CAL" },
		{ label: "T8C", value: "AB_CAL" },
		{ label: "T8E", value: "AB_CAL" },
		{ label: "T8G", value: "AB_CAL" },
		{ label: "T8H", value: "AB_CAL" },
		{ label: "T8L", value: "AB_CAL" },
		{ label: "T8N", value: "AB_CAL" },
		{ label: "T8R", value: "AB_CAL" },
		{ label: "T8S", value: "AB_CAL" },
		{ label: "T8V", value: "AB_CAL" },
		{ label: "T8W", value: "AB_CAL" },
		{ label: "T8X", value: "AB_CAL" },
		{ label: "T9A", value: "AB_CAL" },
		{ label: "T9C", value: "AB_CAL" },
		{ label: "T9E", value: "AB_CAL" },
		{ label: "T9G", value: "AB_CAL" },
		{ label: "T9H", value: "AB_CAL" },
		{ label: "T9J", value: "AB_CAL" },
		{ label: "T9K", value: "AB_CAL" },
		{ label: "T9M", value: "AB_CAL" },
		{ label: "T9N", value: "AB_CAL" },
		{ label: "T9S", value: "AB_CAL" },
		{ label: "T9V", value: "AB_CAL" },
		{ label: "T9W", value: "AB_CAL" },
		{ label: "T9X", value: "AB_CAL" },
		{ label: "V0A", value: "BC_PRI" },
		{ label: "V0B", value: "BC_PRI" },
		{ label: "V0C", value: "BC_PRI" },
		{ label: "V0E", value: "BC_PRI" },
		{ label: "V0G", value: "BC_PRI" },
		{ label: "V0H", value: "BC_PRI" },
		{ label: "V0J", value: "BC_PRI" },
		{ label: "V0K", value: "BC_PRI" },
		{ label: "V0L", value: "BC_PRI" },
		{ label: "V0M", value: "BC_VAN" },
		{ label: "V0N", value: "BC_VAN" },
		{ label: "V0P", value: "BC_VAN" },
		{ label: "V0R", value: "BC_VAN" },
		{ label: "V0S", value: "BC_VAN" },
		{ label: "V0T", value: "BC_PRI" },
		{ label: "V0V", value: "BC_PRI" },
		{ label: "V0W", value: "BC_PRI" },
		{ label: "V0X", value: "BC_VAN" },
		{ label: "V1A", value: "BC_PRI" },
		{ label: "V1B", value: "BC_PRI" },
		{ label: "V1C", value: "BC_PRI" },
		{ label: "V1E", value: "BC_PRI" },
		{ label: "V1G", value: "BC_PRI" },
		{ label: "V1H", value: "BC_PRI" },
		{ label: "V1J", value: "BC_PRI" },
		{ label: "V1K", value: "BC_PRI" },
		{ label: "V1L", value: "BC_PRI" },
		{ label: "V1M", value: "BC_VAN" },
		{ label: "V1N", value: "BC_PRI" },
		{ label: "V1P", value: "BC_PRI" },
		{ label: "V1R", value: "BC_PRI" },
		{ label: "V1S", value: "BC_PRI" },
		{ label: "V1T", value: "BC_PRI" },
		{ label: "V1V", value: "BC_PRI" },
		{ label: "V1W", value: "BC_PRI" },
		{ label: "V1X", value: "BC_PRI" },
		{ label: "V1Y", value: "BC_PRI" },
		{ label: "V1Z", value: "BC_PRI" },
		{ label: "V2A", value: "BC_PRI" },
		{ label: "V2B", value: "BC_PRI" },
		{ label: "V2C", value: "BC_PRI" },
		{ label: "V2E", value: "BC_PRI" },
		{ label: "V2G", value: "BC_PRI" },
		{ label: "V2H", value: "BC_PRI" },
		{ label: "V2J", value: "BC_PRI" },
		{ label: "V2K", value: "BC_PRI" },
		{ label: "V2L", value: "BC_PRI" },
		{ label: "V2M", value: "BC_PRI" },
		{ label: "V2N", value: "BC_PRI" },
		{ label: "V2P", value: "BC_VAN" },
		{ label: "V2R", value: "BC_VAN" },
		{ label: "V2S", value: "BC_VAN" },
		{ label: "V2T", value: "BC_VAN" },
		{ label: "V2V", value: "BC_VAN" },
		{ label: "V2W", value: "BC_VAN" },
		{ label: "V2X", value: "BC_VAN" },
		{ label: "V2Y", value: "BC_VAN" },
		{ label: "V2Z", value: "BC_VAN" },
		{ label: "V3A", value: "BC_VAN" },
		{ label: "V3B", value: "BC_VAN" },
		{ label: "V3C", value: "BC_VAN" },
		{ label: "V3E", value: "BC_VAN" },
		{ label: "V3G", value: "BC_VAN" },
		{ label: "V3H", value: "BC_VAN" },
		{ label: "V3J", value: "BC_VAN" },
		{ label: "V3K", value: "BC_VAN" },
		{ label: "V3L", value: "BC_VAN" },
		{ label: "V3M", value: "BC_VAN" },
		{ label: "V3N", value: "BC_VAN" },
		{ label: "V3R", value: "BC_VAN" },
		{ label: "V3S", value: "BC_VAN" },
		{ label: "V3T", value: "BC_VAN" },
		{ label: "V3V", value: "BC_VAN" },
		{ label: "V3W", value: "BC_VAN" },
		{ label: "V3X", value: "BC_VAN" },
		{ label: "V3Y", value: "BC_VAN" },
		{ label: "V4A", value: "BC_VAN" },
		{ label: "V4B", value: "BC_VAN" },
		{ label: "V4C", value: "BC_VAN" },
		{ label: "V4E", value: "BC_VAN" },
		{ label: "V4G", value: "BC_VAN" },
		{ label: "V4K", value: "BC_VAN" },
		{ label: "V4L", value: "BC_VAN" },
		{ label: "V4M", value: "BC_VAN" },
		{ label: "V4N", value: "BC_VAN" },
		{ label: "V4P", value: "BC_VAN" },
		{ label: "V4R", value: "BC_VAN" },
		{ label: "V4S", value: "BC_VAN" },
		{ label: "V4T", value: "BC_PRI" },
		{ label: "V4V", value: "BC_PRI" },
		{ label: "V4W", value: "BC_VAN" },
		{ label: "V4X", value: "BC_VAN" },
		{ label: "V4Z", value: "BC_VAN" },
		{ label: "V5A", value: "BC_VAN" },
		{ label: "V5B", value: "BC_VAN" },
		{ label: "V5C", value: "BC_VAN" },
		{ label: "V5E", value: "BC_VAN" },
		{ label: "V5G", value: "BC_VAN" },
		{ label: "V5H", value: "BC_VAN" },
		{ label: "V5J", value: "BC_VAN" },
		{ label: "V5K", value: "BC_VAN" },
		{ label: "V5L", value: "BC_VAN" },
		{ label: "V5M", value: "BC_VAN" },
		{ label: "V5N", value: "BC_VAN" },
		{ label: "V5P", value: "BC_VAN" },
		{ label: "V5R", value: "BC_VAN" },
		{ label: "V5S", value: "BC_VAN" },
		{ label: "V5T", value: "BC_VAN" },
		{ label: "V5V", value: "BC_VAN" },
		{ label: "V5W", value: "BC_VAN" },
		{ label: "V5X", value: "BC_VAN" },
		{ label: "V5Y", value: "BC_VAN" },
		{ label: "V5Z", value: "BC_VAN" },
		{ label: "V6A", value: "BC_VAN" },
		{ label: "V6B", value: "BC_VAN" },
		{ label: "V6C", value: "BC_VAN" },
		{ label: "V6E", value: "BC_VAN" },
		{ label: "V6G", value: "BC_VAN" },
		{ label: "V6H", value: "BC_VAN" },
		{ label: "V6J", value: "BC_VAN" },
		{ label: "V6K", value: "BC_VAN" },
		{ label: "V6L", value: "BC_VAN" },
		{ label: "V6M", value: "BC_VAN" },
		{ label: "V6N", value: "BC_VAN" },
		{ label: "V6P", value: "BC_VAN" },
		{ label: "V6R", value: "BC_VAN" },
		{ label: "V6S", value: "BC_VAN" },
		{ label: "V6T", value: "BC_VAN" },
		{ label: "V6V", value: "BC_VAN" },
		{ label: "V6W", value: "BC_VAN" },
		{ label: "V6X", value: "BC_VAN" },
		{ label: "V6Y", value: "BC_VAN" },
		{ label: "V6Z", value: "BC_VAN" },
		{ label: "V7A", value: "BC_VAN" },
		{ label: "V7B", value: "BC_VAN" },
		{ label: "V7C", value: "BC_VAN" },
		{ label: "V7E", value: "BC_VAN" },
		{ label: "V7G", value: "BC_VAN" },
		{ label: "V7H", value: "BC_VAN" },
		{ label: "V7J", value: "BC_VAN" },
		{ label: "V7K", value: "BC_VAN" },
		{ label: "V7L", value: "BC_VAN" },
		{ label: "V7M", value: "BC_VAN" },
		{ label: "V7N", value: "BC_VAN" },
		{ label: "V7P", value: "BC_VAN" },
		{ label: "V7R", value: "BC_VAN" },
		{ label: "V7S", value: "BC_VAN" },
		{ label: "V7T", value: "BC_VAN" },
		{ label: "V7V", value: "BC_VAN" },
		{ label: "V7W", value: "BC_VAN" },
		{ label: "V7X", value: "BC_VAN" },
		{ label: "V7Y", value: "BC_VAN" },
		{ label: "V8A", value: "BC_VAN" },
		{ label: "V8C", value: "BC_VAN" },
		{ label: "V8G", value: "BC_PRI" },
		{ label: "V8J", value: "BC_PRI" },
		{ label: "V8K", value: "BC_VAN" },
		{ label: "V8L", value: "BC_VAN" },
		{ label: "V8M", value: "BC_VAN" },
		{ label: "V8N", value: "BC_VAN" },
		{ label: "V8P", value: "BC_VAN" },
		{ label: "V8R", value: "BC_VAN" },
		{ label: "V8S", value: "BC_VAN" },
		{ label: "V8T", value: "BC_VAN" },
		{ label: "V8V", value: "BC_VAN" },
		{ label: "V8W", value: "BC_VAN" },
		{ label: "V8X", value: "BC_VAN" },
		{ label: "V8Y", value: "BC_VAN" },
		{ label: "V8Z", value: "BC_VAN" },
		{ label: "V9A", value: "BC_VAN" },
		{ label: "V9B", value: "BC_VAN" },
		{ label: "V9C", value: "BC_VAN" },
		{ label: "V9E", value: "BC_VAN" },
		{ label: "V9G", value: "BC_VAN" },
		{ label: "V9H", value: "BC_VAN" },
		{ label: "V9J", value: "BC_VAN" },
		{ label: "V9K", value: "BC_VAN" },
		{ label: "V9L", value: "BC_VAN" },
		{ label: "V9M", value: "BC_VAN" },
		{ label: "V9N", value: "BC_VAN" },
		{ label: "V9P", value: "BC_VAN" },
		{ label: "V9R", value: "BC_VAN" },
		{ label: "V9S", value: "BC_VAN" },
		{ label: "V9T", value: "BC_VAN" },
		{ label: "V9V", value: "BC_VAN" },
		{ label: "V9W", value: "BC_VAN" },
		{ label: "V9X", value: "BC_VAN" },
		{ label: "V9Y", value: "BC_VAN" },

	];

	const [formData, setFormData] = useState(
		{
			commodity: "",
			exchangeType: "",
			scac: "",
			payDistance: "",
			originMarketID: "",
			destinationMarketID: "",
			customerType: "",
			date: "",
			confidence: 0.5,
			isLoading: 'false',
			result: ""
		});

	const [origData, setOrigData] = useState(
		{
			originMarketID: "",
			destinationMarketID: "",
		});

	const resetSliderProps = (resetVal) => {
		return {
			min: 0.5,
			max: 0.95,
			label: "",
			value: 0.5,
			step: .05,
			reset: resetVal,
			onChange: handleChange
		}
	};


	useEffect(() => {
		getMarketIds();
		//getConfidenceIntervalBuy();
		//getConfidenceIntervalSell();

		try {
			var dateTempConcat = "";

			var cnt = 0;
			for (var i = 0; i < 7; i++) {
				var date = new Date();
				date.setDate(date.getDate() - i);

				if (date.toLocaleString('default', { weekday: 'short' }) == 'Sun') {
					cnt = i;
				};
			}

			for (var i = 0; i < 21; i++) {
				var date = new Date();
				date.setDate(date.getDate() - cnt + i);

				var datePartTemp = findDatePart(date);

				dateTempConcat = dateTempConcat + datePartTemp;
			}

			var date = new Date();
			date.setDate(date.getDate());
			if (date.toLocaleString('default', { weekday: 'short' }) != "Sun") {
				setShowThree(true);
			}

			setDateArray(dateTempConcat.split(';'));

			setArray21(createSundayArray().split(';'));

		}
		catch (e: unknown) {
		}
	}, []);

	useEffect(() => {
		if (!formData.exchangeType
			|| !formData.originMarketID
			|| !formData.destinationMarketID	
			|| !formData.customerType
		) {
			setIsInitial(true);
		}
	}, [formData]);

	useEffect(() => {
		if (isLoading !== 'mezzo') {
			//setIsInitial(false);
		}
		else {
			setIsInitial(true);
		}
	}, [isLoading]);

	const evaluateArray = (val) => {
		var cnt = 0;
		for (var i = 0; i < 7; i++) {
			var date = new Date();
			date.setDate(date.getDate() - i);

			if (date.toLocaleString('default', { weekday: 'short' }) == 'Sun') {
				cnt = i;
			};
		}

		if (Number(val) < cnt || Number(val) > cnt + 13) {
			return 'false';
		}
		else {
			if (val == cnt.toString()) {
				return 'today';
			} else {
				return 'true';
			}
		}
	}

	const createSundayArray = () => {
		var cnt = 0;
		for (var i = 0; i < 7; i++) {
			var date = new Date();
			date.setDate(date.getDate() - i);

			if (date.toLocaleString('default', { weekday: 'short' }) == 'Sun') {
				cnt = i;
			};
		}

		var pass = "";
		for (var i = 0; i < 21; i++) {

			pass = pass + i.toString() + ";";
		};

		return pass;
	}


	function isErrnoException(e: unknown): e is NodeJS.ErrnoException {
		if ('code' in (e as any)) return true;
		else return false;
	}

	useEffect(() => {
		setIsExpanded(props.isItExpanded)
	}, [props.isItExpanded]);

	useEffect(() => {
		setIsLoadingNew("false");
	}, [ratepredSellResultArray]);

	


	useEffect(() => {
		if (dataMarket) {
			let markets = [...dataMarket];
			setMarkets(markets);
		}

	}, [dataMarket]);

	//useEffect(() => {
	//	if (confidenceResult) {
	//		let intervals = [...confidenceResult];
	//		setConfidenceFactorsBuy(intervals);
	//	}
	//}, [confidenceResult]);

	//useEffect(() => {
	//	if (confidenceResultSell) {
	//		let intervals = [...confidenceResultSell];
	//		setConfidenceFactorsSell(intervals);
	//	}
	//}, [confidenceResultSell]);

	const [sliderProps, setSliderProps] = useState(
		{
			min: 0.5,
			max: 0.95,
			label: "",
			value: ((isReset == true) ? formData.confidence : 0.5),
			step: .05,
			reset: ((isReset == true) ? 1 : 0),
			onChange: handleChange
		});

	useEffect(() => {
		if (isTriggered) {
			if (!isWaitingForRatePredResponse && !isWaitingForRatePredSellResponse) {
				setIsLoading('true');
				setIsTriggered(false);
				return;
			}

			if (isWaitingForRatePredResponse || isWaitingForRatePredSellResponse) {
				setIsLoading('mezzo');
				return;
			}
		}
	}, [isWaitingForRatePredResponse, isWaitingForRatePredSellResponse, isTriggered])

	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0
	});

	//function execSetIsComplete() {
	//	setIsCompleted(true);
	//};

	useEffect(() => {
		if (isReset === false) {
			setFormData((old) => {
				return {
					...old,
					['payDistance']: payDistanceResult
				}
			});
		} else {
			setIsReset(false);
			setFormData((old) => {
				return {
					...old,
					['payDistance']: ""
				}
			});
		}

		//removed because looking it up from a list was faster
		//updateMarket(formData.originMarketID, formData.destinationMarketID);

	}, [payDistanceResult]);

	useEffect(() => {
		if (ratepredResult != undefined && ratepredResult != "NaN") {
			setIsLoading('true');

			var cnt = 0;
			for (var i = 0; i < 7; i++) {
				var date = new Date();
				date.setDate(date.getDate() - i);

				if (date.toLocaleString('default', { weekday: 'short' }) == 'Sun') {
					cnt = i;
				};
			}

			const filler = '0,'

			const blankFiller = ','

			if (ratepredResult !== 'error') {
				setResultBuy(filler.repeat(cnt) + ratepredResult);
			}
			else {
				setResultBuy(blankFiller.repeat(cnt) + blankFiller.repeat(42));
			}

		} else {
			//setIsLoading('false');
		}
	}, [ratepredResult]);

	useEffect(() => {
		if (ratepredSellResult != undefined && ratepredSellResult != "NaN") {
			setIsLoading('true');

			var cnt = 0;
			for (var i = 0; i < 7; i++) {
				var date = new Date();
				date.setDate(date.getDate() - i);

				if (date.toLocaleString('default', { weekday: 'short' }) == 'Sun') {
					cnt = i;
				};
			}

			const filler = '0,'

			const blankFiller = ','

			if (ratepredSellResult !== 'error') {
				setResultSell(filler.repeat(cnt) + ratepredSellResult);
			}
			else {
				setResultSell(blankFiller.repeat(cnt) + blankFiller.repeat(42));
			}

		} else {
			setIsLoading('false');
		}
	}, [ratepredSellResult]);

	useEffect(() => {
		if (ratepredResult != undefined && ratepredSellResult != undefined && ratepredResult != "NaN" && ratepredSellResult != "NaN") {
			setRatepredResultArray(resultBuy.split(','));//resultBuy is padded with zeros to place the day in the correct calendar spot
			setRatepredSellResultArray(resultSell.split(','));
		}
		
	}, [resultBuy, resultSell]);

	const reset14Array = () => {
		setRatepredResultArray([]);
		setRatepredSellResultArray([]);
		ratepredSellResult = ""
		ratepredResult = ""
		
		setResultBuy("");
		setResultSell("");
	};

	useEffect(() => {
		if (formData.originMarketID !== null && formData.originMarketID !== undefined) {
			if (formData.originMarketID.length === 3) {
				setDisplayOrigKMA(true);
				let newValue = kmaFromZip3.find(di => di.label === formData.originMarketID);
				if (newValue) {
					setCalculatedOrigKMA(newValue!.value)
				}
			}
			else {
				setDisplayOrigKMA(false);
			}
		}
	}, [formData.originMarketID]);

	useEffect(() => {
		if (formData.destinationMarketID !== null && formData.destinationMarketID !== undefined) {
			if (formData.destinationMarketID.length === 3) {
				setDisplayDestKMA(true);
				let newValue = kmaFromZip3.find(di => di.label === formData.destinationMarketID);

				if (newValue) {
					setCalculatedDestKMA(newValue!.value)
				}
			}
			else {
				setDisplayDestKMA(false);
			}
		}
	}, [formData.destinationMarketID]);

	useEffect(() => {

		if (((formData.destinationMarketID != "" && formData.originMarketID != "")
			&& (formData.destinationMarketID != undefined && formData.originMarketID != undefined)
			&& ((formData.exchangeType != null && formData.customerType != null)
				&& (formData.exchangeType != "" && formData.customerType != "")))
			&& ((origData.destinationMarketID != "" && origData.originMarketID != "")
				&& (origData.destinationMarketID != undefined && origData.originMarketID != undefined)))
		{
            if (formData.payDistance !== undefined && formData.payDistance !== "" && formData.payDistance !== null) {
				let passDist: string = formData.payDistance;
				if (isNumeric(passDist) || typeof passDist === "number") {
					setIsInitial(false);
				}
				else {
					setIsInitial(true);
				}
			}
		} else {
			setIsInitial(true);
		};
	}, [formData.exchangeType, formData.customerType, formData.destinationMarketID, formData.originMarketID, origData.destinationMarketID, origData.originMarketID])

	useEffect(() => {

		if (((formData.destinationMarketID != "" && formData.originMarketID != "")
			&& (formData.destinationMarketID != undefined && formData.originMarketID != undefined)
			&& ((formData.exchangeType != null && formData.customerType != null)
				&& (formData.exchangeType != "" && formData.customerType != "")))
			|| ((origData.destinationMarketID != "" && origData.originMarketID != "")
				&& (origData.destinationMarketID != undefined && origData.originMarketID != undefined)))
		{
			const timeOutId = setTimeout(() => callPayDistanceCall(), 1);
			return () => clearTimeout(timeOutId);
		}
	}, [formData.destinationMarketID, formData.originMarketID, origData.destinationMarketID, origData.originMarketID])


	//useEffect(() => {

	//	if (convertZipToKMAResultOrig !== undefined) {
	//		setNewOrig(convertZipToKMAResultOrig);

	//		//removed - too slow
	//		//setCalculatedOrigKMA(convertZipToKMAResultOrig);
	//		console.log("convertZipToKMAResultOrig");
	//		console.log(convertZipToKMAResultOrig);
	//	};

	//}, [convertZipToKMAResultOrig])

	//useEffect(() => {

	//	if (convertZipToKMAResultDest !== undefined) {
	//		setNewDest(convertZipToKMAResultDest);
	//		setCalculatedDestKMA(convertZipToKMAResultDest);
	//	};

	//}, [convertZipToKMAResultDest])

	const findDatePart = (dateVal) => {
		var datePartTemp = ('0' + (dateVal.getMonth() + 1)).slice(-2) + '/'
			+ ('0' + dateVal.getDate()).slice(-2)
			+ ' - '
			+ dateVal.toLocaleString('default', { weekday: 'short' }) + ';';

		return datePartTemp;
	}

	function isNumeric(str) {
		if (typeof str != "string") return false
		return !Number.isNaN(str) && 
			!isNaN(parseFloat(str)) 
	}

	async function callPayDistanceCall() {
		if (formData.originMarketID != undefined && formData.destinationMarketID != undefined
			&& formData.originMarketID != "" && formData.destinationMarketID != ""
			&& formData.originMarketID != null && formData.destinationMarketID != null
			) {

			setIsInitial(true);

			setFormData((old) => {
				return {
					...old,
					['payDistance']: "calculating..."
				}
			});


			const odPairRecordToPassA: OdPairRecord = {
				odPair: origData.originMarketID + " " + origData.destinationMarketID
			};

			const odPairRecordToPassB: OdPairRecord = {
				odPair: origData.destinationMarketID + " " + origData.originMarketID
			};

			await payDistanceCall((origData.originMarketID < origData.destinationMarketID) ? odPairRecordToPassA : odPairRecordToPassB)

			setIsInitial(false);

		}

	}

	function delay(milliseconds) {
		return new Promise(resolve => {
			setTimeout(resolve, milliseconds);
		});
	}

	const closeForm = () => {
		props.setIsExpanded(false);
	};

	const clearForm = () => {
		props.setIsCleared(true);
		props.setIsExpanded(false);
	};

	function handleChangeMarketIdFinal(id: any, val: string, meta: string) {
		if (isReset === false) {

			if (id === "originMarketID") {
				if (val) {
					setDisplayOrigKMA(false);
				}
			}

			if (id === "destinationMarketID") {
				if (val) {
					setDisplayDestKMA(false);
				}
			}

			if (val !== null && val !== "" && meta !== "clear") {

				if (id === "originMarketID" || id === "destinationMarketID") {

					setFormData((old) => {
						return {
							...old,
							['payDistance']: ""
						}
					});

					setOrigData((old) => {
						return {
							...old,
							[id]: val
						}
					});
				}
				setFormData((old) => {
					return {
						...old,
						[id]: val
					}
				});

				if (id !== "confidence") {
					reset14Array();
				}
			}
			else {

				if (meta === "clear") {
					setIsInitial(true);
				}

				setFormData((old) => {
					return {
						...old,
						[id]: null
					}
				});
			}
		} else {
			setIsReset(false);
			setFormData((old) => {
				return {
					...old,
					['confidence']: 0.5
				};
			});
		}
	};

	function handleChange(evt) {
		if (isReset === false) {
			setSliderProps(resetSliderProps(0));
			setFormData((old) => {
				let val: string = "";

				if (evt.target.id === "commodity") {
					val = evt.target.value.toString().toUpperCase();
				} else {
					val = evt.target.value;
				}

				return {
					...old,
					[evt.target.id]: val
				}
			}

			);
			reset14Array();
		} else {
			setIsReset(false);
			setFormData((old) => {
				return {
					...old,
					['confidence']: 0.5
				};
			});
		}
	};

	//async function updateMarket(orig, dest) {

	//	setIsInitial(true);
	//	//this section determines if the value is a Zip3 or not

	//	//origin
	//	if (!Number.isNaN(orig) && orig.length === 3) {

	//		const zipToPassOrig: ZipRecord = {
	//			zip: orig.toString()
	//		};

	//		await convertZipToKMAOrig(zipToPassOrig);

	//	}

	//	//destination
	//	if (!Number.isNaN(dest) && dest.length === 3) {

	//		const zipToPass: ZipRecord = {
	//			zip: dest.toString()
	//		};

	//		await convertZipToKMADest(zipToPass);

	//	}

	//	setIsInitial(false);
	//}

	const handlePredictClick = (event) => {

		try {

			//setIsCompleted(false);

			reset14Array();

			if (isReset === false) {

				setIsLoadingNew("mezzo");
				setLoadingMessage("Loading...");
				setIsInitial(true);

				if (((!Number.isNaN(formData.originMarketID) && formData.originMarketID.length === 3) && !isNumeric(formData.destinationMarketID))
					|| ((!Number.isNaN(formData.destinationMarketID) && formData.destinationMarketID.length === 3) && !isNumeric(formData.originMarketID))) {

					alert("Both origin and destination must be Zip3 to Zip3 or MarketID to MarketID");
				}

				if (/^\d+$/.test(formData.payDistance) === false) {
					setLoadingMessage("");
					alert("Average Pay Distance must be a numeric value!:" + formData.payDistance);
				}

				setIsTriggered(true);
				event.preventDefault();

				var overrideOrig = origData.originMarketID;
				if (!Number.isNaN(origData.originMarketID) && origData.originMarketID.length === 3) {
					overrideOrig = calculatedOrigKMA;
					/*overrideOrig = newOrig;*/
				}

				var overrideDest = origData.destinationMarketID;
				if (!Number.isNaN(origData.destinationMarketID) && origData.destinationMarketID.length === 3) {
					overrideDest = calculatedDestKMA;
					/*overrideDest = newDest;*/
				}

				let passDate = new Date();

				passDate.setHours(0, 0, 0, 0);

				const formDataToPass: FormData = {
					commodity: formData.commodity,
					exchangeType: formData.exchangeType,
					scac: formData.scac,
					payDistance: Number(formData.payDistance),
					originMarketID: overrideOrig,
					destinationMarketID: overrideDest,
					customerType: formData.customerType,
					date: formatDate(passDate),
					confidence: formData.confidence,
					isLoading: formData.isLoading,
					result: formData.result
				};

				ratepredResult = ""
				setResultBuy("");
				ratepredSellResult = ""
				setResultSell("");
				RatePredictorBuyCall(formDataToPass);
				RatePredictorSellCall(formDataToPass);

				//setIsLoadingNew("false");

			}
		}
		catch {
		}
		setIsReset(false);
	};

	function padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	function formatDate(date) {
		return [
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate()),
			date.getFullYear(),
		].join('/');
	}

	const formatResult = (result, flag, conFactors) => {
		let num = 0.00;
		let mezzo = 0.00;
		let res = "";

		var factor = 0;

		if (formData.confidence == 0.50) {
			factor = 0;
		} else {
			//factor = Number(confidenceFactor);
			for (let i = 0; i < conFactors.length; i++) {
				if (conFactors[i].confidence == (Math.round(formData.confidence * 100)).toString()) {
					factor = Number(conFactors[i].factor);
				}
			}
		};

		switch (flag) {
			case 1:
				num = Math.round(result - factor);
				break;
			case 2:
				num = Math.round(result);
				break;
			case 3:
				num = Math.round(result - - factor); //the plus sign was returning NaN for some reason
				break;
			default:
				num = Math.round(result);
		}

		if (result == "NaN" || result == undefined || num.toString() == "NaN" || num.toString() == undefined || num == 0) {
			return res = "";
		} else {
			return res = "$" + num.toLocaleString();
		}
	}

	return (
		<>
			{isExpanded && (
				<CModal className="rate-pred-modal" alignment="center" visible={isExpanded} onClose={() => {
					setIsExpanded(false);
					props.setIsExpanded(false);
				}}>
					<div className="top-level-rp-new">

						<div className="container-rate-new">
							<div className="header-new">
								<h1 className="header-text-new">Predictor (all-in)</h1>
							</div>
							<div className="content-new">


								<form onSubmit={handlePredictClick}>
									<CContainer className="container-input">
										<CRow className="row-input">
											<CCol md="auto">
												<DsDropdown
													id="commodity"
													group=""
													table=""
													column=""
													displayName="Commodity:"
													operator="In"
													className="mt-1 dropdown-container"
													noSelectionPlaceholder=""
													isMultiSelect={false}
													isSearchable={true}
													isClearable={true}
													values={commodityType}
													onInputChange={handleChangeMarketIdFinal}
													disabled={false}
												/>
											</CCol>
											<CCol md="auto">
												<DsDropdown
													id="exchangeType"
													group=""
													table=""
													column=""
													displayName="Trailer Type:"
													operator="In"
													className="mt-1 dropdown-container"
													noSelectionPlaceholder=""
													isMultiSelect={false}
													isSearchable={true}
													isClearable={true}
													values={trailerType}
													onInputChange={handleChangeMarketIdFinal}
													disabled={false}
												/>
											</CCol>
											<CCol md="auto">
												<DsDropdown
													id="originMarketID"
													ref={origRef}
													group="marketZips"
													table="Origin"
													column="Orig Zip 3"
													displayName="Origin Market/Zip:"
													operator="In"
													className="mt-1 dropdown-container"
													noSelectionPlaceholder=""
													isMultiSelect={false}
													isSearchable={true}
													isClearable={true}
													values={sourceMarketZip.marketsAndZip}
													onInputChange={handleChangeMarketIdFinal}
													disabled={false}
												/>
											</CCol>
											<CCol md="auto">
												<DsDropdown
													id="destinationMarketID"
													ref={destRef}
													group="marketZips"
													table="Origin"
													column="Orig Zip 3"
													displayName="Destination Market/Zip:"
													operator="In"
													className="mt-1 dropdown-container"
													noSelectionPlaceholder=""
													isMultiSelect={false}
													isSearchable={true}
													isClearable={true}
													values={sourceMarketZip.marketsAndZip}
													onInputChange={handleChangeMarketIdFinal}
													disabled={false}
												/>
											</CCol>
											<CCol md="auto">
												<div className="label-plus-input">
													<label className="lbl-cc" htmlFor="payDistance">Average Pay Distance: <span> </span></label>
													<input
														id="payDistance"
														className="input2-new"
														type="text"
														name="payDistance"
														value={formData.payDistance}
														onChange={handleChange}
													/>
												</div>
											</CCol>
											<CCol md="auto">
												<DsDropdown
													id="customerType"
													group=""
													table=""
													column=""
													displayName="Carrier Type:"
													operator="In"
													className="mt-1 dropdown-container2"
													noSelectionPlaceholder=""
													isMultiSelect={false}
													isSearchable={true}
													isClearable={true}
													values={carrierType}
													onInputChange={handleChangeMarketIdFinal}
													disabled={false}
												/>
											</CCol>
										</CRow>
									</CContainer>
									<div className="buttons">
										<button type="button" disabled={isInitial} className="btn-cancel button-cancel-new btn btn-primary " onClick={clearForm} name="cancel">Clear</button>
										<button type="submit" disabled={isInitial} className="btn btn-primary button-submit-new" name="submit">Rate Now</button>
										<img className="centerRP" src={xClearRP} onClick={closeForm} />
									</div>
									{displayOrigKMA && (
										<div className="kma-orig" >
											{`KMA Used: ${calculatedOrigKMA}` }
										</div>)
									}
									{displayDestKMA && (
										<div className="kma-dest" >
											{`KMA Used: ${calculatedDestKMA}`}
										</div>)
									}
								</form>

								<div className="row0-14">
									<h1 className="h1-14">14-day Predictor</h1>
								</div>

								<div className="row1-14">
									&nbsp;&nbsp;
									<Card14
										display={evaluateArray(array21[0])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[0])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[0]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[0])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[0]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[0]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[0])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[0]) + 28], 2, confidenceFactorsSell)}
										/>
									<Card14
										display={evaluateArray(array21[1])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[1])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[1]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[1])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[1]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[1]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[1])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[1]) + 28], 2, confidenceFactorsSell)}
										 />
									<Card14
										display={evaluateArray(array21[2])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[2])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[2]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[2])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[2]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[2]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[2])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[2]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[3])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[3])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[3]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[3])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[3]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[3]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[3])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[3]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[4])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[4])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[4]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[4])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[4]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[4]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[4])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[4]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[5])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[5])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[5]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[5])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[5]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[5]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[5])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[5]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[6])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[6])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[6]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[6])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[6]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[6]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[6])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[6]) + 28], 2, confidenceFactorsSell)} 
										 />
								</div>
								<div className="row2-14">
									&nbsp;&nbsp;
									<Card14
										display={evaluateArray(array21[7])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[7])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[7]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[7])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[7]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[7]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[7])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[7]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[8])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[8])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[8]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[8])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[8]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[8]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[8])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[8]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[9])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[9])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[9]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[9])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[9]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[9]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[9])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[9]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[10])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[10])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[10]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[10])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[10]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[10]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[10])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[10]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[11])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[11])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[11]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[11])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[11]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[11]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[11])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[11]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[12])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[12])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[12]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[12])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[12]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[12]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[12])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[12]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[13])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[13])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[13]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[13])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[13]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[13]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[13])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[13]) + 28], 2, confidenceFactorsSell)} 
										 />
								</div>

								{showThree && (<div className="row3-14">
									&nbsp;&nbsp;
									<Card14
										display={evaluateArray(array21[14])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[14])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[14]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[14])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[14]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[14]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[14])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[14]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[15])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[15])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[15]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[15])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[15]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[15]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[15])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[15]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[16])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[16])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[16]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[16])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[16]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[16]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[16])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[16]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[17])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[17])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[17]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[17])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[17]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[17]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[17])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[17]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[18])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[18])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[18]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[18])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[18]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[18]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[18])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[18]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[19])}
										evenOdd={"even"}
										date={dateArray[parseInt(array21[19])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[19]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[19])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[19]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[19]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[19])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[19]) + 28], 2, confidenceFactorsSell)} 
										 />
									<Card14
										display={evaluateArray(array21[20])}
										evenOdd={"odd"}
										date={dateArray[parseInt(array21[20])]}
										buyLow={formatResult(ratepredResultArray[parseInt(array21[20]) + 14], 2, confidenceFactorsBuy)}
										buy={formatResult(ratepredResultArray[parseInt(array21[20])], 2, confidenceFactorsBuy)}
										buyHigh={formatResult(ratepredResultArray[parseInt(array21[20]) + 28], 2, confidenceFactorsBuy)}
										sellLow={formatResult(ratepredSellResultArray[parseInt(array21[20]) + 14], 2, confidenceFactorsSell)}
										sell={formatResult(ratepredSellResultArray[parseInt(array21[20])], 2, confidenceFactorsSell)}
										sellHigh={formatResult(ratepredSellResultArray[parseInt(array21[20]) + 28], 2, confidenceFactorsSell)} 
										 />
								</div>)}
							</div>
						</div>
					</div>
					<div>
						{(isLoadingNew === "mezzo" || isLoading === "mezzo") && (
							<div className="result-container">
								<div>
									<p className="lbl2">{loadingMessage}</p>
								</div>
							</div>
						)}
					</div>
				</CModal>
			)}
			{/*button was here...*/}
		</>
	);
}


export default RatePredictor;