import React, { useEffect } from 'react';
import { CContainer, CRow, CCol, CCloseButton } from "@coreui/react";
import './Card14.css';
/*import Background from '../assets/img/todays_color.png';*/

function Card14(props) {
    return (
        <> {props.display === 'true' && (
            <div className={`card14 ${props.evenOdd}`}>
                <CContainer className="container14">
                    <CRow className="row14-header">
                        <CCol>
                            <p className="date14">{props.date}</p>
                        </CCol>
                        {/*<CCol>*/}
                        {/*</CCol>*/}
                        {/*<CCol>*/}
                        {/*</CCol>*/}
                    </CRow>
                    <CRow className="row14-top">
                        <CCol className="col14-1">
                            <h5 className="head14">&nbsp;</h5>
                        </CCol>
                        <CCol className="col14-2b">
                            <h5 className="head14">&nbsp; &nbsp; &nbsp; Buy</h5>
                        </CCol>
                        <CCol className="col14-3b">
                            <h5 className="head14">Sell</h5>
                        </CCol>
                    </CRow>
                    <CRow className="row14">
                        <CCol className="col14-1">
                            <h5 className="buy14">High</h5>
                        </CCol>
                        <CCol className="col14-2">
                            <h6 className="buy14">{props.buyHigh}</h6>
                        </CCol>
                        <CCol className="col14-3">
                            <h6 className="buy14">{props.sellHigh}</h6>
                        </CCol>
                    </CRow>
                    <CRow className="row14">
                        <CCol className="col14-1">
                            <h5 className="buy14">Predicted</h5>
                        </CCol>
                        <CCol className="col14-2">
                            <h3 className="buy14">{props.buy}</h3>
                        </CCol>
                        <CCol className="col14-3">
                            <h3 className="buy14">{props.sell}</h3>
                        </CCol>
                    </CRow>
                    <CRow className="row14">
                        <CCol className="col14-1">
                            <h5 className="buy14">Low</h5>
                        </CCol>
                        <CCol className="col14-2">
                            <h6 className="buy14">{props.buyLow}</h6>
                        </CCol>
                        <CCol className="col14-3">
                            <h6 className="buy14">{props.sellLow}</h6>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        )}
            {props.display === 'false' && (
                <div className={`card14 ${props.evenOdd}`}>
                    <CCol>
                        <p className="date14">{props.date}</p>
                    </CCol>
                </div>
            )}
            {props.display === 'today' && (
                <div className="card14 today">
                    
                    <CContainer className="container14">
                        <CRow className="row14-header">
                            <CCol>
                                <p className="date14-today">&nbsp; {props.date}</p>
                            </CCol>
                            {/*<CCol>*/}
                            {/*</CCol>*/}
                            {/*<CCol>*/}
                            {/*</CCol>*/}
                        </CRow>
                        <CRow className="row14-top">
                            <CCol className="col14-1">
                                <h5 className="head14-today">&nbsp;</h5>
                            </CCol>
                            <CCol className="col14-2b">
                                <h5 className="head14-today">&nbsp; &nbsp; &nbsp; Buy</h5>
                            </CCol>
                            <CCol className="col14-3b">
                                <h5 className="head14-today">Sell</h5>
                            </CCol>
                        </CRow>
                        <CRow className="row14">
                            <CCol className="col14-1">
                                <h5 className="buy14-today">High</h5>
                            </CCol>
                            <CCol className="col14-2">
                                <h6 className="buy14-today">{props.buyHigh}</h6>
                            </CCol>
                            <CCol className="col14-3">
                                <h6 className="buy14-today">{props.sellHigh}</h6>
                            </CCol>
                        </CRow>
                        <CRow className="row14">
                            <CCol className="col14-1">
                                <h5 className="buy14-today">Predicted</h5>
                            </CCol>
                            <CCol className="col14-2">
                                <h3 className="buy14-today-pred">{props.buy}</h3>
                            </CCol>
                            <CCol className="col14-3">
                                <h3 className="buy14-today-pred">{props.sell}</h3>
                            </CCol>
                        </CRow>
                        <CRow className="row14">
                            <CCol className="col14-1">
                                <h5 className="buy14-today">Low</h5>
                            </CCol>
                            <CCol className="col14-2">
                                <h6 className="buy14-today">{props.buyLow}</h6>
                            </CCol>
                            <CCol className="col14-3">
                                <h6 className="buy14-today">{props.sellLow}</h6>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            )}
        </>
    );
}


export default Card14;

