import React, { useState, useEffect } from 'react';
import { DsDropdownInputProps, SelectionItem, ProFilterInfo } from '../models/filters';
import Select, { components } from 'react-select';

const Option = (props) => {
    return (
        <div className="d-flex align-items-center">
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


function DsDropdown({
    id,
    group,
    table,
    column,
    displayName,
    operator,
    className,
    noSelectionPlaceholder,
    values,
    isSearchable,
    isClearable,
    ref,
    isMultiSelect,
    onInputChange,
    disabled

}: DsDropdownInputProps) {
    const [ selected, setSelected] = useState<SelectionItem[] | null>(null);

    const colorStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            //console.log({ data, isDisabled, isFocused, isSelected });
            return {
                ...styles,
                /*backgroundColor: isFocused ? "#FFFFFF" : null,*/
                backgroundColor: isFocused || isSelected ? '#36a4ff' : 'transparent',
                color: "#333333"
            };
        }
    };


    //useEffect(() => {
    //    if (selected === null || selected.length == 0) {
    //        if (currentFilter !== null) {
    //            removeFilter(currentFilter);
    //            setCurrentFilter(null);
    //        }

    //        //if (onSetFilterItems) {
    //        //    onSetFilterItems(group, null);
    //        //}

    //        return;
    //    }

    //    let values = [...selected.map(s => s.value)];

    //    let quotedValues = values.map(v => `'${v}'`);
    //    let inList = `(${quotedValues.join(",")})`;

    //    const reportFilter = createBasicFilter({ table, column, value: values });

    //    let filter: ProFilterInfo = {
    //        displayText: `${displayName} ${translateOperatorName(operator)} ${inList}`,
    //        id,
    //        group,
    //        reportFilter,
    //        reset
    //    };

    //    setCurrentFilter(filter);
    //    updateFilter(filter);

    //    //if (onSetFilterItems) {
    //    //    onSetFilterItems(group, values);
    //    //}
    //}, [debouncedSelection])


    function reset() {
        //setCurrentFilter(null);
        setSelected(null);
    }

    function handleChange(value: any, meta: any) {
        if (meta.action === 'clear' || value === null) {
            setSelected(null);

            handleChangeSingle(value, meta);

            //handleChangeClear();
            return;
        }

        if (isMultiSelect) {
            handleChangeMulti(value, meta);
        }
        else {
            handleChangeSingle(value, meta);
        }
    }

    //function handleChangeClear() {
    //    if (onInputChange) {
    //        //console.log("From handleChangeSingle within Dropdown " + id + ": " + selected?.toString() + arg.value);
    //        if (id === "confidence") {
    //            onInputChange(id, "50");
    //        } else {
    //            onInputChange(id, "");
    //        }
    //    };
    //}



    function handleChangeSingle(value: any, meta: any) {
        if (value === null) {
            let val: SelectionItem[] = [];
            setSelected(val);

            if (onInputChange) {
                onInputChange(id, val, "clear");
            };
        }
        else {
            let arg = value as SelectionItem;
            let val: SelectionItem[] = [];
            val.push(arg);
            setSelected(val);

            if (onInputChange) {
                if (arg) {
                    onInputChange(id, arg.value, "");
                }
                else {
                    onInputChange(id, null, "clear");
                }
            };
        }
        
    }

    function handleChangeMulti(value: any, meta: any) {
        let val = value as SelectionItem[];
        setSelected(val);
    }

    
    function placeholder() {

        return null;
        //console.log("selected: " + JSON.stringify(selected));
        //if (!selected || selected.length == 0) {
        //    return noSelectionPlaceholder;
        //}

        //if (selected.length > 1) {
        //    return "Multiple Values";
        //}

        //return selected[0].label;
    }

    
    return (
        <div className={className ?? ""}>
            <label className="dsDropdownLabel">{displayName}</label>
            <Select
                value={selected}
                onChange={handleChange}
                options={values}
                styles={colorStyles}
                isSearchable={isSearchable}
                isMulti={isMultiSelect}
                isClearable={isClearable && selected !== null}
                placeholder={placeholder()}
                isDisabled={disabled}
            />
        </div>
    );
}

export default DsDropdown;