import { useState, useEffect, useCallback } from 'react';
//import logoWhite from '../assets/img/New_Pro_Yellow_M.png';
import logoWhite from '../assets/img/mpact_ratepro_white.png';
import { Page } from '../models/page';
import { Report } from '../models/report';
import { CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCollapse, CNavbarNav, CNavItem, CNavLink } from "@coreui/react";
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

interface HeaderProps {
    title: string;
    onPageClick: (page: Page) => void;
    userReport: Report;
    isDisabled: boolean;
};

function ProHeader({ title, userReport, onPageClick, isDisabled }: HeaderProps) {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [menuItems, setMenuItems] = useState<any[]>([]);

    useEffect(() => {
        if (!userReport) {
            return;
        }

        let items: any[] = [];
        let pages = [...userReport.pages];
        let groups = userReport.pageGroups.map(group => {
            return {
                ...group,
                pages: [],
                isGroup: true,
                key: `g_${group.id}`
            };
        });

        let lookup: any = {};
        groups.forEach(g => lookup[g.id] = g);
        pages.sort((a, b) => a.ordinal - b.ordinal);

        pages.forEach(p => {
            if (p.groupId && p.displayName !== "Momentum") {
                let group = lookup[p.groupId];
                group.pages.push(p);
            }
        })

        //this order was necessary to get the order of the menu the way Criss wanted it
        pages.filter(p => p.groupId === 0 && p.displayName === "Trends")
            .forEach(p => items.push(p));

        groups.filter(g => g.pages.length > 0)
            .forEach(i => items.push(i));

        pages.filter(p => p.groupId === 0
            && p.displayName !== "Trends"
            && p.displayName !== "Support")

            .forEach(p => items.push(p));

        setMenuItems(items);
    }, [userReport])

    useEffect(() => {
        if (title === "momentum") {

        }
    }, [title])


    //nav WAS if (onPageClick && !isActive) {
    const pageLink = useCallback((page: Page) => {
        const isActive = page.displayName === title;

        const nav = () => {
            if (onPageClick) {
                onPageClick(page);
                if (isVisible) {
                    setIsVisible(false);
                }
            }
        };


        //WAS <CNavLink disabled={isActive || isDisabled} onClick={nav}>
        return (
            <CNavItem key={page.key}>
                <CNavLink disabled={false} onClick={nav}>
                    {page.displayName}
                </CNavLink>
            </CNavItem>
        );
    }, [isDisabled, isVisible, onPageClick, title]);

    const createMenuItem = useCallback((menuItem: any) => {
        if (!menuItem.isGroup) {
            return pageLink(menuItem as Page);
        }

        let group = menuItem;
        let groupPages: Page[] = menuItem.pages as Page[];

        //nav WAS if (onPageClick && !isActive) {

        return (
            <CNavItem key={group.key}>
                <MDBDropdown>
                    <MDBDropdownToggle nav>
                        <span className="mr-2">{group.title}</span>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdownz">
                        {
                            groupPages.filter(p => !p.hide)
                                .map(page => {
                                    const isActive = page.displayName === title;
                                    const nav = () => {
                                        if (onPageClick) {
                                            onPageClick(page);

                                            if (isVisible)
                                                setIsVisible(false);
                                        }
                                    };
                                    return <MDBDropdownItem onClick={nav} key={page.key} >{page.displayName}</MDBDropdownItem>
                                })
                        }
                    </MDBDropdownMenu>
                </MDBDropdown>
            </CNavItem>
        );
    }, [isVisible, onPageClick, pageLink, title]);

    return (
        <CNavbar expand="xl" colorScheme="dark" style={{ backgroundColor: '#00395c' }} className="pro-header">

            <CContainer fluid>
                <CNavbarBrand >
                    <img src={logoWhite} title="Mpact Pro Logo" className='pro-brand' alt="MPact Pro Logo" />
                </CNavbarBrand>
                <CNavbarToggler
                    aria-label="Toggle navigation"
                    aria-expanded={isVisible}
                    onClick={() => setIsVisible(!isVisible)}
                />
                <CCollapse className="navbar-collapse navbar-position navbarz" visible={isVisible}>
                    <CNavbarNav className="d-flex align-items-center">
                        {menuItems.map(createMenuItem)}
                    </CNavbarNav>
                </CCollapse>
            </CContainer>
        </CNavbar>
    );
}

export default ProHeader;
