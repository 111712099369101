import React, { useEffect, useState } from 'react';
import { Report } from './models/report';
import { useAxios, useLazyAxios } from 'use-axios-client';
//import { useFetch, useLazyFetch } from './hooks/useFetch';

import ReportViewer from "./ReportViewer";
import ProReportViewer from "./pro/ProReportViewer";

import Splash from "./Splash";

import FullSizeCentered from './FullSizeCentered';
import LoadingIndicator from './LoadingIndicator';
import loadingImage from './assets/img/McLeodLogo_white.png';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryString } from './hooks/useQueryString';
import { Page } from './models/page';

import { TelemetryProvider } from './pro/TelemetryContext';
import { FilterProvider } from './pro/FilterContext';
import { CModal, CModalBody, CModalHeader, CModalTitle, CButton } from '@coreui/react';

import ErrorBoundary from './ErrorBoundary';

const baseUri = "https://" + window.location.host + "/";

function ReportSelector() {

    const [getSplashExists, { data: dataSplashExists, loading: loadingSplashExists }] = useLazyAxios<SplashExistVal>(baseUri + 'api/splash/getSplashExists', { method: 'GET' });

    const [userReport, setUserReport] = useState<Report | null>(null);
    const { pageUri } = useParams();
    const navigate = useNavigate();
    const queryString = useQueryString();
    const [isSplash, setIsSplash] = useState<boolean>(false);
    const [splashExists, setSplashExists] = useState<string>("0");

    const [hasLoadError, setHasLoadError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { error: loadError, data: reportLoad, refetch } = useAxios<Report>('/api/reports/userReport');

    interface SplashExistVal {
        splashExists: string
    }

    useEffect(() => {
        getSplashExists();
    }, []);

    useEffect(() => {
        if (dataSplashExists) {
            if (dataSplashExists != undefined) {
                setSplashExists(dataSplashExists[0].splashExists);
            };
        }

    }, [dataSplashExists]);


    useEffect(() => {
        if (!loadError) {
            return;
        }


        if (loadError.message.includes("401")) {
            if (pageUri) {
                navigate(`/login?sender=${pageUri}`)
            }
            else {
                navigate('/login');
            }
        }
        else {
            setErrorMessage(loadError.message);
            setHasLoadError(true);
        }
    }, [loadError]);

    useEffect(() => {
        if (!reportLoad) return;

        setIsLoading(false);
        if (reportLoad.showErrorModal) {
            setErrorMessage(reportLoad.errorMessage);
            setHasLoadError(true);
            return;
        }

        const framingOverride: string | null = queryString.get("hf");
        let initialPage: Page = reportLoad.defaultPage;

        if (pageUri && initialPage.uri !== pageUri) {
            let page = reportLoad.pages.find(p => p.uri === pageUri);
            initialPage = page ?? initialPage;
        }

        let hideFraming = framingOverride === null ? reportLoad.hideFraming : framingOverride === "t" ? true : false;

        setUserReport({
            ...reportLoad,
            defaultPage: initialPage,
            hideFraming
        });

        if (splashExists === '1') {
            showSplash();
        }
    }, [reportLoad])

    const showSplash = () => {
        setIsSplash(true)
        const timeout = setTimeout(() => setIsSplash(false), 4000)
        return () => clearTimeout(timeout)
    };


    function onRetry() {
        setHasLoadError(false);
        setErrorMessage("");
        setIsLoading(true);
        refetch();
    }

    if (hasLoadError) {
        return (
            <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report">
                <FullSizeCentered>
                    <LoadingIndicator imagePath={loadingImage} />
                </FullSizeCentered>

                <CModal alignment="center" visible={hasLoadError} size="xl" backdrop='static'>
                    <CModalHeader closeButton={false}>
                        <CModalTitle style={{ fontSize: "18px" }}>MPact Unavailable</CModalTitle>
                    </CModalHeader>

                    <CModalBody>
                        <p style={{ fontSize: "14px", padding: '0' }}>{errorMessage}</p>
                        <CButton size="sm" onClick={onRetry}>Retry</CButton>
                    </CModalBody>
                </CModal>
            </div>
        )
    }

    if (isLoading || !userReport) {
        return (
            <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report">
                <FullSizeCentered>
                    <LoadingIndicator imagePath={loadingImage} />
                </FullSizeCentered>
            </div>
        );
    }

    if (userReport!.isProReport) {
        return (
            <ErrorBoundary>
                <TelemetryProvider>
                    <FilterProvider>
                        {isSplash && (<Splash setIsSplash={setIsSplash} isSplash={isSplash} />)}
                        {!isSplash && (<ProReportViewer userReport={userReport!} />)}
                    </FilterProvider>
                </TelemetryProvider>
            </ErrorBoundary>
        );
    }

    return (
        <TelemetryProvider>
            {isSplash && (<Splash setIsSplash={setIsSplash} isSplash={isSplash} />)}
            {!isSplash && (<ReportViewer userReport={userReport!} />)}
        </TelemetryProvider>
    );
}

export default ReportSelector;


