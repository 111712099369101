import React, { useState, useEffect, useRef } from 'react';
import { useLazyAxios } from 'use-axios-client';
import { EmbedToken } from '../models/embedToken';

const tokenCheckInterval = 1000 * 60 * 1;
const minTimeToExpiration = 1000 * 60 * 5;

export function useEmbedToken(initial: EmbedToken) {
    const [getData, { data }] = useLazyAxios<EmbedToken>('/api/reports/embedToken');
    const [embedToken, setEmbedToken] = useState<EmbedToken | null>(() => {
        const now = Date.now();
        const tokenExpiration = initial.expiration;
        const timeToExpiration = tokenExpiration - now;

        if (timeToExpiration >= minTimeToExpiration) {
            return initial;
        }
        return null;
    });

    const [token, setToken] = useState<string>(() => {
        if (embedToken === null) {
            return "";
        }

        return embedToken.token;
    });

    const timerCallback = useRef(checkTokenExpiry);

    function checkTokenExpiry() {
        if (embedToken === null) {
            return;
        }

        const now = Date.now();
        const tokenExpiration = embedToken.expiration;
        const timeToExpiration = tokenExpiration - now;

        if (timeToExpiration <= minTimeToExpiration) {
            getData();
        }
    }

    useEffect(() => {
        timerCallback.current = checkTokenExpiry;
    });

    useEffect(() => {
        if (embedToken === null) {
            getData();
        }
        else {
            checkTokenExpiry();
        }

        function tick() {
            timerCallback.current();
        }

        const handle = window.setInterval(tick, tokenCheckInterval);

        return () => {
            window.clearInterval(handle);
        };
    }, []);

    useEffect(() => {
        if (data) {
            setEmbedToken(data)
            setToken(data.token);
        }
    }, [data])


    return {
        token
    }
};
