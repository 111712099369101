import React, { Component, ErrorInfo, ReactNode } from 'react';

class ErrorBoundary extends Component<any, any> {
    public state: any = {
        hasError: false,
        error: null,
        errorInfo: null
    };

    public static getDerivedStateFromError(_: Error): any {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.state = {
            ...this.state,
            error: error,
            errorInfo: errorInfo
        }
        console.error("Uncaught error:", error, errorInfo);
        console.log(error);
        console.log(errorInfo);
    }

    public render() {
        if (this.state.hasError)
            return <p>Something went wrong.</p>

        return this.props.children;
    }
}

export default ErrorBoundary;
