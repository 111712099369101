import React, { useState, useEffect } from 'react';
import { SelectionItem, ProFilterInfo, RadioButtonInputProps } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
//import Select from 'react-select/dist/declarations/src/Select';
import { useDebouncedValue } from '../hooks/useDebouncedValue';

function PbiToggleButtonThreeFilterInput({
    id,
    group,
    table,
    column,
    displayName,
    operator,
    className,
    defaultValue,
    values,
    disabled,
    onSetFilterItems,
    sortIndex
}: RadioButtonInputProps) {


    const { updateFilter, removeFilter, getFilterValue } = useFilters();
    const { createBasicFilter } = useFilterFactory();
    const [currentFilter, setCurrentFilter] = useState<ProFilterInfo | null>();

    const [valueType, setValueType] = useState<string | null>("Both");
    
    const [selected, setSelected] = useState<SelectionItem>(() => {

        let val = values.find(di => di.value === defaultValue);

        return val!;
    });

    const debouncedSelection = useDebouncedValue(selected, 100);

    useEffect(() => {
        let passVal = values.find(di => di.value === defaultValue);
        
        let passDefaultValue : any = getFilterValue(table,column) ?? passVal;
        
        if (passDefaultValue === null || passDefaultValue === undefined) {
            setValueType("Both");
        }
        else {
            let val = values.find(di => di.value === passDefaultValue.value);
            if (val !== undefined && val !== null) {
                setValueType(val.value);
            }
            else {
                setValueType("Both");
            }
        }
    }, [])

    useEffect(() => {
        let value: string = "Both";
        if (selected === undefined) {
            value = "Both";
        }
        else {
            value = selected.value;
        }

        if (valueType === "Both" || value === "Both") {

            removeFilter(currentFilter);
            setCurrentFilter(null);

            return;
        }

        let reportFilter = createBasicFilter({ table, column, value })

        let filter: ProFilterInfo = {
            id,
            group,
            reportFilter,
            displayTextHeader: `${displayName}`,
            displayText: `${selected.label}`,
            reset,
            isReadonly: false,
            isPreload: selected.value === defaultValue,
            rawValue: { value },
            sortIndex: sortIndex
        };

        setCurrentFilter(filter);
        updateFilter(filter);    

        if (onSetFilterItems) {
            onSetFilterItems(group, value);
        }

        
        
    }, [debouncedSelection]);

    useEffect(() => {
        if (selected === undefined || selected.value === "Both") {
            removeFilter(currentFilter);
            setCurrentFilter(null);
        }
    }, [selected]);


    function reset() {
        if (id === "mileage_group") {
            var selectedItem: SelectionItem = { label: ">250", value: ">250" };
        }
        else {
            var selectedItem: SelectionItem = { label: "Both", value: "Both" };
        }

        setSelected(selectedItem);
        setValueType(selectedItem.value);
    }

    useEffect(() => {
        var selectedItem: SelectionItem = { label: "Both", value: "Both" };

        if (valueType == "Both") {
            setSelected(selectedItem);
        }
        else {

            let val: any = null;
            if (id.toString() === "mileage_group") {
                val = values.find(di => di.value === valueType);
            }
            else {
                val = values.find(di => di.value === valueType);
            }
            

            if (val !== undefined) {
                setSelected(val);
            }
        }
    }, [valueType])

    function toggleBoth() {
        setValueType("Both");
        if (currentFilter !== null) {
            removeFilter(currentFilter);
            setCurrentFilter(null);
        }
    }

    function toggleA() {
        setValueType(values[0].value);
    }

    function toggleB() {
        setValueType(values[1].value);
    }

    function handleValueA() {
        if (values[0].value === null || values[0].value === undefined) {
            return "mp-2-fp fp-btn-default";
        }
        else {
            if (valueType === values[0].value) {
                return "mp-2-fp fp-btn-primary"
            }
            else {
                return "mp-2-fp fp-btn-default";
            }
        }
    }

    function handleValueB() {
        if (values[1].value === null || values[1].value === undefined) {
            return "mp-2-fp fp-btn-default";
        }
        else {
            if (valueType === values[1].value) {
                return "mp-2-fp fp-btn-primary"
            }
            else {
                return "mp-2-fp fp-btn-default";
            }
        }
    }

    return (
        <div className={className ?? ""}>
            <label>{displayName}</label>
            {/*<div>*/}
            {/*    {values.map((v, i) => { return RenderInput(v, i); })}*/}
            {/*</div>*/}
            <div className="fp-btn-group">
                <button
                    type="button"
                    onClick={toggleA}
                    className={handleValueA()}
                    id="today">{values[0].value}
                </button>
                <button
                    type="button"
                    onClick={toggleB}
                    className={handleValueB()}
                    id="this_week">{values[1].value}
                </button>
                <button
                    type="button"
                    onClick={toggleBoth}
                    className={"mp-2-fp " + (valueType == "Both" ? "fp-btn-primary" : "fp-btn-default")}
                    id="this_week">Both
                </button>
            </div>
        </div>
    );


}


export default PbiToggleButtonThreeFilterInput;
