import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from 'use-axios-client';

export function useAdminPage() {
    const { data: hasAccess, loading: isAuthPending, error: adminCheckError } = useAxios('/api/admin/hasAccess');
    const navigate = useNavigate();

    useEffect(() => {
        if (!adminCheckError) {
            return;
        }

        if (adminCheckError.message.includes("401")) {
            navigate("/login");
        } else {
            navigate('/unauthorized');
        }

    }, [adminCheckError])

    useEffect(() => {
        if (hasAccess === undefined) {
            return;
        }

        if (!hasAccess) {
            navigate('/unauthorized');
        }

    }, [hasAccess])


    return {
        isAuthPending
    };
}