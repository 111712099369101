import { useState, useEffect, useRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useFilters } from './FilterContext';

import { models, Report as PbiReport } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react';
import ProHeader from './ProHeader';
import FilterPane from './FilterPane';
import { Page } from '../models/page';
import { useEmbedToken } from '../hooks/useEmbedTokens';
import { ReportViewerProps } from '../models/report';
import { useQueryStringFilters } from '../hooks/useQueryStringFilters';
import { usePopoutLink } from '../hooks/usePopoutLink';
import ActiveFilters from './ActiveFilters';
/*import { useAxios } from 'use-axios-client';*/
import funnelLogo from '../assets/img/funnel.png'
import { useFilterFactory } from '../hooks/useFilterFactory';
import { subDays } from 'date-fns';
import { ProFilterInfo, GeographyComplete } from '../models/filters';
import { useTelemetry } from './TelemetryContext';
import { Helmet } from 'react-helmet';
import ChatBot from '../ChatBot';
import ButtonRatePred from './ButtonRatePred';
import ButtonSupport from './ButtonSupport';

import './ProReportViewer.css';

function ProReportViewer({ userReport }: ReportViewerProps) {
    //const { data } = useAxios<GeographyComplete[]>('/api/filters/geographiesComplete');
    const {
        setEmbed,
        activeFilters,
        areBasicFiltersVisible,
        registerPreloadedFilters,
        toggleBasicFilters,
        geoMaster
    } = useFilters();

    const { link } = usePopoutLink(true);
    const { filters: queryStringFilters, proFilters, userId } = useQueryStringFilters(true);
    const { pageUri } = useParams();

    const embed = useRef<any>(false);
    const pbiRef = useRef<any>("235px");
    //const fpRef = useRef<any>();

    const isFirstRender = useRef<boolean>(true);
    const preloads = useRef<ProFilterInfo[]>([]);

    const [activePage, setActivePage] = useState<string>(userReport.defaultPage.powerBiInternalName);
    const [activePageTitle, setActivePageTitle] = useState<string>(userReport.defaultPage.displayName);
    const [isRatePred, setIsRatePred] = useState(false);
    const [isChatbot, setIsChatbot] = useState(false);
    const [chatbotScac, setChatbotScac] = useState(false);
    const [isNavDisabled, setIsNavDisabled] = useState(false);


    const [route, setRoute] = useState("");

    const [grayOut, setGrayOut] = useState<boolean>(false);
    const [isSupport, setIsSupport] = useState<boolean>(false);
    /*const [geoMaster, setGeoMaster] = useState<GeographyComplete[]>([]);*/

    const [isInitialRenderComplete, setIsInitialRenderComplete] = useState<boolean>(false);

    const { token } = useEmbedToken(userReport.embedToken);
    const navigate = useNavigate();
    const { createDateRangeFilter } = useFilterFactory();
    const { setSession, logAction, setUser } = useTelemetry();

    const suppressPowerBiSizeChanges = true;

    const embedConfig = useRef<any>(
        {
            type: 'report',
            id: userReport.reportId,
            embedUrl: userReport.embedUrl,
            accessToken: token,
            pageName: activePage,
            settings: {
                panes: {
                    filters: {
                        visible: false
                    },
                    pageNavigation: {
                        visible: false
                    }
                },
                background: models.BackgroundType.Transparent
            },
            tokenType: models.TokenType.Embed
        });

    useEffect(() => {
        setSession(userReport.sessionId);
        setUser(userId!);
        logAction("reportViewerLoaded");
        setIsRatePred(userReport.isRatePredReport);
        setChatbotScac(userReport.isChatbotUser);//from [ChatbotScacs] table
        setIsChatbot(userReport.isChatbotReport);//global from Configuration table

        setIsInitialRenderComplete(true);
        console.log("pro report viewer initial render");

        return () => {
            console.log("pro report viewer destroyed");
            logAction("reportViewerUnloaded");
        }
    }, [])

    //useEffect(() => {
    //    if (!data) {
    //        return;
    //    }
    //    setGeoMaster(data);
    //}, [data]);

    useEffect(() => {
        embedConfig.current = {
            ...embedConfig.current,
            accessToken: token
        };
    }, [token])

    useEffect(() => {
        if (userReport.hideFraming) {
            return;
        }

        const contentPBI: any = pbiRef.current!;
        if (!contentPBI) {
            return;
        }

        setGrayOut(areBasicFiltersVisible);
        if (areBasicFiltersVisible && !suppressPowerBiSizeChanges) {
            contentPBI.style.top = "235px";
            contentPBI.style.height = "70vh";
        } else {
            contentPBI.style.top = "124px";
            contentPBI.style.height = "84vh";
        }
    }, [areBasicFiltersVisible, userReport])

    useEffect(() => {
        if (isSupport) {
            navigate("/support");
        }
    }, [isSupport])

    useEffect(() => {
        let defaultPage = userReport.pages.find(p => p.isDefault);
        let pageMatch = userReport.pages.find(p => p.uri === pageUri);

        setIsNavDisabled(true);

        if (pageMatch) {
            setActivePage(pageMatch.powerBiInternalName);
            setActivePageTitle(pageMatch.displayName);
            embedConfig.current.pageName = pageMatch.powerBiInternalName;

            logAction("pageChanged", { "newPage": pageMatch.displayName });
        }
        else if (defaultPage) {
            setActivePage(defaultPage.powerBiInternalName);
            setActivePageTitle(defaultPage.displayName);
            embedConfig.current.pageName = defaultPage.powerBiInternalName;

            logAction("pageChanged", { "newPage": defaultPage.displayName });
        }

        if (embed.current !== false) {
            (async function () {
                await embed.current.setPage(embedConfig.current.pageName);
            })();
        }

        return () => {
            setActivePage("");
            setActivePageTitle("");
            setIsNavDisabled(false);
        };
    }, [pageUri]);

    const onPbiReportEvent = useCallback((event: any) => {
        let type = event.type;
        let details = event.detail;
        let keys = Object.keys(details)
            .filter(k => k !== "report" && k !== "filters" && k !== "page" && details[k]);

        let toLog: any = {};
        keys.forEach(k => toLog[k] = details[k]);

        logAction(type, toLog);
    }, [logAction])


    const canRenderFlowMaps = useMemo(() => {
        let foundOrigin = false;
        let foundDestination = false;

        for (var i = 0; i < activeFilters.length; i++) {
            foundOrigin = !foundOrigin && activeFilters[i].id === "origin_zone";
            foundDestination = !foundDestination && activeFilters[i].id === "destination_zone";

            if (foundOrigin || foundDestination) {
                break;
            }
        }

        return foundOrigin || foundDestination;
    }, [activeFilters]);

    let embedEventHandlers: Map<string, any> = new Map<string, any>(
        [
            ['rendered', async function (e: any, pbiReport: PbiReport) {

                setIsNavDisabled(false);
                logAction("reportRendered");

                if (embed.current === false) {
                    embed.current = pbiReport;
                    pbiReport.on("buttonClicked", onPbiReportEvent);
                    pbiReport.on("commandTriggered", onPbiReportEvent);
                    pbiReport.on("dataHyperlinkClicked", onPbiReportEvent);
                    pbiReport.on("dataSelected", onPbiReportEvent);
                    pbiReport.on("selectionChanged", onPbiReportEvent);
                    pbiReport.on("visualClicked", onPbiReportEvent);

                    //removed from here based on Luis' advice
                    //this causes a warning, but it will not work otherwise
                    //registerPreloadedFilters(preloads.current);
                    await setEmbed(pbiReport);
                }
            }],
            ['error', async function (event: any) {
            }]
        ]);

    if (isFirstRender.current) {
        let shipDateStart = subDays(new Date(), 90);
        let shipDateEnd = new Date();
        let shipDateFilter = createDateRangeFilter(shipDateStart, shipDateEnd, "Dates", "Date");

        const defaultShipDate: ProFilterInfo = {
            id: "shipdate_range",
            group: "shipdate",
            displayTextHeader: `Ship Date`,
            displayText: `${shipDateStart.toLocaleDateString('en-US')} to ${shipDateEnd.toLocaleDateString('en-US')}`,
            reportFilter: shipDateFilter,
            reset: () => { },
            isPreload: true,
            rawValue: { startDate: shipDateStart, endDate: shipDateEnd },
            sortIndex: 3
        };

        //set embed filters
        const reportFilters: any[] = [...proFilters.map(pf => pf.reportFilter)];
        reportFilters.push(shipDateFilter);
        embedConfig.current.filters = reportFilters;

        //track preloads
        let preloaded = [...proFilters];
        preloaded.push(defaultShipDate);
        preloads.current = preloaded;
        //moved to here based on Luis' advice
        //this causes a warning, but it will not work otherwise
        registerPreloadedFilters(preloads.current);
        isFirstRender.current = false;
    }

    async function changePage(page: Page) {
        setIsNavDisabled(true);
        if (page.uri === "flow-maps" && !canRenderFlowMaps) {
            alert("In order to use the flow map, please select an origin or destination first.")
        } else {
            setIsSupport(false);
            const route = `/${page.uri}`;
            setRoute(route);

            if (!isNavDisabled) {
                navigate(route);
            }
        }
    }

    useEffect(() => {
        if (!isNavDisabled) {
            navigate(route);
        }
    }, [isNavDisabled, route])

    const toggleSupport = useCallback((val) => {
        setIsSupport(val);
    }, []);

    if (userReport.hideFraming) {
        return (
            <>
                <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report-pro" style={{ overflow: 'hidden' }}>
                    <span
                        title="Click here to access the full power of MPact Pro!"
                        className='collapsed-background base-mpact mb-2 button-left'
                        onClick={() => {
                            var params = [
                                'height=' + window.screen.height,
                                'width=' + window.screen.width
                            ].join(',');

                            window.open(link, "MPactPopup", params)
                        }}>
                        Launch MPactPRO!
                    </span>

                    <div
                        className="report-viewer"
                        style={{
                            position: 'absolute',
                            top: '20px',
                            left: '0'
                        }}

                    >
                        <PowerBIEmbed embedConfig={embedConfig.current} eventHandlers={embedEventHandlers} cssClassName='report embed-rendered' />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>{`${activePageTitle} | MPact Pro`}</title>
            </Helmet>

            <div className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report-pro pro-report-container">
                <ProHeader
                    title={activePageTitle}
                    onPageClick={changePage}
                    userReport={userReport}
                    isDisabled={embed.current === isNavDisabled}
                />

                <div>
                    {grayOut && (<div className="gray-out"></div>)};
                </div>

                <div className="pro-report-viewer" ref={pbiRef}>
                    <PowerBIEmbed cssClassName="report" embedConfig={embedConfig.current} eventHandlers={embedEventHandlers} />
                    {!areBasicFiltersVisible && <ActiveFilters pageUri={pageUri} />}
                </div>

                <div className="funnel-div btn">
                    <img src={funnelLogo} title="Funnel Logo" className="funnel" onClick={_ => toggleBasicFilters()} alt="filter icon" />
                </div>

                {isInitialRenderComplete && <FilterPane geoMaster={geoMaster} />}

                {/*{isChatbot && chatbotScac && (<ChatBot isProClass="button-pro" />)}*/}
                {isRatePred && (<ButtonRatePred isProClass="button-pro-rp" />)}
                <ButtonSupport toggleSupport={toggleSupport} />
                <footer
                    className="bg-dark w-100 text-white d-flex justify-content-center align-items-center pro-footer">
                    All information and content in the MPact portal is the intellectual property of McLeod Software.  You may not reuse, republish, or reprint any of the content without the explicit prior written consent of McLeod Software.
                </footer>
            </div>
        </>
    );
}

export default ProReportViewer;
