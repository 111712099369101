import React, { useState, useEffect } from 'react';
import { SelectionItem, ProFilterInfo, RadioButtonInputProps } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
//import Select from 'react-select/dist/declarations/src/Select';
import { useDebouncedValue } from '../hooks/useDebouncedValue';


function PbiSliderToggleFilterInput({
    id,
    group,
    table,
    column,
    displayName,
    operator,
    className,
    values,
    defaultValue,
    slideToggle,
    toggleSlider,
    //forceUpdate,
    sortIndex
}: RadioButtonInputProps) {

    const { updateFilter, getFilterValue } = useFilters();
    const { createBasicFilter } = useFilterFactory();

    //true is RPM and false if Flat
    const [isFlatMode, setIsFlatMode] = useState<boolean>(true);

    const [selected, setSelected] = useState<SelectionItem>(() => {
        let val = values.find(di => di.value === defaultValue);

        return val!;
    });

    const debouncedSelection = useDebouncedValue(selected, 500);

    useEffect(() => {
        //var defaultValue = getFilterValue(id);

        console.log("Slider default value: " + defaultValue);

        if (defaultValue) {
            let newValue = values.find(di => di.value === defaultValue)!;
            if (newValue.value === "RPM") {
                setIsFlatMode(true)
            }
            else {
                setIsFlatMode(false)
            }
        }
    }, [])

    useEffect(() => {
        slideToggle!(selected.value);
    }, [selected])


    useEffect(() => {
        slideToggle!(isFlatMode === true ? "RPM" : "Flat");
        if (isFlatMode) {
            let newValue = values.find(di => di.value === "RPM")!;
            setSelected(newValue);
        }
        else {
            let newValue = values.find(di => di.value === "Flat")!;
            setSelected(newValue);
//            forceUpdate!();
        }
    }, [isFlatMode])

    useEffect(() => {

        if (selected !== undefined) {
            let value = selected.value;

            let reportFilter = createBasicFilter({ table, column, value })

            let filter: ProFilterInfo = {
                id,
                group,
                reportFilter,
                displayTextHeader: `${displayName}`,
                displayText: `${selected.value}`,
                reset,
                isReadonly: false,
                isPreload: selected.value === defaultValue,
                rawValue: { value },
                sortIndex: sortIndex
            };

            updateFilter(filter);

        }

    }, [debouncedSelection]);

    
    function reset() {
        let newValue = values.find(di => di.value === defaultValue)!;
        setSelected(newValue);
        setIsFlatMode(false);
    }

    function toggleFlatRpm() {
        setIsFlatMode(!isFlatMode);
        
    }

    

    return (
        <div className={className ?? ""}>
            <div className="switch">
                <input id="checkbox1" checked={isFlatMode} onChange={toggleFlatRpm} className="look" type="checkbox" />
                <label htmlFor="checkbox1"></label>
            </div>
        </div>
    );


}


export default PbiSliderToggleFilterInput;
