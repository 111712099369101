import React, { useEffect, useState } from 'react';
import { useAxios, useLazyAxios } from 'use-axios-client';
import { useNavigate } from 'react-router-dom';

import slide1 from './assets/img/prosales/Mainpage.png';
import slide2 from './assets/img/prosales/0001.png';
import slide3 from './assets/img/prosales/0002.png';
import slide4 from './assets/img/prosales/0003.png';
import slide5 from './assets/img/prosales/0004.png';
import slide6 from './assets/img/prosales/0005.png';
//import slide7 from './assets/img/prosales/0006.png';
//import slide8 from './assets/img/prosales/0007.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./upgrade.css";
import { Carousel } from 'react-responsive-carousel';
import { CNavItem, CNavbar, CNavbarBrand, CNavbarToggler, CCollapse, CNavbarNav, CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CTable, CTableBody, CTableDataCell, CTableFoot, CTableHead, CTableHeaderCell, CTableRow, CContainer, CRow, CCol, CNavLink } from '@coreui/react';
import { MDBInput } from "mdbreact";

import { Helmet } from 'react-helmet';

//import logoWhite from './assets/img/MPactPRO_white.png';
import logoWhite from './assets/img/mpact_ratepro_white.png';

function getReturnUrl(): string {
    let protocol = "https://";
    let host = window.location.host;
    let base = protocol + host;
    let url = new URL(base)
    url.searchParams.append("hf", "f");
    return base;
}

interface UpgradeRequestInfo {
    requestor: string;
    agree: boolean;
}

function Upgrade() {
    const { data: upgradeInfo, loading: upgradeInfoLoading, error: upgradeInfoError } = useAxios<any>('api/registration/getUpgradeInfo', { method: 'GET' });
    const [requestUpgrade, { data: upgradePostResponse }] = useLazyAxios<any>('api/registration/upgradeToPro', { method: 'POST' });

    const [requestor, setRequestor] = useState<UpgradeRequestInfo>({ requestor: "", agree: false });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showThankYouModal, setShowThankYourModal] = useState<boolean>(false);

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const [upgradeText, setUpgradeText] = useState<string>("Upgrade today for instant access!");

    const [normalRate, setNormalRate] = useState<number>(0);
    const [discountedRate, setDiscountedRate] = useState<number>(0);

    const navigate = useNavigate();



    useEffect(() => {
        if (upgradeInfo) {
            if (upgradeInfo.hasOwnProperty("error")) {
                return;
            }

            if (upgradeInfo.isPro) {
                navigateToReport();
            }


            if (upgradeInfo.hasDiscount) {
                setUpgradeText(`Upgrade today for instant access and a discounted rate!`);

                let discount = upgradeInfo.discountedRate;

                if (discount < 100) {
                    discount = 100;
                }

                if (discount > 1000) {
                    discount = 1000;
                }

                setDiscountedRate(discount);
            }

            let rate = upgradeInfo.normalRate;
            if (rate < 100) {
                rate = 100;
            }

            if (rate > 1000) {
                rate = 1000;
            }

            setNormalRate(upgradeInfo.normalRate);
        }
    }, [upgradeInfo])

    useEffect(() => {
        if (upgradeInfoError) {
            if (upgradeInfoError.message.includes("401")) {
                navigate(`/login?sender=upgrade`)
            }
        }
    }, [upgradeInfoError])

    useEffect(() => {
        if (upgradePostResponse !== null && upgradePostResponse !== undefined) {
            if (upgradePostResponse === true) {
                navigateToReport();
            }
        }
    }, [upgradePostResponse]);


    function onRequestorChange(evt: any) {
        setRequestor((old) => {
            return {
                ...old,
                [evt.target.id]: evt.target.value,
            };
        })
    }

    function submitUpgradeRequest(evt: any) {
        setShowModal(false);
        setShowThankYourModal(true);
        window.setTimeout(() => {
            requestUpgrade(requestor);
        }, 1000);
    }

    function navigateToReport() {
        window.location.href = getReturnUrl();
    }

    let displayUpgradeOption = !upgradeInfoLoading && !upgradeInfoError && upgradeInfo && !upgradeInfo.hasOwnProperty("error");

    return (
        <>
            <Helmet>
                <title>Upgrade to Mpact Pro!</title>
            </Helmet>
            <div
                className="pos-absolute top-0 bottom-0 left-0 right-0 bg bg-report upgrade-container"
                style={{ overflow: 'hidden' }}
            >

                <CNavbar expand="xl" colorScheme="dark" className="bg-dark upgrade-header">
                    <CContainer fluid>
                        <CNavbarBrand >
                            <img src={logoWhite} title="Mpact Pro Logo" className='pro-brand' />
                        </CNavbarBrand>
                        <CNavbarToggler
                            aria-label="Toggle navigation"
                            aria-expanded={isVisible}
                            onClick={() => setIsVisible(!isVisible)}
                        />
                        <CCollapse className="navbar-collapse" visible={isVisible}>
                            <CNavbarNav className="d-flex align-items-center">
                                {displayUpgradeOption &&
                                    <CNavItem>
                                        <CNavLink onClick={() => { setShowModal(m => !m); }}>{upgradeText}</CNavLink>
                                    </CNavItem>
                                }

                                {/*    <CNavItem>*/}
                                {/*        <CNavLink href={process.env.REACT_APP_PROPDF} download className="text-white ml-5">MPact PRO Features</CNavLink>*/}
                                {/*    </CNavItem>*/}
                            </CNavbarNav>

                        </CCollapse>
                    </CContainer>
                </CNavbar>

                <div className="upgrade-content">
                    <Carousel
                        showArrows={true}
                        autoPlay={!showModal}
                        infiniteLoop={true}
                        interval={5000}
                        showIndicators={false}
                        showThumbs={true}
                        thumbWidth={150}
                        useKeyboardArrows={true}

                        dynamicHeight={false}
                        className="mt-2 carousel-fix">
                        <div>
                            <img src={slide1} />
                            <p className="legend">
                                <h6>MPact PRO</h6>
                                MPact PRO's graphical analysis
                                tools visually present you with a
                                clearer understanding of current
                                market rates along with the
                                direction and speed of changes
                                that are coming, so your team can
                                make faster, smarter, and more
                                profitable decisions.
                            </p>
                        </div>

                        <div>
                            <img src={slide2} />
                            <p className="legend">
                                <h6>Market Rate Differential</h6>
                                Highlight critical opportunities to penetrate
                                an account in a particular area with the MPact
                                PRO Market Rate Differential. When you sort
                                and display your activity by the lanes where
                                you perform best to least, you identify how
                                your rates might affect the market behavior.
                                This analysis view also benchmarks your rates
                                against the aggregated market for a side-byside
                                comparison of rates, number of orders,
                                and timeframe.
                            </p>
                        </div>


                        <div>
                            <img src={slide3} />
                            <p className="legend">
                                <h6>Flow Map</h6>
                                Uncover crucial answers to market questions
                                by revealing what your network looks like in
                                your largest market with and without you,
                                and how competitively your current rates
                                stand in that market. With the Flow Map, you
                                fully understand the market you're pricing
                                and what drives the rates in that market
                                before quoting and negotiating.
                            </p>
                        </div>

                        <div>
                            <img src={slide4} />
                            <p className="legend">
                                <h6>Order Trends</h6>
                                Transportation rates in a particular
                                market are constantly influenced by asset
                                availability and load volume. Fluxes in these
                                variables cause rates to vary drastically. The
                                Order Trends graphs display the influencers
                                in the market, taking away the mystery.
                                Begin to understand your total share of
                                any given market by breaking it down to
                                reveal the number of brokered loads or
                                asset providers.
                            </p>
                        </div>

                        <div>
                            <img src={slide5} />
                            <p className="legend">
                                <h6>The 5 Whys (Average Rate)</h6>
                                Delve into the key measures of load data
                                by asking "Why" in MPact PRO's new "5
                                Whys." With this visual order decomposition
                                tree, you can ask why you get the results
                                you do 5 times, applying different filters to
                                illustrate what equipment, length of haul,
                                commodities, and key markets render the
                                most profit. Uncover wasteful practices
                                that need eliminating and use the "hover to
                                discover" tool tip - available only in MPact
                                PRO - to unlock the winners and losers faster
                                that any spreadsheet.
                            </p>
                        </div>

                        <div>
                            <img src={slide6} />
                            <p className="legend">
                                <h6>MPact PRO Predictor</h6>
                                McLeod's new MPact PRO Predictor gives you the power
                                to forecast freight rates up to 14 days in advance.
                                Backed by historical data from McLeod�s Market Insight,
                                MPact PRO Predictor factors in Linehaul rate, Fuel,
                                Carrier type, Trailer type, and Commodity type to
                                offer All-in predictions based on real data.
                                Give your sales staff a leg up with quotes for orders
                                that pick up and deliver in the near term and help
                                new users explore lane rates and navigate the market
                                from day one on the job.
                            </p>
                        </div>
                    </Carousel>
                </div>

            </div>

            <CModal alignment="center" visible={showModal} onClose={() => { setShowModal(false); }} size="xl">
                <CModalHeader>
                    <CModalTitle>Upgrade {upgradeInfo?.scac} to MPact PRO</CModalTitle>
                </CModalHeader>

                <CModalBody>
                    <CContainer>
                        <CRow className="mt-2">
                            <div>
                                <h5><strong>Terms & Conditions:</strong></h5>
                                <h6><strong>Fee</strong></h6>
                                <p>
                                    Customer agrees to pay the monthly subscription fee stated which is based on the McLeod Software licensed user count at time of purchase with a minimum of $100/month. McLeod Software maintains the right to audit licensed user counts and adjust pricing accordingly.
                                </p>
                                <br />
                                <h6><strong>Term</strong></h6>
                                <p>
                                    The term of the subscription provided is month-to-month with invoicing beginning on the next 1st day of the month following the date of purchase until terminated by either party subject to the notice provisions found in the License Terms and Conditions.
                                </p>
                                <br />
                                <h6><strong>Specific Addendum Terms and Conditions</strong></h6>
                                <p>
                                    <a href="https://press.mcleodsoftware.com/legal/CCTerms" target="_blank">License terms and conditions</a>
                                    <br />
                                    <a href="https://press.mcleodsoftware.com/legal/CCServiceLevel" target="_blank">Service Level Agreement</a>
                                    <br />
                                    <a href="https://press.mcleodsoftware.com/legal/CCAcceptableUse" target="_blank">Acceptable Use Policy</a>
                                </p>

                            </div>
                            <CCol>
                                <h6><strong>Monthly Billing</strong></h6>
                                <CTable small>
                                    <CTableHead>
                                        <CTableRow>
                                            <CTableHeaderCell scope="col">Your Licensed Users</CTableHeaderCell>
                                            <CTableHeaderCell scope="col">Total Monthly Rate</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        <CTableRow>
                                            <CTableDataCell>{upgradeInfo?.userCount || "N/A"}</CTableDataCell>
                                            <CTableDataCell>${normalRate || "N/A"}</CTableDataCell>
                                        </CTableRow>

                                        {upgradeInfo?.hasDiscount &&
                                            <CTableRow color="success">
                                                <CTableDataCell>Discounted Rate</CTableDataCell>
                                                <CTableDataCell>${discountedRate || "N/A"}</CTableDataCell>
                                            </CTableRow>
                                        }

                                    </CTableBody>
                                </CTable>
                            </CCol>

                            <CCol>
                                <h6><strong>Please complete form to enable upgrade</strong></h6>

                                <div className="md-form mb-0">
                                    <MDBInput
                                        type='text'
                                        label="Authorized Purchaser Name"
                                        id='requestor'
                                        value={requestor.requestor}
                                        required
                                        onChange={onRequestorChange}
                                    />
                                </div>

                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        id="agree"
                                        className="custom-control-input"
                                        checked={requestor.agree}
                                        onChange={e => {
                                            setRequestor((old) => {
                                                return {
                                                    ...old,
                                                    agree: e.target.checked
                                                };
                                            });
                                        }}
                                    />
                                    <label
                                        htmlFor="agree"
                                        className="custom-control-label">
                                        By clicking 'Upgrade' to purchase MPact Pro you are stating you have authority to make this purchase.
                                    </label>
                                </div>

                            </CCol>
                        </CRow>
                    </CContainer>

                </CModalBody>

                <CModalFooter>
                    <CButton color="success" onClick={submitUpgradeRequest} disabled={!requestor.agree || !requestor.requestor}>
                        Upgrade
                    </CButton>
                </CModalFooter>
            </CModal>



            <CModal alignment="center" visible={showThankYouModal} size="xl">
                <CModalHeader closeButton={false} >
                    <CModalTitle>Thank you</CModalTitle>
                </CModalHeader>

                <CModalBody>
                    <p>Thank you for upgrading to MPact PRO!  A McLeod Software sales representative will contact you soon.  In the meantime, please enjoy instant access to MPact PRO.  We will redirect you shortly.</p>
                </CModalBody>
            </CModal>
        </>
    );
}

export default Upgrade;