import { models } from 'powerbi-client'
import { BasicFilterInfo } from '../models/filters';

function createBasicFilter(basic: BasicFilterInfo): any {
    let vals: any[] = [];

    if (Array.isArray(basic.value)) {
        vals = basic.value;
    } else {
        vals.push(basic.value);
    }

    return {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: basic.table,
            column: basic.column,
        },
        operator: "In",
        values: vals,
        filterType: models.FilterType.Basic,
        requireSingleSelection: false
    };
}


function createDateRangeFilter(startDate: Date, endDate: Date, table: string, column: string): any {
    return {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
            table: table,
            column: column
        },
        logicalOperator: "And",
        conditions: [
            {
                operator: "GreaterThanOrEqual",
                value: startDate.toISOString()
            },
            {
                operator: "LessThanOrEqual",
                value: endDate.toISOString()
            }
        ],
        filterType: models.FilterType.Advanced
    }
}


function createDateFilter(date: Date, table: string, column: string, operator: string) {
    return {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
            table: table,
            column: column
        },
        logicalOperator: "And",
        conditions: [
            {
                operator: operator,
                value: date.toISOString()
            }
        ],
        filterType: models.FilterType.Advanced
    }
}

function operatorNameToSymbol(operator: string): string {
    switch (operator) {
        case "In":
            return "In";
        case "Is":
            return "=";
        case "NotEqual":
            return "!=";
        case "GreaterThanOrEqual":
            return ">=";
        case "LessThanOrEqual":
            return "<=";

        default:
            return operator;
    }
}


/*
https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/control-report-filters
*/


export function useFilterFactory() {
    return {
        createBasicFilter,
        createDateRangeFilter,
        createDateFilter,
        translateOperatorName: operatorNameToSymbol
    };
}
