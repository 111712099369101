import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { CButton } from '@coreui/react';
import supportLogo from '../assets/img/support.png'
//import supportLogo from '../assets/img/rate-pred.png'

import './RatePredictor2.css';

function ButtonSupport(props) {

	function changeDisplay() {
		props.toggleSupport(true);
	};


	return (
		<div>
			<CButton className="
						text-black-50 
						text-dark 
						bg-transparent 
						shadow-none
						btn-border-none
						support-button"

				shape="pill"
				variant="ghost">

				<img className="button-image-sb" src={supportLogo} onClick={changeDisplay} />
			</CButton>
			
		</div>
	)
};

export default ButtonSupport;