import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import './App.css';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import { GoogleOAuthProvider } from '@react-oauth/google';

import App from './App';
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
    <BrowserRouter>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
            <App />
        </GoogleOAuthProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
