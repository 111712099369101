import React, { useState, useEffect } from 'react';
import { SelectionItem, ProFilterInfo, RadioButtonInputProps } from '../models/filters';
import { useFilters } from './FilterContext';
import { useFilterFactory } from '../hooks/useFilterFactory';
//import Select from 'react-select/dist/declarations/src/Select';
import { useDebouncedValue } from '../hooks/useDebouncedValue';

function PbiToggleButtonTwoFilterInput({
    id,
    group,
    table,
    column,
    displayName,
    operator,
    className,
    defaultValue,
    values,
    disabled,
    sortIndex
}: RadioButtonInputProps) {

    const [isMarketPosition, setIsMarketPosition] = useState<boolean>(true);
    const [labelA, setLabelA] = useState<string>();
    const [labelB, setLabelB] = useState<string>();

    const { updateFilter} = useFilters();
    const { createBasicFilter } = useFilterFactory();

    const [selected, setSelected] = useState<SelectionItem>(() => {
        let val = values.find(di => di.value === defaultValue);
        return val!;
    });

    const debouncedSelection = useDebouncedValue(selected, 100);

    useEffect(() => {
        //var defaultValue = getFilterValue(id);



        //let defaultCompare = { value: 'Buy' }
        /*if (JSON.stringify(defaultValue) === JSON.stringify(defaultCompare)) {*/
        if (defaultValue === 'Buy') {
            setIsMarketPosition(false);
        }
        else {
            setIsMarketPosition(true);
        }

        setLabelA(values[0].value);
        setLabelB(values[1].value);

    }, [])

    useEffect(() => {
        let value = selected.value;
        //console.log("debounce: ");
        //console.log(value);
        let reportFilter = createBasicFilter({ table, column, value })

        let filter: ProFilterInfo = {
            id,
            group,
            reportFilter,
            displayTextHeader: `${ displayName }`,
            displayText: `${selected.label}`,
            reset,
            isReadonly: false,
            isPreload: selected.value === defaultValue,
            rawValue: { value },
            sortIndex
        };

        updateFilter(filter);

    }, [debouncedSelection]);    
    
    

    function reset() {
        //let newValue = values.find(di => di.value === defaultValue)!;
        //setSelected(newValue);
        var selectedItem = { label: "Sell", value: "Sell" };
        setSelected(selectedItem);
        setIsMarketPosition(true);
    }

    useEffect(() => {
        var selectedItem: SelectionItem = { label: "Buy", value: "Buy" };

        if (isMarketPosition == true) {
            selectedItem = { label: "Sell", value: "Sell" };
            setSelected(selectedItem);
        }
        else {
            setSelected(selectedItem);
        }

        //console.log("selectedItem: ");
        //console.log(selectedItem);

    }, [isMarketPosition])

    useEffect(() => {
        //console.log("selected from 2 Button !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!: ");
        //console.log(selected);
    }, [selected])

    function toggleMP() {
        setIsMarketPosition(!isMarketPosition);
        }

    function handleChange(evt: any) {
        let val = evt.target.value;
        let selection: SelectionItem = values.find(v => v.value == val)!;
        setSelected(selection);
    }

    return (
        <div className={className ?? ""}>
            <label>{displayName}</label>
            {/*<div>*/}
            {/*    {values.map((v, i) => { return RenderInput(v, i); })}*/}
            {/*</div>*/}
            <div className="fp-btn-group">
                <button
                    type="button"
                    onClick={toggleMP}
                    onChange={handleChange}
                    className={"mp-1-fp " + (isMarketPosition ? "fp-btn-default" : "fp-btn-primary")}
                    id="Buy">{labelA}
                </button>
                <button
                    type="button"
                    onClick={toggleMP}
                    onChange={handleChange}
                    className={"mp-1-fp " + (isMarketPosition ? "fp-btn-primary" : "fp-btn-default")}
                    id="Sell">{labelB}
                </button>
            </div>
        </div>
    );


}


export default PbiToggleButtonTwoFilterInput;
